import AlertCardView1_0 from './CardView/View1_0';
import AlertCardView1_2 from './CardView/View1_2';
import AlertCardView1_4 from './CardView/View1_4';
import AlertCardView1_6 from './CardView/View1_6';
import AlertCardView1_9 from './CardView/View1_9';
import AlertCardView2_0 from './CardView/View2_0';

import MyAlertView1_0 from './DetailView/MyAlertView1_0';
import MyAlertView1_2 from './DetailView/MyAlertView1_2';
import MyAlertView1_4 from './DetailView/MyAlertView1_4';
import MyAlertView1_6 from './DetailView/MyAlertView1_6';
import MyAlertView1_9 from './DetailView/MyAlertView1_9';
import MyAlertView2_0 from './DetailView/MyAlertView2_0';

import React, { useState } from 'react';
function MyAlertView({ alertRuns, showAlert, hideAlert }) {
    const [selectedAlertId, setSelectedAlertId] = useState();

    const selectedAlertData = alertRuns?.find(({id}) => id === selectedAlertId);
    const selectedAlertVersion = selectedAlertData?.results?.alertVersion;
    const showAlerts = (item) => {
        setSelectedAlertId(item?.id);
        showAlert();
    };

    const hideDetailedAlert = () => {
        setSelectedAlertId(undefined);
        hideAlert();
    }

    const alertMap = {
        '19': 'MARKET_SHARE',
        '22': 'PROMOTION_EFFICIENCY',
        '20': 'DISTRIBUTION_GAIN_AND_LOSSES',
        '237': 'ECOSYSTEM_ALERT',
        '21': 'COMPETITIVE_PRICING',
        '450': 'GROWTH_ALERT',
        '451': 'BRAND_TREND_ALERT',
        '455': 'PRICE_POSITION_TREND_ALERT',
        '452': 'MARKET_TREND_ALERT',
        '454': 'ASSORTMENT_ACTION',

        // Financial Services-specific alert skus
        '925': 'GROWTH_ALERT',
        '926': 'PRICE_POSITION_TREND_ALERT',
        '927': 'ECOSYSTEM_ALERT',
        '928': 'MARKET_SHARE',
        '929': 'PROMOTION_EFFICIENCY',
        '930': 'DISTRIBUTION_GAIN_AND_LOSSES',
    };

    // SKUs for View Report button
    let viewReportAlertsSKU = ["451", "452", "455", "454", '926'];

    return (
        <>
            {!selectedAlertId && alertRuns?.map((alertRun) => {
                const alertVersion = alertRun?.results?.alertVersion;
                if (alertVersion === "1.0") {
                    return (
                        <AlertCardView1_0
                            CardData={alertRun}
                            key={alertRun?.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                        />
                    );
                } else if (["1.2", "1.3"].includes(alertVersion)) {
                    return (
                        <AlertCardView1_2
                            CardData={alertRun}
                            key={alertRun.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                        />
                    );
                } else if (alertVersion === "1.4") {
                    return (
                        <AlertCardView1_4
                            CardData={alertRun}
                            key={alertRun.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                            viewReportAlertsSKU={viewReportAlertsSKU}
                        />
                    );
                } else if (alertVersion === "1.6") {
                    return (
                        <AlertCardView1_6
                            CardData={alertRun}
                            key={alertRun.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                            viewReportAlertsSKU={viewReportAlertsSKU}
                        />
                    );
                } else if (alertVersion === "1.9") {
                    return (
                        <AlertCardView1_9
                            CardData={alertRun}
                            key={alertRun.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                            viewReportAlertsSKU={viewReportAlertsSKU}
                        />
                    );
                } else if (alertVersion === "2.0") {
                    return (
                        <AlertCardView2_0
                            CardData={alertRun}
                            key={alertRun.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                            viewReportAlertsSKU={viewReportAlertsSKU}
                        />
                    );
                } else {
                    return (
                        <AlertCardView1_0
                            CardData={alertRun}
                            key={alertRun.id}
                            handleClick={showAlerts}
                            alertMap={alertMap}
                        />
                    );
                }
            })}


            { selectedAlertVersion === "1.0" && <MyAlertView1_0 alertData={selectedAlertData} alertMap={alertMap} goBack={hideDetailedAlert}/>}
            { ["1.2", "1.3"].includes(selectedAlertVersion) && <MyAlertView1_2 alertData={selectedAlertData} alertMap={alertMap} goBack={hideDetailedAlert}/>}
            { selectedAlertVersion === "1.4" && <MyAlertView1_4 alertData={selectedAlertData} alertMap={alertMap} viewReportAlertsSKU={viewReportAlertsSKU} goBack={hideDetailedAlert}/>}
            { selectedAlertVersion === "1.6" && <MyAlertView1_6 alertData={selectedAlertData} alertMap={alertMap} viewReportAlertsSKU={viewReportAlertsSKU} goBack={hideDetailedAlert}/>}
            { selectedAlertVersion === "1.9" && <MyAlertView1_9 alertData={selectedAlertData} alertMap={alertMap} viewReportAlertsSKU={viewReportAlertsSKU} goBack={hideDetailedAlert}/>}
            { selectedAlertVersion === "2.0" && <MyAlertView2_0 alertData={selectedAlertData} alertMap={alertMap} goBack={hideDetailedAlert}/>}
        </>
    )

}
export default MyAlertView