import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './ByzzerChip.scss';

export type ByzzerChipProps = {
    className?: string;
    children?: ReactNode;
    label?: ReactNode;
}


export const ByzzerChip = ({
    label,
    children = label,
    className,
    ...props
}: ByzzerChipProps) => {

    const baseClassName = 'byzzer-chip';

    return (
        <div className={classnames(baseClassName, className)}>
            {typeof children === 'string' ? (
                <span className={classnames(`${baseClassName}-text-content`)}>
                    {children}
                </span> 
            ) : children}
        </div>
    );

};

export default ByzzerChip;

ByzzerChip.displayName = 'ByzzerChip';