import './DodMarketFilterBuilder.scss';
import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import {DodFilter, DodFilters, SummedSelection} from '@/types/DodRun';
import {
    ByzzerChangeEventHandler,
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
    ByzzerSelectOption,
} from '@byzzer/ui-components';
import {DodMarketFilterTypePicker} from './DodMarketFilterTypePicker';
import {DodFilterType, DodMarketFilterType} from '@/components/DodConfigEditor/types';
import {DodMarketFilterValuePicker} from './DodMarketFilterValuePicker';
import {DodMarketFilterPreview} from './DodMarketFilterPreview';
import {RunConfigMarket} from '@/types/ReportRun';
import {DodSummedSelectionEditor} from '@/components/DodConfigEditor/common';
import {MarketPickerProvider} from '@/components/MarketPicker';
import {DodPanel, DodPanelRef} from '@/components/DodConfigEditor/common/DodPanel';
import {alert as byzzerAlert} from '@/components/form';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import {stopPropagation} from '@/utils/stopPropagation';
import {DodPreset} from "@/types/ApiTypes";
import {useMarketService} from "@/services/market.service";
import {useUser} from "@/contexts/UserContext";
import { isValidArray } from '@/utils';

const baseClassName = 'dod-market-filter-builder';

export interface DodMarketFilterBuilderProps extends Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>> {
    className?: string;
    name?: string;
    tip?: ReactNode;
    value: DodFilters;
    onChange: ByzzerChangeEventHandler<DodFilters>;

    onValidityChange?(e: ByzzerValidityChangeEvent): void;
} // Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export type AdvancedSearchFilters = {
    marketType?: ByzzerSelectOption;
    marketLevel?: ByzzerSelectOption;
    marketTier?: ByzzerSelectOption;
    parentCompany?: ByzzerSelectOption[];
    channel?: ByzzerSelectOption;
    childMarketType?: ByzzerSelectOption;
};

export type MarketTab = 'markets' | 'search';

export function DodMarketFilterBuilder({
                                           className,
                                           name,
                                           value,
                                           onChange,
                                           onValidityChange,
                                           tip,
                                           ...props
                                       }: DodMarketFilterBuilderProps) {
    const filters = value;
    const { subscription, features, accessibleMasterCompanies } = useUser();
    const isFreeUser = subscription?.metadata?.isFree ?? false
    const selectionLimit = subscription?.metadata?.extractLimits?.market ?? Infinity

    const {getRunConfigMarketsByKeys} = useMarketService();
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const [filterType, setFilterType] = useState<DodMarketFilterType>('all');
    const [filterText, setFilterText] = useState<string>('');
    const [valueSelectionMode, setValueSelectionMode] = useState<'single' | 'summed'>('single');
    const [summedSelection, setSummedSelection] = useState<SummedSelection<RunConfigMarket[]> | undefined>();
    const [summedSelectionIndex, setSummedSelectionIndex] = useState<number | undefined>();
    const singleActions = useMemo<ActionConfig[]>(
        (): ActionConfig[] => [
            {
                icon: 'summed',
                type: 'summed',
                tip: 'Create A Summed Selection',
                action() {
                    setValueSelectionMode('summed');
                },
                include: !isFreeUser
            },
        ],
        []
    );
    const valuePanelRef = useRef<DodPanelRef>(null);
    const [advancedSearchFilters, setAdvancedSearchFilters] = useState<AdvancedSearchFilters>({});
    const [activeMarketFolderTab, setActiveMarketFolderTab] = useState<MarketTab>('markets');

    const [showRemainingMarkets, setShowRemainingMarkets] = useState(false)
    const [hideDisabledMarkets, setHideDisabledMarkets] = useState(false)
    const [hideNonPromoMarkets, setHideNonPromoMarkets] = useState(false)

    useEffect(() => {
        onValidityChange?.({
            name,
            isValid: Boolean(value.markets.values.length + value.markets.summedSelections.length),
        });
    }, [value.markets.values, value.markets.summedSelections]);

    useEffect(() => {
        setFilterText('');
    }, [activeMarketFolderTab, filterType]);

    useEffect(() => {
        if (activeMarketFolderTab === 'markets') {
            setAdvancedSearchFilters({});
        }
    }, [activeMarketFolderTab]);

    const handleTabChange = (tabKey) => {
        if (tabKey === 'search') {
            trackEvent({
                type: 'click',
                name: 'dod_filter_advanced_search_click',
                data: getEventData({ dodWizardStep: 'market', panel: 'filter selection' }),
            });
        }
        reset();
        setActiveMarketFolderTab(tabKey);
    };

    function handleSearchFiltersChange(e) {
        setAdvancedSearchFilters(e.value);
    }

    function handleFilterTypeChange(e) {
        if (e.value !== 'all') {
            trackEvent({
                type: 'click',
                name: 'dod_filter_type_click',
                data: getEventData({ dodWizardStep: 'market', panel: 'filter selection', filterType: e.value }),
            });
        }
        reset();
        setFilterType(e.value);
    }

    function handleSummedSelectionChange(e: ByzzerChangeEvent<SummedSelection<RunConfigMarket[]>>) {
        setSummedSelection(e.value);
        const updatedValue = value.markets;
        if (summedSelectionIndex === undefined) {
            updatedValue.summedSelections = [...updatedValue.summedSelections, e.value] as any;
        } else {
            updatedValue.summedSelections = updatedValue.summedSelections.map((v, i) =>
                i === summedSelectionIndex ? e.value : v
            );
        }
        onChange({
            name,
            value: {
                ...value,
                markets: updatedValue,
            },
        });
        setSummedSelectionIndex(undefined);
        setSummedSelection(undefined);
        setValueSelectionMode('single');
    }

    function handleSummedSelectionCancel() {
        reset();
    }

    function handleFilterValueChange(e: ByzzerChangeEvent<RunConfigMarket[]>) {
        const updatedValue: DodFilter<RunConfigMarket[]> = value.markets ?? {
            summedSelections: [],
        };
        updatedValue.values = e.value;
        onChange({
            name,
            value: {
                ...value,
                markets: updatedValue,
            },
        });
    }

    function handleFilterChange(e: ByzzerChangeEvent<DodFilters>) {
        onChange?.({
            name,
            value: e.value,
        });
    }

    function handleEditSummedSelection(_: DodFilterType, index: number): void {
        // const filterTypeMapping = {
        //     all: 'all',
        //     majormarket: 'majorMarkets',
        //     channel: 'majorMarkets',
        //     account: 'accounts',
        //     stateline: 'stateLines',
        //     region: 'regions',
        //     division: 'divisions',
        // };
        setValueSelectionMode('summed');
        setSummedSelection(value.markets.summedSelections[index]);
        // const subMarketType = value.markets.summedSelections[index].values[0]?.subMarketType;
        // const filterType = subMarketType ?  subMarketType.replace(/\s/g, '').toLowerCase() : 'all';
        // setFilterType(filterTypeMapping[filterType]);

        // BYZ-12406 By default, we want ui to land in 'all' filtertype when editing any sum selection for consistency
        setFilterType('all'); 
        setSummedSelectionIndex(index);
    }

    function handlePresetSelect(e: ByzzerChangeEvent<DodPreset>) {
        const priorApprovalParams = {
            isDodMarket: true,
            categories: value.categories.values as string[],
            isCategoriesSummedMode: value.categories.summedSelections.length ? true : false,
            summedCategories: value.categories.summedSelections as any,
            nonSummedCategories: value.categories.values as string[],
        };

        let markets = getRunConfigMarketsByKeys(
            e.value.values.markets!.values.map((mkt) => mkt.key) as string[],
            priorApprovalParams
        );
        let summedMarkets = e.value.values.markets!.summedSelections.map((summedSelection) => ({
            ...summedSelection,
            values: getRunConfigMarketsByKeys(
                summedSelection.values.map((mkt) => mkt.key) as string[],
                priorApprovalParams
            ),
        })).filter((sumMarket) => sumMarket.values.length);

       
        if (!isValidArray(markets) && !isValidArray(summedMarkets)) {
            byzzerAlert({
                content: `You do not have access to any of the saved markets in "${e.value.displayName}"`
            })
        }

        // if it's a limited market user, validate saved markets with accessible markets allowed for this user
        const isLimitedMarketUser = features?.enableLimitedMarketSubscription;

        if (isLimitedMarketUser) {
            // BYZ-12100 Remove Market from Saved markets if market is removed from accessible markets
            // get the list of master companies selected in regular and summed markets list of this saved selection
            const masterCompaniesInMarkets: string[] = markets
                .map((market) => market.masterCompany as string)
                .filter((val) => val !== undefined);
            const masterCompaniesInSummedMarkets: string[] = summedMarkets
                .map((sumMrkt) => sumMrkt.values)
                .flat()
                .map((runConfigMrkt) => runConfigMrkt.masterCompany as string)
                .filter((val) => val !== undefined);

            // check if the saved selection contains any unpurchased markets which isnt accessible to this user
            const containsUnpurchasedMarkets: boolean = Boolean(
                [
                    ...masterCompaniesInMarkets.filter(
                        (masterCompany) => !accessibleMasterCompanies.includes(masterCompany)
                    ),
                    ...masterCompaniesInSummedMarkets.filter(
                        (masterCompany) => !accessibleMasterCompanies.includes(masterCompany)
                    ),
                ].length
            );

            // if the list contains markets which the user doesnt have access to, then filter them out with a popup warning
            if (containsUnpurchasedMarkets) {
                byzzerAlert({
                    content: (
                        <div>
                            This saved markets list contains markets that are not accessible with your current subscription. These markets will not be added.
                        </div>
                    ),
                });
                markets = markets.filter((market) =>
                    accessibleMasterCompanies.includes(market?.masterCompany as string)
                );
                summedMarkets = summedMarkets
                    .map((sum) => {
                        let { values, name } = sum;
                        values = values.filter((market) =>
                            accessibleMasterCompanies.includes(market?.masterCompany as string)
                        );
                        return {
                            values,
                            name,
                        };
                    })
                    .filter((sumMarket) => sumMarket.values.length); // filtering out the sumMarket that has no values
            }
        }

        onChange({
            name,
            value: {
                ...value,
                markets: {
                    ...e.value.values.markets!,
                    values: markets,
                    summedSelections: summedMarkets
                },
            },
        });
    }

    function reset() {
        valuePanelRef.current?.resetFilter();
        setValueSelectionMode('single');
        setSummedSelection(undefined);
        setSummedSelectionIndex(undefined);
    }

    function handleExclusionFilterChange(e: ByzzerCheckableChangeEvent<string>) {
        switch (e.name) {
            case 'includeRemainingMarkets':
                setShowRemainingMarkets(e.checked)
                break;
            case 'excludeDisabledMarkets':
                setHideDisabledMarkets(e.checked)
                break;
            case 'excludeNoPromoDataAccounts':
                setHideNonPromoMarkets(e.checked)
                break;
        }
    }

    // BYZ-12529 Show market options for limited market users
    // const hideActions = features?.enableLimitedMarketSubscription;

    return (
        <MarketPickerProvider
            showRemainingMarkets={showRemainingMarkets}
            hideDisabledMarkets={hideDisabledMarkets}
            hideNonPromoMarkets={hideNonPromoMarkets}
        >
            <div className={classnames(baseClassName, className)} {...props}>
                <DodMarketFilterTypePicker
                    value={filterType}
                    onChange={handleFilterTypeChange}
                    filters={value}
                    onPresetSelect={handlePresetSelect}
                    advancedSearchFilters={advancedSearchFilters}
                    onAdvancedSearchFiltersChange={handleSearchFiltersChange}
                    activeMarketFolderTab={activeMarketFolderTab}
                    onTabChange={handleTabChange}
                    showPresetWarning={Boolean(value.markets.values.length + value.markets.summedSelections.length)}
                />

                <DodPanel
                    expandable={true}
                    byzRef={valuePanelRef}
                    filterPlaceholder={'Search Markets'}
                    onFilterChange={setFilterText}
                    name={'market-picker-panel'}
                    trackClick={{ data: { dodWizardStep: 'market', panel: "value picker" } }}
                    actions={
                        /* !hideActions ? */  // BYZ-12529 Show market options for limited market users
                        [
                        {
                            key: 'custom-filters',
                            items: [
                                {
                                    content: (
                                        <ByzzerCheckbox
                                            label={'Hide Non-Selectable Markets'}
                                            name={'excludeDisabledMarkets'}
                                            checked={hideDisabledMarkets}
                                            onChange={handleExclusionFilterChange}
                                            onClick={stopPropagation}
                                        />
                                    ),
                                },
                                {
                                    content: (
                                        <ByzzerCheckbox
                                            label={'Show Remaining Markets'}
                                            name={'includeRemainingMarkets'}
                                            checked={showRemainingMarkets}
                                            onChange={handleExclusionFilterChange}
                                            onClick={stopPropagation}
                                        />
                                    ),
                                },
                                {
                                    content: (
                                        <ByzzerCheckbox
                                            label={'Hide Non-Promo Data Accounts'}
                                            name={'excludeNoPromoDataAccounts'}
                                            checked={hideNonPromoMarkets}
                                            onChange={handleExclusionFilterChange}
                                            onClick={stopPropagation}
                                        />
                                    ),
                                },
                            ],
                        },
                    ]
                    /* : [] */ // BYZ-12529 Show market options for limited market users
                }
                >
                    {valueSelectionMode === 'single' && (
                        <DodMarketFilterValuePicker
                            key={`${summedSelectionIndex}`}
                            filters={filters}
                            filterType={filterType}
                            filterText={filterText}
                            value={value.markets.values}
                            actions={singleActions}
                            includeActions={true}
                            onApply={handleFilterValueChange}
                            activeMarketFolderTab={activeMarketFolderTab}
                            advancedSearchFilters={advancedSearchFilters}
                            limit = {selectionLimit}
                            inSummedMode={false} //bcoz valueSelectionMode is 'single'
                        />
                    )}
                    {valueSelectionMode === 'summed' && (
                        <DodSummedSelectionEditor<RunConfigMarket[]>
                            key={`${summedSelectionIndex}`}
                            onApply={handleSummedSelectionChange}
                            onCancel={handleSummedSelectionCancel}
                            includeActions={true}
                            value={summedSelection}
                            dodWizardStep='market'
                        >
                            {({onChange, values, className}) => (
                                <DodMarketFilterValuePicker
                                    key={`${summedSelectionIndex}`}
                                    filters={filters}
                                    filterType={filterType}
                                    filterText={filterText}
                                    value={values!}
                                    onChange={onChange}
                                    activeMarketFolderTab={activeMarketFolderTab}
                                    advancedSearchFilters={advancedSearchFilters}
                                    inSummedMode={true} //bcoz valueSelectionMode is 'summed'
                                />
                            )}
                        </DodSummedSelectionEditor>
                    )}
                </DodPanel>
                <DodMarketFilterPreview
                    onChange={handleFilterChange}
                    value={value}
                    onEditSummedSelection={handleEditSummedSelection}
                    skipSorting
                />
            </div>
        </MarketPickerProvider>
    );
}

export default DodMarketFilterBuilder;
