import React, { forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useState } from 'react';
import './FocusKpiFilter.scss';
import {
    DefaultConfigWizardContext,
    DefaultConfigWizardContextValue,
} from '../../DefaultsConfigWizard/DefaultsConfigWizardContext';
import { FocusKpiRunConfigOptions } from '@/types/RunConfigOptions';
import { useUser } from '@/contexts/UserContext';
import { FilterGroup } from '@/components/ConfigurationEditors/FilterGroup';
import classnames from 'classnames';
import { focusKPIOptions } from '@/components/FocusKPISelector/focusKPI.constants';
import FocusKPISelector from '@/components/FocusKPISelector/FocusKPISelector';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';
import { DefaultConfigLabels } from '@/pages/NewOnboarding/OnboardingSteps';

export type FocusKpiFiltersRef = {
    value?: DefaultConfigWizardContextValue['value'];
};

export type FocusKpiFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<DefaultConfigWizardContextValue['value']>) => void;
    value?: DefaultConfigWizardContextValue['value'];
    summary?: ReactNode;
    includeFocusKPI?: boolean;
    sku?: string;
    labelsAndTips?: DefaultConfigLabels;
} & Partial<Omit<FocusKpiRunConfigOptions, 'type' | 'title'>> &
    Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const FocusKpiFilter = forwardRef<FocusKpiFiltersRef, FocusKpiFiltersProps>(
    ({ className, name, value, onChange, summary, includeFocusKPI = true, sku, ...props }, ref) => {
        const baseClassName = 'byz-focuskpi-filters';

        const { value: contextValue, onChange: onContextChange, state: contextState } = useContext(
            DefaultConfigWizardContext
        );

        const [internalValue, setInternalValue] = useState<DefaultConfigWizardContextValue['value']>({});

        const { defaultRunConfig } = useUser();

        function handleChange(e: ByzzerChangeEvent<unknown>) {
            onContextChange?.(e.name as keyof typeof defaultRunConfig, e.value, e.data);
        }

        useImperativeHandle(ref, () => ({
            get value() {
                return internalValue;
            },
        }));

        useEffect(() => {
            setInternalValue(contextValue ?? {});
        }, [contextValue]);

        useEffect(() => {
            if (value) {
                setInternalValue(value);
            }
        }, [value]);

        return (
            <FilterGroup className={classnames(baseClassName, className)}>
                {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}

                <FocusKPISelector
                    onlyRenderIf={includeFocusKPI}
                    label={<SelectorLabelInfo sku={sku!} selectorCode={'focusKPI'} />}
                    name={'focusKPI'}
                    value={internalValue.focusKPI}
                    onChange={handleChange}
                    placeholder={'Select Facts'}
                    options={focusKPIOptions}
                />
            </FilterGroup>
        );
    }
);

export default FocusKpiFilter;
FocusKpiFilter.displayName = 'FocusKpiFilter';
