import React, {useState, useEffect, forwardRef, memo, useMemo, useCallback} from "react";
import classnames from 'classnames';
import {AgGridReact} from "ag-grid-react";
import {CellClickedEvent, CellEditRequestEvent, ColDef} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.min.css"; // Move up to top of app?  eg. app.js or index.js?
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Move up to top of app?  eg. app.js or index.js?
// must import this custom CSS last
import './ByzzerTable.scss';
import {AgGridReactProps, AgReactUiProps} from "ag-grid-react/lib/shared/interfaces";


export type ByzzerTableProps<TColData = any, TColValue = any> = {
    rowData: any[];
    columnDefs: ColDef<TColData, TColValue>[];
    themeName?: string;
    baseColumnWidth?: number;
    defaultColDef?: ColDef<TColData, TColValue>;
    autoHeight?: boolean;   // <-- & HERE
    suppressMenu?: boolean;
    wrapText?: boolean;
    onCellClick?: (e:any) => void;
    readOnlyEdit?:boolean;
    onCellEditRequest?: (e:any) => void ;
    suppressClickEdit?: boolean;
    singleClickEdit?: boolean;
} & Partial<AgGridReactProps<TColData>>;


export const ByzzerTable = memo(forwardRef<AgGridReact, ByzzerTableProps>(({
    rowData,
    columnDefs,
    themeName = 'ag-theme-alpine',
    baseColumnWidth,
    defaultColDef,
    autoHeight,
    suppressMenu,
    wrapText,
    onCellClick,
    readOnlyEdit = false,
    onCellEditRequest,
    suppressClickEdit,
    singleClickEdit,
    ...props
}, ref) => {

    const baseColDef: ColDef = useMemo(() => ({

        width: baseColumnWidth ?? 350,  // set every column width
        filter: true,  // make every column use 'text' filter by default
        sortable: true,  // enable floating filters by default
        floatingFilter: true,
        resizable: true,
        suppressMenu: suppressMenu ?? true,
        autoHeight: autoHeight ?? false,
        wrapText: wrapText ?? false,
        lockVisible: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,

        // https://stackoverflow.com/questions/33894190/how-to-wordwrap-a-header-in-ag-grid

    }), [baseColumnWidth, suppressMenu, autoHeight, wrapText]);

    const handleCellClick = useCallback((e: CellClickedEvent<any>) => {
        onCellClick?.(e)
    }, [onCellClick]);

    const handleCellEditRequest = useCallback((e: CellEditRequestEvent<any>) => {
        onCellEditRequest?.(e)
    }, [onCellEditRequest])

    return (
        <AgGridReact
            className={themeName}
            ref={ref} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef ?? baseColDef} // Default Column Properties
            enableCellTextSelection={true}
            tooltipShowDelay={1500}
            // tooltipHideDelay={2500}
            suppressMenuHide={true}
            onCellClicked={handleCellClick} //we probably need this soon in future for further enhancements
            readOnlyEdit={readOnlyEdit}
            onCellEditRequest={handleCellEditRequest}
            singleClickEdit={singleClickEdit}
            suppressClickEdit={suppressClickEdit} //set this true if you only want to have your own way of starting editing, such as clicking a button in your custom cell renderer. setting this true will prevent cell editing
            suppressScrollOnNewData = {true}  //tells the grid to NOT scroll to the top when the page changes.
            {...props}
        />
    );

}));

export default ByzzerTable;

ByzzerTable.displayName = 'ByzzerTable';
