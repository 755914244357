import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './AboutYou.scss';
import { ByzzerTextInput, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import { PersonaDetails } from "@/types/ApiTypes";
import { useUser } from "@/contexts/UserContext";
import { useTenantApi } from "@/hooks";
import MarkdownEditor from '@uiw/react-markdown-editor';
import { OnboardingStepProps } from "../../OnboardingWizard";


function PersonaCard({persona, className, selected, onClick}: {persona: PersonaDetails, className: string, selected?: boolean, onClick?: (code: string) => void}) {
    const {
        title,
        description,
        iconUrl,
        code
    } = persona;
    function handleClick() {
        onClick?.(code);
    }
    return (
        <div 
            className={classnames(`${className}-card`, {
                [`${className}-card--selected`]: selected
            })}
            onClick={handleClick}
        >
            <img className={classnames(`${className}-card__thumbnail`)} src={iconUrl!}/> {/* switch to pure css */}
            <div className={classnames(`${className}-card__title`)}>
                <MarkdownEditor.Markdown source={title}/>
            </div>
            <div className={classnames(`${className}-card__description`)}>
                <MarkdownEditor.Markdown source={description}/>
            </div>
        </div>
    )
}

export const OTHER_PERSONA_CODE = 'other';

const baseClassName = 'byz-onboarding-about-you';

export const AboutYou = forwardRef(({
    action,
    isLastStep,
    step,
    onNext,
    onSkip,
    setBusy,
    title,
    nextText,
    busy,
    ...props
}: OnboardingStepProps, ref) => {
    const { personas, company } = useUser();
    const {savePersonas} = useTenantApi();
    const stepRef = useRef<any>();
    const [selectedPersonas, setSelectedPersonas] = useState<string[]>([]);
    const [otherPersonaDesc, setOtherPersonaDesc] = useState<string>('');
    const [shouldSaveData, setShouldSaveData] = useState(false);

    useEffect(() => {
        ;(async () => {
            if (shouldSaveData && (selectedPersonas?.length || Boolean(otherPersonaDesc))) {
                await savePersonas({personaCodes: selectedPersonas, otherPersonaDesc});
                onNext();
            }
            setShouldSaveData(false);
        })()
    }, [shouldSaveData]);

    // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
    useImperativeHandle(ref, () => stepRef.current, []);

    function handlePersonaCardClick(personaCode: string) {
        if (selectedPersonas.includes(personaCode)) {
            const newPersonas = selectedPersonas.filter(id => ![personaCode, OTHER_PERSONA_CODE].includes(id))
            setSelectedPersonas(newPersonas);
        } else {
            const newPersonas = [...selectedPersonas.filter(personaCode => personaCode !== OTHER_PERSONA_CODE), personaCode];
            setSelectedPersonas(newPersonas);
        }
    }

    function handleOtherPersonaInput({value}: ByzzerChangeEvent<string>) {
        if (value && !selectedPersonas.includes(OTHER_PERSONA_CODE)) {
            setSelectedPersonas(curr => [...curr, OTHER_PERSONA_CODE]);
        } else if (!value && selectedPersonas.includes(OTHER_PERSONA_CODE)) {
            setSelectedPersonas(curr => curr.filter(code => code !== OTHER_PERSONA_CODE));
        }
        setOtherPersonaDesc(value);
    }

    function handleNext(id?: string) {
        setBusy?.(true);
        setShouldSaveData(true);
        return false;
    }

    const disableNext = !selectedPersonas.length || (selectedPersonas.includes(OTHER_PERSONA_CODE) && !otherPersonaDesc);

    const personaCards = useMemo(() => {
        return [...(personas ?? [])].sort((a, b) => a.personaOrder - b.personaOrder)?.filter(persona => {
            return persona?.isApplicableToAllAccessLevels || persona?.applicableAccessLevels?.includes(company?.accessLevel!)
        })?.map(persona => (
            <PersonaCard 
                key={persona.code} 
                persona={persona} 
                className={baseClassName} 
                onClick={handlePersonaCardClick} 
                selected={selectedPersonas.includes(persona.code)}
            />
        ))
    }, [selectedPersonas])

    return (
        <WizardStep className={classnames(baseClassName)} byzRef={stepRef} title={title} id={step}>
            <WizardHeader className={classnames({
                // 'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={`report-run-config-wizard__step-title`}>
                    {title}
                </h1>
            </WizardHeader>
            <WizardContent>

                <div className={`${baseClassName}`}>
                    <h1 id={'persona_which_role_fits_you'}>Which role(s) fits you best?</h1>
                    <div className={`${baseClassName}__persona-cards`}>
                        {personaCards}
                    </div>
                    <div className={`${baseClassName}__persona-other`}>
                        <span>Other:</span>
                        <ByzzerTextInput onChange={handleOtherPersonaInput} value={otherPersonaDesc} name={'other_persona'} placeholder={"Type in the role that describes your duties the best"} />
                    </div>
                </div>

            </WizardContent>
            <WizardActions 
                disableNext={disableNext} 
                nextDisabledTip={'Please select or enter a role to continue.'} 
                beforeNext={handleNext}
                nextText={nextText}
            />
        </WizardStep>
    );

});

export default AboutYou;

AboutYou.displayName = 'AboutYou';

