import './DodProductFilterBuilder.scss';
import React, { ReactNode, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { DodSummedSelectionEditor } from '@/components/DodConfigEditor/common';
import { DodFilterType, DodProductFilterType } from '@/components/DodConfigEditor/types';
import { DodPanel, DodPanelRef } from '@/components/DodConfigEditor/common/DodPanel';
import { confirm } from '@/components/form/ByzzerModal';
import { isCharacteristicFilter, isMarketResetRequired } from '@/components/DodConfigEditor/common/utils';
import { DodProductFilterPreview } from './DodProductFilterPreview';
import { DodProductFilterValuePicker } from './DodProductFilterValuePicker';
import { DodProductFilterTypePicker } from './DodProductFilterTypePicker';
import { useUser } from '@/contexts/UserContext';
import { DodPreset } from '@/types/ApiTypes';
import {
    DodCharacteristicFilter,
    DodCharacteristicFilterMap,
    DodFilter,
    DodFilters,
    SummedSelection,
} from '@/types/DodRun';
import { useDodWizard } from '../../DodRunConfigWizard/DodWizardContext';

const baseClassName = 'dod-product-filter-builder';

export type DodProductFilterBuilderProps = {
    className?: string;
    name?: string;
    value: DodFilters;
    tip?: ReactNode;
    onChange: ByzzerChangeEventHandler<DodFilters>;
    onValidityChange?(e: ByzzerValidityChangeEvent): void;
    resetFactSelections(filterType: string): void
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

function placeholderForProductType(filterType: DodProductFilterType) {
    if(!filterType) return `search field values`
    switch (filterType) {
        case 'upcs':
            return `Search for UPCs (must enter at least 4 digits)`;
        case 'productDescriptions':
            return `Search for product descriptions (must enter at least 4 digits)`;
        default:
            return `Search for ${filterType.replace(/([A-Z])/g, ' $1').trim().toLowerCase()}`;
    }

}

export function DodProductFilterBuilder({
                                            className,
                                            name,
                                            value,
                                            onChange,
                                            onValidityChange,
                                            tip,
                                            resetFactSelections,
                                            ...props
                                        }: DodProductFilterBuilderProps) {

    // alias value as filters for clarity
    const filters = value;
    const { subscription, categories: userCategories } = useUser();
    const { deletePreset } = useDodWizard();
    const isFreeUser = subscription?.metadata?.isFree ?? false
    const allowedHierarchyLevels = subscription?.metadata?.extractLimits?.allowedHierarchyLevels
    const [filterType, setFilterType] = useState<DodFilterType<DodProductFilterType>>({
        type: 'categories',
    });
    const [valueSelectionMode, setValueSelectionMode] = useState<'single' | 'summed'>('single');
    const [summedSelection, setSummedSelection] = useState<SummedSelection | undefined>();
    const [summedSelectionIndex, setSummedSelectionIndex] = useState<number | undefined>();
    const [rawFilterText, setFilterText] = useState<string>('');
    const filterText = useDeferredValue(rawFilterText);
    const singleActions = useMemo<ActionConfig[]>((): ActionConfig[] => [
        {
            icon: 'summed',
            type: 'summed',
            tip: 'Create A Summed Selection',
            action() {
                setValueSelectionMode('summed');
            },
            include: !isFreeUser
        },
    ], []);
    const hasSelections = useMemo<boolean>(() => {
        return Boolean(
            value.categories.values.length + value.categories.summedSelections.length +
            value.departments.values.length + value.departments.summedSelections.length +
            value.superCategories.values.length + value.superCategories.summedSelections.length +
            value.subcategories.values.length + value.subcategories.summedSelections.length +
            value.brands.values.length + value.brands.summedSelections.length +
            value.parentCompanies.values.length + value.parentCompanies.summedSelections.length +
            value.upcs.values.length + value.upcs.summedSelections.length +
            value.productDescriptions.values.length + value.productDescriptions.summedSelections.length +
            value.manufacturers.values.length + value.manufacturers.summedSelections.length +
            Object.keys(value.characteristics).length + Object.keys(value.characteristics).length +
            Object.keys(value.customCharacteristics).length + Object.keys(value.customCharacteristics).length
        )
    }, [value])
    const selectionLimit = useMemo<number>(() => {
      return isFreeUser && filterType.type !== 'subcategories' ? 1 : Infinity
    }, [isFreeUser, filterType]);
    const values = useMemo<string[] | 'all'>(() => {
        if (isCharacteristicFilter(filterType)) {
            return value[filterType.type][filterType.data?.id]?.values ?? [];
        }

        return value[filterType.type]?.values ?? [];

    }, [value, filterType.type, filterType.data]);
    const valuePanelRef = useRef<DodPanelRef>(null);
    const currentSummedSelectionsRef = useRef<string[] | 'all'>([]);
    const applyBtnClickRef = useRef<boolean>(false);

    useEffect(() => {
        const hasValues = Boolean(value.categories.values.length + value.categories.summedSelections.length);

        onValidityChange?.({
            name,
            isValid: hasValues,
        });
    }, [value.categories.values, value.categories.summedSelections]);

    useEffect(() => {
        setFilterText('');
    }, [filterType])

    function removeMarketPreset() {
        deletePreset('market');
    }

    function handleFilterChange(e: ByzzerChangeEvent<DodFilters>) {
        onChange?.({
            name,
            value: e.value,
        });
    }

    function isSameSummedSelections() {
        const selectionValues = summedSelection?.values || [];
        const currentSelection = currentSummedSelectionsRef.current;

        if (Array.isArray(currentSelection) && Array.isArray(selectionValues)) {
            return isEqual([...currentSelection].sort(), [...selectionValues].sort());
        }

        return currentSelection === selectionValues;
    }

    async function handleFilterTypeChange(e) {
        if (valueSelectionMode === 'summed' && !isSameSummedSelections() && !applyBtnClickRef.current) {
            const result = await confirm({
                content: 'You are navigating to a different field. Do you want to add your summed products to selection first?',
                yesLabel: 'Yes',
                noLabel: 'No',
            });
            if (result) return;
            resetNavigateAwayState();
        }

        if (e.value.type === 'mySavedProducts') {
            onChange({ name, value: e.value.data });
        } else {
            setFilterType(e.value);
            reset();
        }
    }

    async function handleSummedSelectionChange(e: ByzzerChangeEvent<SummedSelection>) {
        resetNavigateAwayState();

        const requiresMarketReset = isMarketResetRequired(filterType, filters);
        if (requiresMarketReset && !(await confirmMarketReset())) return;

        const updates = {...value};
        if (requiresMarketReset) {
            // todo: only remove markets don't match the selected categories
            updates.markets = {
                values: [],
                summedSelections: [],
            }
            removeMarketPreset()
        }

        setSummedSelection(e.value);
        const updatedValue = value[filterType.type];
        let filter: DodFilter | DodCharacteristicFilterMap;

        if (isCharacteristicFilter(filterType)) {
            filter = (updatedValue as DodCharacteristicFilterMap);
            const {id, displayName} = filterType.data;
            const characteristic = filter[id] ?? {
                characteristic: filterType.data.code,
                displayName,
                values: [],
                summedSelections: []
            };
            if (summedSelectionIndex === undefined) {
                characteristic.summedSelections = [...characteristic.summedSelections, e.value];
            } else {
                characteristic.summedSelections = characteristic.summedSelections.map((v, i) => {
                    return i === summedSelectionIndex ? e.value : v;
                });
            }
            filter[id] = characteristic;
        } else {
            filter = updatedValue as DodFilter<string[] | 'all'>;
            if (summedSelectionIndex === undefined) {
                filter.summedSelections = [...filter.summedSelections, e.value];
            } else {
                filter.summedSelections = filter.summedSelections.map((v, i) => {
                    return i === summedSelectionIndex ? e.value : v;
                });
            }
        }

        onChange({
            name,
            value: {
                ...value,
                [filterType.type]: filter,
                ...updates
            },
        });
        setSummedSelectionIndex(undefined);
        setSummedSelection(undefined);
        setValueSelectionMode('single');
    }

    function handleSummedSelectionCancel() {
        reset();
    }

    async function handleFilterValueChange(e: ByzzerChangeEvent<string[] | 'all'>) {

        const requiresMarketReset = isMarketResetRequired(filterType, filters);
        if (requiresMarketReset && !(await confirmMarketReset())) return;

        const updates = {...value};
        if (requiresMarketReset) {
            // todo: only remove markets don't match the selected categories
            updates.markets = {
                values: [],
                summedSelections: [],
            }
            removeMarketPreset()
        }

        if (isCharacteristicFilter(filterType)) {
            const {id, displayName} = filterType.data;
            if (updates[filterType.type][id]) {
                updates[filterType.type][id].values = e.value;
            } else {
                updates[filterType.type][id] = {
                    characteristic: id,
                    displayName,
                    values: e.value,
                    summedSelections: []
                } as DodCharacteristicFilter;
            }

            if (
                updates[filterType.type][id]?.values?.length === 0 &&
                updates[filterType.type][id]?.summedSelections?.length === 0
            ) {
                delete updates[filterType.type][id];
            }
        } else {
            updates[filterType.type].values = e.value;
        }

        onChange({
            name,
            value: {
                ...value,
                ...updates
            }
        });

        if ((filterType.type === 'upcs' || filterType.type === 'brands') && e.value.length === 0) {
            resetFactSelections?.(filterType.type);
        }
    }

    function handleEditSummedSelection(filterType: DodFilterType, index: number) {
        setValueSelectionMode('summed');
        setSummedSelectionIndex(index);
        setFilterType(filterType as any);
        if (isCharacteristicFilter(filterType)) {
            const summedSelectionValues = value[filterType.type][filterType.data?.id].summedSelections[index];
            setSummedSelection(summedSelectionValues);
            currentSummedSelectionsRef.current = summedSelectionValues?.values || [];
        } else {
            const summedSelectionValues = value[filterType.type].summedSelections[index];
            setSummedSelection(summedSelectionValues);
            currentSummedSelectionsRef.current = summedSelectionValues?.values || [];
        }
    }

    function handleDeleteSummedSelection(filterType: DodFilterType, index: number) {
        reset();
    }

    function handleDeleteSummedSelectionValue(filterType: DodFilterType, index: number) {

    }

    async function handlePresetSelect(e: ByzzerChangeEvent<DodPreset>): Promise<void> {
        const requiresMarketReset = isMarketResetRequired(filterType, filters);
        if (requiresMarketReset && !(await confirmMarketReset())) return;
        let { timePeriods, categories, ...productFilters} = e.value.values as DodFilters;

        const validatedCategories: DodFilter = {
            values: categories.values === 'all' ? 'all' : categories.values.filter((item) => userCategories.includes(item)) ,
            summedSelections: categories.summedSelections.map((category) => {
                return ({
                    ...category,
                    values: category.values === 'all' ? 'all': category.values.filter((item) => userCategories.includes(item))
                })
            }) 
        }

        onChange({
            name,
            value: {
                ...value,
                ...productFilters as DodFilters,
                categories: validatedCategories,
                markets: {
                    values: [],
                    summedSelections: []
                }
            }
        });
        removeMarketPreset()
    }

    function resetNavigateAwayState() {
        applyBtnClickRef.current = false;
        currentSummedSelectionsRef.current = [];
    }

    function reset() {
        valuePanelRef.current?.resetFilter();
        setValueSelectionMode('single');
        setSummedSelection(undefined);
        setSummedSelectionIndex(undefined);
        resetNavigateAwayState();
    }

    function resetFilterToCategories() {
        setFilterType({
            type: 'categories'
        })
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <DodProductFilterTypePicker
                className={classnames(`${baseClassName}__filters`)}
                value={filterType}
                onChange={handleFilterTypeChange}
                filters={value}
                onPresetSelect={handlePresetSelect}
                allowedHierarchyLevels ={allowedHierarchyLevels}
                onNoCustomChars={resetFilterToCategories}
                showPresetWarning={hasSelections}
            />

            <DodPanel filterPlaceholder={placeholderForProductType(filterType.type)}
                      byzRef={valuePanelRef}
                      onFilterChange={setFilterText}
                      expandable={true}
                      name={'product-filter-picker'}
                      filterType={filterType}
                      trackClick={{ data: { dodWizardStep: 'product', panel: "value picker" } }}
            >
                {valueSelectionMode === 'single' && (
                    <DodProductFilterValuePicker
                        key={filterType.type}
                        filters={filters}
                        filterType={filterType}
                        filterText={filterText}
                        value={values}
                        actions={singleActions}
                        includeActions={true}
                        onApply={handleFilterValueChange}
                        limit = {selectionLimit}
                    />
                )}
                {valueSelectionMode === 'summed' && (
                    <DodSummedSelectionEditor<string[] | 'all'>
                        key={`${filterType.type}:${summedSelectionIndex}`}
                        onApply={handleSummedSelectionChange}
                        onCancel={handleSummedSelectionCancel}
                        value={summedSelection}
                        includeActions={true}
                        filterType={filterType.type}
                        dodWizardStep='product'
                    >
                        {({onChange, values, className}) => (
                            <DodProductFilterValuePicker
                                key={`${filterType.type}:${summedSelectionIndex}`}
                                filters={filters}
                                filterType={filterType}
                                filterText={filterText}
                                value={values!}
                                onChange={(data) => {
                                  applyBtnClickRef.current = false;
                                  currentSummedSelectionsRef.current = data?.value || [];
                                  onChange(data)
                                }}
                            />
                        )}
                    </DodSummedSelectionEditor>
                )}
            </DodPanel>
            <DodProductFilterPreview
                value={value}
                onChange={handleFilterChange}
                onEditSummedSelection={handleEditSummedSelection}
                onDeleteSummedSelection={handleDeleteSummedSelection}
                onDeleteSummedSelectionValue={handleDeleteSummedSelectionValue}
                resetFactSelections={resetFactSelections}
            />
        </div>
    );
}

export async function confirmMarketReset(): Promise<boolean> {
    return confirm({
        title: 'Warning',
        content: (<>
            <p>Changing your category selections will clear the markets selected on the next tab.</p>
            <p>If you continue you will have to redo your market selections.</p>
        </>),
        yesLabel: 'Continue',
        noLabel: 'Cancel',
    });
}

export default DodProductFilterBuilder;