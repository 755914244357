import './DodProductFilterTypePicker.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import {
    BRAND_AND_MANUFACTURER_INFO,
    CATEGORY_SELECTION_INFO,
    CHARACTERISTICS_INFO,
    CUSTOM_CHAR_INFO,
    HIERARCHY_INFO,
    SAVED_PRODUCT_INFO,
} from '@/views/DataOnDemand/Products/ProductConstants';
import { filterHasExplicitValues, filterValuesToStrings } from '@/components/DodConfigEditor/common/utils';
import { useCharacteristicService } from '@/services/characteristic.service';
import { useUser } from '@/contexts/UserContext';
import {
    DodFilterTypeOptionGroup,
    DodFilterTypePicker, DodFilterTypePickerProps,
    DodFilterTypePickerRef,
} from '@/components/DodConfigEditor/common';
import { DodFilters } from '@/types/DodRun';
import { DodFilterType, DodProductFilterType } from '@/components/DodConfigEditor/types';
import classnames from 'classnames';
import { DodPreset } from '@/types/ApiTypes';
import { useTenantApi } from '@/hooks';
import { useCategoryService } from '@/services/category.service';

const baseClassName = 'dod-product-filter-type-picker';

export type DodProductFilterTypePickerProps = {
    name?: string;
    className?: string;
    value: DodFilterType<DodProductFilterType>;
    onChange: ByzzerChangeEventHandler<DodFilterType<DodProductFilterType>>;
    onPresetSelect: ByzzerChangeEventHandler<DodPreset>;
    filters: DodFilters;
    tipDelay?: number | [number | null, number | null];
    onNoCustomChars: () => void;
    allowedHierarchyLevels?: string[];
} & Partial<Omit<DodFilterTypePickerProps<DodFilters, DodFilterType<DodProductFilterType>>, 'initialOptionGroups'>>;

function valueMatcher(a: DodFilterType<DodProductFilterType>, b: DodFilterType<DodProductFilterType>): boolean {
    return a?.type === b?.type && a?.data === b?.data;
}

const EXCLUDED_CHARACTERISTICS = ['unicorn_subcategory', 'brand', 'brand_owner', 'brand_owner_high']; //BYZ-11227

export function DodProductFilterTypePicker({
    className,
    onChange,
    filters,
    onNoCustomChars,
    allowedHierarchyLevels,
    ...props
}: DodProductFilterTypePickerProps) {
    const { getCharacteristicsForCategories } = useTenantApi();
    const { categories: userCategories } = useUser();
    const { getCustomCharacteristicsForCategories } = useCharacteristicService();
    const { getCategoriesBasedOnFilter } = useCategoryService();
    const [optionGroups] = useState<DodFilterTypeOptionGroup<DodFilters, DodFilterType<DodProductFilterType>>[]>([
        {
            label: 'Hierarchy',
            tip: HIERARCHY_INFO,
            options: [
                { label: 'Department', value: { type: 'departments' } },
                { label: 'Super Category', value: { type: 'superCategories' } },
                { label: 'Category', value: { type: 'categories' } },
                {
                    label: 'Subcategory',
                    value: { type: 'subcategories' },
                    disabled(filters) {
                        return (
                            (allowedHierarchyLevels && !allowedHierarchyLevels.includes('subcategory'))
                            || (filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0)
                        );
                    },
                },
                {
                    label: 'UPC',
                    value: { type: 'upcs' },
                    disabled(filters) {
                        return (
                            (allowedHierarchyLevels && !allowedHierarchyLevels.includes('upc'))
                            || (filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0)
                        );
                    },
                },
                {
                    label: 'Product Description',
                    value: { type: 'productDescriptions' },
                    disabled(filters) {
                        return (
                            (allowedHierarchyLevels && !allowedHierarchyLevels.includes('productDescription'))
                            || (filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0)
                        );
                    },
                },
            ],
            disabledTip: CATEGORY_SELECTION_INFO,
        },
        {
            label: 'Brand & Manufacturer',
            tip: BRAND_AND_MANUFACTURER_INFO,
            options: [
                {
                    label: 'Parent Company',
                    value: { type: 'parentCompanies' },
                    disabled(filters) {
                        return (
                            (allowedHierarchyLevels && !allowedHierarchyLevels.includes('parentCompany')) 
                            || (filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0)
                        );
                    },
                },
                {
                    label: 'Manufacturer',
                    value: { type: 'manufacturers' },
                    disabled(filters) {
                        return (
                            (allowedHierarchyLevels && !allowedHierarchyLevels.includes('manufacturer')) 
                            || (filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0)
                        );
                    },
                },
                {
                    label: 'Brand',
                    value: { type: 'brands' },
                    disabled(filters) {
                        return (
                            (allowedHierarchyLevels && !allowedHierarchyLevels.includes('brand')) 
                            || (filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0)
                        );
                    },
                },
            ],
            disabledTip: CATEGORY_SELECTION_INFO,
        },
        {
            key: 'characteristics',
            label: 'Characteristics',
            tip: CHARACTERISTICS_INFO,
            async loadOptions(filters) {
                const categories = getCategoriesBasedOnFilter(filters, userCategories);
                const characteristics = await getCharacteristicsForCategories(categories);
                return characteristics
                    .filter((char) => !EXCLUDED_CHARACTERISTICS.includes(char.code))
                    .map((char) => ({
                        label: char.displayName,
                        value: {
                            type: 'characteristics',
                            data: {
                                id: char.code,
                                displayName: char.displayName,
                            },
                        },
                    }));
            },
            disabled(filters) {
                return (allowedHierarchyLevels && !allowedHierarchyLevels.includes('characteristic')) || filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0;
            },
            disabledTip: CATEGORY_SELECTION_INFO,
        },
        {
            key: 'customCharacteristics',
            label: 'My Custom Characteristics',
            tip: CUSTOM_CHAR_INFO,
            loadOptions(filters) {
                const categories = getCategoriesBasedOnFilter(filters, userCategories);
                let characteristics: CustomCharacteristic[];
                if (filterHasExplicitValues(filters.categories)) {
                    characteristics = getCustomCharacteristicsForCategories(
                        filterValuesToStrings(filters?.categories, userCategories)
                    );
                } else {
                    if (
                        filters.categories.values === 'all' ||
                        filters.categories.summedSelections.some((sum) => sum.values === 'all')
                    ) {
                        characteristics = getCustomCharacteristicsForCategories(categories);
                    } else {
                        characteristics = [];
                    }
                }
                if(!characteristics.length) onNoCustomChars();
                return characteristics.map((char) => ({
                    label: char.label,
                    value: {
                        type: 'customCharacteristics',
                        data: {
                            id: char.id,
                            displayName: char.label,
                        },
                    },
                }));
            },
            disabled(filters) {
                return (allowedHierarchyLevels && !allowedHierarchyLevels.includes('customCharacteristic')) || filters?.categories.values.length === 0 && filters?.categories.summedSelections.length === 0;
            },
            disabledTip: CATEGORY_SELECTION_INFO,
        },
    ]);
    const ref = useRef<DodFilterTypePickerRef>(null);

    // refresh the characteristics if and only if the categories change
    useEffect(() => {
        ref.current?.reloadOptionGroupsByKeys(['characteristics', 'customCharacteristics']);
    }, [filters.categories.values, filters.categories.summedSelections]);

    function handleChange(e: ByzzerChangeEvent<DodFilterType<DodProductFilterType>>) {
        onChange(e);
    }

    return (
        <DodFilterTypePicker
            className={classnames(baseClassName, className)}
            pickerRef={ref}
            filters={filters}
            initialOptionGroups={optionGroups}
            onChange={handleChange}
            valueMatcher={valueMatcher}
            presetType={'product'}
            presetGroupLabel={'My Saved Products'}
            presetGroupTip={SAVED_PRODUCT_INFO}
            filterPlaceholder={'Search Product Folders'}
            defaultExpandAll={true}
            {...props}
        />
    );
}

export default DodProductFilterTypePicker;
