// To Do: Refactor this file based on latest coding standards
import { ByzzerButton } from '@byzzer/ui-components';
import '../../../pages/MyAlertsPage/MyAlertsPage.scss';
import parse from 'react-html-parser';
import back from '@images/icons/back-icon.svg';
import '../../../pages/ProductConfigurationEditor.scss';
import {useUser} from "@/contexts/UserContext";
import {useNavigate} from 'react-router-dom';

import {
    ByzzerPink,
    coloredSpan,
    defaultValue,
    formatDateMinusOneMMDDYYYY,
    formatDateMMDDYYYY,
    formatPercent,
    formatPercentWithoutSign,
    formatPercentWithSign,
    formatPrice,
    formatTitle,
    generateBasicOrderedList,
    generateOrderedList,
    generateOrderedListforPricePercentile,
    generateOrderedListWithBrandAndGrowth,
    generateTable,
    getDirection,
    greenColor,
    numberWith1Decimal,
    numberWith2Decimal,
    numberWithCommas,
    pluralCheck,
    promoEffColor,
    redColor,
    returnColorByDirection,
    returnColorSpan,
    returnNewtext,
    subtractWeeks,
    textBold,
    toTitleCase,
    toTitleCaseWithComma,
    txtColor,
    valueCheck
} from '../utils/alertUtil1_4';
import {isSaturday} from 'date-fns';

function MyAlertView1_4(props) {
    const { 
        alertData: {
            createdBy
        }
    } = props;
    let viewReportAlertsSKU = props.viewReportAlertsSKU;
    const { user, company } = useUser();
    // const [currentAlertTitle, setCurrentAlertTitle] = useState(() => {
    //     return '';
    // });
    // const [CurrentAlertBlurb, setCurrentAlertBlurb] = useState(() => {
    //     return '';
    // })
    // const [currentAlertDuration, setCurrentAlertDuration] = useState(() => {
    //     return '';
    // })

    // const [showCurrentAlert, setShowCurrentAlert] = useState(false);

    const tableConfig = {
        percentKeys: [],
        titleCaseColumns: [],
        dollarColumns: [],
        columnMapping: {},
        defaultValue: {},
    };

    // const [currentAlertBody, setCurrentAlertBody] = useState(() => {
    //     return [];
    // });

    const footerLink = '<a target="_blank" href="https://learn.byzzer.ai">Byzzer Resources</a>';
    let footerText =
        'FMCG Retailers or Fast Moving Consumer Goods is composed of Grocery, Drug, Mass, Dollar, Club, and Military Retailers. For more information about your alerts and the data powering them, head to the ' +
        footerLink;

    let alert = props.alertData;
    // let alertResults = props.alertData.results;
    let alertResults = JSON.parse(JSON.stringify(alert.results).replace(/Fmcg/g, 'FMCG'));

    const alertMap = props.alertMap;
    // console.log(alertResults)
    // let config = props.alertConfig;
    let config = { ...tableConfig };
    let config1 = { ...tableConfig };

    let currentAlertBody = [];

    let dt = new Date(alertResults.filter_duration.split('to')[1].trim());
    let duration;
    if (!isSaturday(dt)) {
        duration = formatDateMMDDYYYY(dt.setDate(dt.getDate() - 1));
    } else {
        duration = formatDateMMDDYYYY(dt);
    }

    // let duration = formatDateMMDDYYYY(alertResults.filter_duration.split('to')[1].trim());

    // setCurrentAlertTitle(alert.title);
    // // setCurrentAlertBlurb(alert.blurb);
    // setCurrentAlertDuration(duration);

    let filter_data = {
        filter_category: toTitleCase(alertResults.filter_category),
        filter_market: toTitleCase(alertResults.filter_market),
        filter_brand: toTitleCase(alertResults.filter_brand),
    };
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_SHARE') {
        config.columnMapping = {
            market: 'Market',
            brand_level_share: 'Brand $ Share',
            share_change: 'Share pt Chg',
            brand_level_brand_growth: 'Brand Growth',
        };
        // config.defaultValue = {
        //     share_change: 'N/A',
        //     brand_level_brand_growth: 'N/A',
        // };
        config.percentKeys = ['brand_level_share', 'brand_level_brand_growth'];
        let filter_duration = `4 weeks ending ${duration}`;

        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance:</div>);
        currentAlertBody.push(
            parse(
                `In the last ${filter_duration},
                 ${filter_data.filter_brand} ${txtColor(`${alertResults.share_of_category_change_gained_or_lost} 
                market share by ${formatPercent(alertResults.share_of_category_change)} points`, `${alertResults.share_of_category_change_gained_or_lost}`)}${((alertResults.reason ? ' ' + alertResults.reason + '.' : null) || '.')
                    .toString().replace('the category', filter_data.filter_category)}`)
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your category performance:</div>);

        // for value 1

        let alertResults_category_sales_change_display = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(
            alertResults.category_sales_change)}%` : '';


        // for value 2

        let alertResults_sales_change_percent_display = valueCheck(alertResults.sales_change_percent_direction) ? ` ${formatPercent(alertResults.sales_change_percent)}%` : '';

        currentAlertBody.push(
            parse(
                `Overall sales in the ${filter_data.filter_category} category are $${numberWithCommas(
                    alertResults.total_dollars
                )}. The category sales are ${txtColor(`${alertResults.category_sales_change_direction}${alertResults_category_sales_change_display}`, `${alertResults.category_sales_change_direction}`)} and your sales 
                    ${txtColor(`${alertResults.sales_change_percent_direction}${alertResults_sales_change_percent_display}`, `${alertResults.sales_change_percent_direction}`)}, 
                    resulting in a   
                    ${txtColor(`${formatPercent(alertResults.share_of_category_change)} 
                    share point ${alertResults.share_of_category_change_gain_or_decline}`, `${alertResults.share_of_category_change_gain_or_decline}`)} to ${formatPercent(alertResults.share_of_category)}% vs  prior year.`
            )
        );
        currentAlertBody.push(
            parse(
                `Are you keeping pace with other brands in ${filter_data.filter_category
                }? Find out how you compare on the ${textBold('Brand Share Report.')}`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your performance by market:</div>);

        let highest_market_text = '';
        if (alertResults.biggest_share_gains_by_market?.length) {
            let highest_share_change_direction = getDirection(alertResults.highest_share_change, 'gained', 'lost', 'changed by');

            highest_market_text = `${filter_data.filter_brand}'s greatest share increase was ${alertResults.highest_market
                } where you ${txtColor(`${highest_share_change_direction} ${formatPercent(alertResults.highest_share_change)} share points`, highest_share_change_direction)}.`;
        }

        currentAlertBody.push(
            parse(
                `At the market level, ${filter_data.filter_brand} gained share in ${alertResults.gained_share_from_total_retailers
                } markets and lost share in ${alertResults.lost_share_from_total_retailers
                } markets. ${highest_market_text} To find out the drivers of your performance check out ${textBold(
                    'Business Drivers Waterfall Report'
                )} which quantifies the volume impact of levers such as price, promotion and distribution.`
            )
        );
        if (alertResults.biggest_share_gains_by_market?.length) {
            currentAlertBody.push(<div className="detailed_card_subheading">Your biggest share point gains were:</div>);
            currentAlertBody.push(parse(generateTable(alertResults.biggest_share_gains_by_market, config)));
        }

        let lowest_market_text = '';

        if (alertResults.biggest_share_losses_by_market?.length) {
            let lowest_share_change_direction = getDirection(alertResults.lowest_share_change, 'lost', 'lost', 'changed by');
            lowest_market_text = `${filter_data.filter_brand}'s greatest share decrease was ${alertResults.lowest_market
                } where you ${txtColor(`${lowest_share_change_direction} ${formatPercent(alertResults.lowest_share_change)} share points`, lowest_share_change_direction)}.`;
        }

        currentAlertBody.push(
            parse(
                `${lowest_market_text} Benchmark the performance of your ${filter_data.filter_brand
                } products to other Brands on the ${textBold('Product Performance Report.')}`
            )
        );

        //{alertResults.biggest_share_losses_by_market}
        if (alertResults.biggest_share_losses_by_market?.length) {
            currentAlertBody.push(<div className="detailed_card_subheading">Your biggest share point losses were:</div>);
            currentAlertBody.push(parse(generateTable(alertResults.biggest_share_losses_by_market, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on the ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Last Year.`
        );
        currentAlertBody.push(parse(footerText));

        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'COMPETITIVE_PRICING') {
        config.columnMapping = {
            brand: 'Brand',
            total_average_price_for_l4w: 'Avg Price',
            non_promo_average_price_for_l4w: 'NP Price',
            everyday_price_change: 'NP % Chg',
            sales_promo: '% Sales Promo',
            any_promo_average_price_for_l4w: 'Promo Price',
            promotion_price_change: 'Promo Price % Chg',
        };
        // config.defaultValue = {
        //     non_promo_average_price_for_l4w: 'N/A',
        //     everyday_price_change: 'N/A',
        //     sales_promo: 'N/A',
        //     any_promo_average_price_for_l4w: 'No discount',
        // };
        config.percentKeys = ['everyday_price_change', 'sales_promo', 'promotion_price_change'];
        config.titleCaseColumns = ['brand'];
        config.dollarColumns = [
            'total_average_price_for_l4w',
            'non_promo_average_price_for_l4w',
            'any_promo_average_price_for_l4w',
        ];
        let filter_duration = `4 weeks ending ${duration}`;

        currentAlertBody.push(<div className="detailed_card_subheading">Your Pricing:</div>);

        currentAlertBody.push(
            parse(
                `The ${filter_data.filter_brand}'s average price was ${returnColorSpan(`$${alertResults.total_average_price_for_l4w_yes}`, `ByzzerPink`)}
                for the ${filter_duration}${returnNewtext(`, ${txtColor(`${alertResults.total_average_price_change_yes_direction} by ${formatPercent(alertResults.total_average_price_change_yes
                )}%`, `${alertResults.total_average_price_change_yes_direction}`, true)}
                    vs. prior 4 weeks, causing your ratio to competitors to change by ${txtColor(`${formatPercent(
                    alertResults.ratio_change)}%`, alertResults.ratio_change, true)} `, alertResults.total_average_price_change_yes_direction)} in ${filter_data.filter_category}. ${alertResults.ratio_change_inc_decr
                } in your pricing ratio to competitors indicates you are relatively ${alertResults.ratio_change_more_less
                } expensive than your competitors than you were in the previous 4 weeks. Understand how your price position is changing vs. the category and other brands in the ${textBold(
                    'Price Comparison vs. Category Report.'
                )}`
            )
        );
        currentAlertBody.push(<div className="detailed_card_subheading">Your Category Pricing:</div>);

        currentAlertBody.push(
            parse(
                `The average price for ${filter_data.filter_category} is $${alertResults.total_average_price_for_l4w_no}${returnNewtext(`, ${txtColor(`${alertResults.total_average_price_change_no_direction} ${formatPercent(
                    alertResults.total_average_price_change_no
                )}%`, `${alertResults.total_average_price_change_no_direction}`, true)}                
                vs. prior 4 weeks`, alertResults.total_average_price_change_no_direction)}. The average price is impacted by the everyday non-promoted prices, promoted prices, and proportion of sales that are promoted.`
            )
        );


        currentAlertBody.push(
            parse(
                `The average everyday price was $${formatPrice(
                    alertResults.non_promo_average_price_for_l4w
                )}${returnNewtext(`, ${txtColor(`${formatPercentWithSign(alertResults.change_non_promo_price)}%`, alertResults.change_non_promo_price, true)} vs. prior 4 weeks`, alertResults.change_non_promo_price)}.`
            )
        );
        currentAlertBody.push(
            parse(
                `The average promoted price was                 
            $${alertResults.any_promo_average_price_for_l4w === 'No discount'
                    ? alertResults.any_promo_average_price_for_l4w
                    : formatPrice(alertResults.any_promo_average_price_for_l4w)
                }${returnNewtext(`, ${txtColor(`${formatPercentWithSign(alertResults.change_promo_price)}%`, alertResults.change_promo_price, true)}  vs. prior 4 weeks`, alertResults.change_promo_price)}.`
            )
        );

        currentAlertBody.push(
            parse(
                `${formatPercent(alertResults.on_deal)}% of volume was sold on promotion${returnNewtext(`, ${txtColor(`${formatPercentWithSign(alertResults.on_deal_change)}%`, alertResults.on_deal_change, true)}  vs. prior 4 weeks`, alertResults.on_deal_change)}.`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your Competitor Pricing:</div>);

        currentAlertBody.push(
            parse(
                `What brands to watch? Check out which brands had the greatest pricing changes over the past 4 weeks in ${filter_data.filter_category
                }. For more details on the everyday vs. promoted price run the ${textBold(
                    'Price Change Over Time Report.'
                )}`
            )
        );
        if (alertResults.biggest_price_change?.length) {
            currentAlertBody.push(
                `Brands with the biggest increase in average price, with details on the Everyday/Non-promoted(NP) Price and Promoted Price:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_price_change, config)));
        }
        if (alertResults.lowest_price_change?.length) {
            currentAlertBody.push(
                `Brands with the biggest decrease in average price, with details on the Everyday/Non-promoted(NP) Price and Promoted Price:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.lowest_price_change, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on the ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Previous 4 Weeks.`
        );
        currentAlertBody.push(parse(footerText));
        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'DISTRIBUTION_GAIN_AND_LOSSES') {
        config.percentKeys = ['tdp_share', 'category_share'];
        config.columnMapping = {
            market: 'Market',
            tdp_share: 'Ditro Shr',
            tdp_share_change: 'Distro Shr pt Chg',
            category_share: 'Cat Share',
            category_share_pt_change: 'Cat Shr pt Chg',
            distribution_fair_share: 'Distro Fair Shr',
        };
        // config.defaultValue = {
        //     tdp_share_change: 'New market',
        //     category_share_pt_change: 'New market',
        // };
        let filter_duration = `13 weeks ending ${duration}`;
        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance:</div>);

        let alertResults_category_tdps_change_ya_direction_display = valueCheck(alertResults.category_tdps_change_ya_direction)
            ? `${formatPercent(alertResults.category_tdps_change_ya)}% `
            : '';

        currentAlertBody.push(
            parse(
                `${filter_data.filter_brand}'s distribution is 
                ${txtColor(`${alertResults.category_tdps_change_ya_direction
                    } ${alertResults_category_tdps_change_ya_direction_display}`, `${alertResults.category_tdps_change_ya_direction}`)}in the latest ${filter_duration} and your share of distribution in ${filter_data.filter_category
                } has ${txtColor(`${alertResults.share_of_tdp_diff_direction} by ${formatPercent(Math.abs(alertResults.share_of_tdp_diff))} share points`, `${alertResults.share_of_tdp_diff_direction}`)}         
                from ${formatPercent(alertResults.share_of_tdp_year_ago)}% to ${formatPercent(
                    alertResults.share_of_tdp
                )}% vs. last year. Investigate your performance and share of distribution by week on the ${textBold(
                    'Category and Brand Trend Report.'
                )} Your distribution is made up of all the SKUs your brand offers multiplied by each retailer-store selling. Your share of distribution is how well your products are represented in the category. For a full review of ${filter_data.filter_brand
                }'s distribution vs the category and other brands run the ${textBold(
                    'Assortment Landscape Smart Report.'
                )}`
            )
        );
        currentAlertBody.push(
            parse(
                `Benchmark your share of distribution to your share of sales to ensure you are receiving your fair share. Your share of distribution is ${formatPercent(
                    alertResults.share_of_tdp
                )}% and your share of sales is ${formatPercent(alertResults.share_of_sales)}% meaning you are 
            ${txtColor(`${alertResults.category_sales_share_direction} distributed `, `${alertResults.category_sales_share_direction}`)}
            based on your performance and you should ${alertResults.category_sales_share_comment}.`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your Category Performance:</div>);


        let alertResults_category_tdps_change_percent_direction_display = valueCheck(alertResults.category_tdps_change_percent_direction) ? ` ${formatPercent(alertResults.category_tdps_change_percent)}%` : '';
        currentAlertBody.push(
            parse(
                `${filter_data.filter_category} distribution is 
                ${txtColor(`${alertResults.category_tdps_change_percent_direction}${alertResults_category_tdps_change_percent_direction_display}`, `${alertResults.category_tdps_change_percent_direction}`)}.`
            )
        );
        if (alertResults.biggest_gain_brands?.length) {
            currentAlertBody.push(
                `The Brands with the biggest gains in distribution for ${filter_data.filter_category} were:`
            );
            currentAlertBody.push(parse(generateBasicOrderedList(alertResults.biggest_gain_brands)));
        }
        if (alertResults.biggest_gain_brands?.length) {
            currentAlertBody.push(`The biggest declines in distribution were:`);
            currentAlertBody.push(parse(generateBasicOrderedList(alertResults.biggest_declined_brands)));
        }

        currentAlertBody.push(
            parse(
                `Benchmark the share of distribution and distribution growth of your products to other Brands with the ${textBold(
                    'Product Performance Report.'
                )}`
            )
        );
        currentAlertBody.push(<div className="detailed_card_subheading">Your performance across markets:</div>);

        let highest_increase_share_pt_market = '';
        let highest_decline_share_pt_market = '';
        let highest_incr_decr_share_pt_market_text = `. ${filter_data.filter_brand} had the `;
        if (alertResults.highest_increase_tdp_share_change_pt > 0) {
            highest_increase_share_pt_market =
                `${txtColor(`greatest increase in distribution`, `increased`)} in ${alertResults.highest_increase_by_market
                } (${txtColor(`${formatPercent(alertResults.highest_increase_tdp_share_diff)} share pts`, alertResults.highest_increase_tdp_share_diff)} to ${formatPercent(alertResults.highest_increase_tdp_share)}%)`
                ;
        }

        if (alertResults.highest_decline_tdp_share_change_pt < 0) {
            highest_decline_share_pt_market =
                `${txtColor(`greatest distribution decline`, `decline`)} in ${alertResults.highest_decline_by_market
                } (${txtColor(`${formatPercent(alertResults.highest_decline_tdp_share_diff)} share pts`, alertResults.highest_decline_tdp_share_diff)} to ${formatPercent(
                    alertResults.highest_decline_tdp_share
                )}%)`
                ;
        }

        if (highest_increase_share_pt_market && highest_decline_share_pt_market)
            highest_incr_decr_share_pt_market_text += highest_increase_share_pt_market + ' and ' + highest_decline_share_pt_market;
        else if (highest_increase_share_pt_market)
            highest_incr_decr_share_pt_market_text += highest_increase_share_pt_market;
        else if (highest_decline_share_pt_market)
            highest_incr_decr_share_pt_market_text += highest_decline_share_pt_market;
        else highest_incr_decr_share_pt_market_text = ' greatest ' + '';

        currentAlertBody.push(
            parse(
                `Investigate your Distribution Share to understand where you are not capturing your fair share and should increase distribution or are capturing your fair share and should focus on increasing velocity${highest_incr_decr_share_pt_market_text}. Find out if you are capturing your fair share of distribution based on your performance with the ${textBold(
                    'Market Opportunity Report.'
                )}`
            )
        );
        if (alertResults.biggest_gains?.length) {
            currentAlertBody.push(
                `Compare ${filter_data.filter_brand}'s distribution share (Distro Shr) to ${filter_data.filter_brand}'s category share (Cat Shr) to understand if you are over or under your fair share. ${filter_data.filter_brand}'s biggest distribution (distro) gains were:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_gains, config)));
        }
        if (alertResults.biggest_declines?.length) {
            currentAlertBody.push(
                `Compare ${filter_data.filter_brand}'s distribution share (Distro Shr) to ${filter_data.filter_brand}'s category share (Cat Shr) to understand if you are over or under your fair share. ${filter_data.filter_brand}'s biggest distribution (distro) losses were:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_declines, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Last Year.`
        );
        currentAlertBody.push(parse(footerText));
        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PROMOTION_EFFICIENCY') {
        config.columnMapping = {
            market: 'Market',
            promotion_efficiency: 'Promotion Efficiency',
            change_in_promotion_efficiency: 'Change in Promotion Efficiency',
        };
        // config.defaultValue = {
        //     change_in_promotion_efficiency: 'New market',
        //     promotion_efficiency: 'N/A',
        // };
        config.percentKeys = ['change_in_promotion_efficiency'];
        config.dollarColumns = ['promotion_efficiency'];
        let filter_duration = `13 weeks ending ${duration}`;
        let opportunity_text = alertResults.opportunity
            ? `Bringing promotions up to best in class levels is worth ${returnColorSpan(`$${numberWithCommas(alertResults.opportunity)}`, `ByzzerPink`)}`
            : '';
        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance:</div>);

        currentAlertBody.push(
            parse(
                `In the ${filter_duration}, ${filter_data.filter_brand}'s promotion efficiency in ${filter_data.filter_category
                } is ${promoEffColor(`$${alertResults.promotion_efficiency}`, `${alertResults.promotion_efficiency}`)} , which means for every $1 spent discounting the product, the return is 
                ${promoEffColor(`$${alertResults.promotion_efficiency}`, `${alertResults.promotion_efficiency}`)}                    
                
                in lift (incremental sales dollars). The greater your incremental sales per $1 spent, the more efficient the promotion for you and the retailer. ${filter_data.filter_brand
                }'s efficiency ${returnNewtext(`${txtColor(`${alertResults.change_promotion_efficiency_direction} by ${formatPercent(
                    Math.abs(alertResults.change_promotion_efficiency)
                )}%`, `${alertResults.change_promotion_efficiency_direction}`)} and `, alertResults.change_promotion_efficiency_direction)}is $${formatPrice(alertResults.promotion_efficiency_vs_best_in_class)} ${alertResults.efficiency_more_or_less
                } efficient vs. best in class promotions in your category. ${opportunity_text}. Evaluate your promotion efficiency over time with the ${textBold(
                    'Promotion Incremental Sales Trend Report.'
                )}`
            )
        );
        currentAlertBody.push(<div className="detailed_card_subheading">Your Performance across Markets:</div>);
        currentAlertBody.push(
            parse(
                `At the market level, ${alertResults.total_biggest_increase
                } market(s) have seen an increase in promotion efficiency while ${alertResults.total_biggest_decline
                } market(s) have seen a decline in promotion efficiency. Review your promotion efficiency by market with the ${textBold(
                    'Promotion Efficiency Comparison Report'
                )} or dive deep on a market and the merchandising support with the ${textBold(
                    'Promotion Support and Lift Report.'
                )}`
            )
        );
        if (alertResults.biggest_increase?.length) {
            currentAlertBody.push(
                `Markets with the biggest increase in promotion efficiency for ${filter_data.filter_brand}:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_increase, config)));
        }
        if (alertResults.biggest_declines?.length) {
            currentAlertBody.push(
                `Markets with the biggest decline in promotion efficiency for ${filter_data.filter_brand}:`
            );
            currentAlertBody.push(parse(generateTable(alertResults.biggest_declines, config)));
        }

        currentAlertBody.push(
            `This alert was prepared for ${filter_data.filter_brand} on the ${filter_data.filter_category} based on FMCG Retailers for the ${filter_duration} vs. Last Year. `
        );
        currentAlertBody.push(parse(footerText));

        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ECOSYSTEM_ALERT') {
        let filter_duration = `4 weeks ending ${duration}`;

        // currentAlertBody.push(`Duration: ${alertResults.filter_duration}`);

        // currentAlertBody.push(`Market: ${alertResults.filter_market}`);

        currentAlertBody.push(<div className="detailed_card_subheading">Market Performance:</div>);

        let alertResults_category_sales_change_display_market_performance = valueCheck(alertResults.category_sales_change_direction) ? ` ${formatPercent(alertResults.category_sales_change)}%` : '';

        currentAlertBody.push(
            parse(
                `In the latest ${filter_duration}, ${toTitleCase(
                    alertResults.category
                )} sales are $${numberWithCommas(alertResults.category_sales)},
                ${txtColor(`${alertResults.category_sales_change_direction}${alertResults_category_sales_change_display_market_performance}`, `${alertResults.category_sales_change_direction}`)}
                vs. the prior year.  ${formatPercent(
                    alertResults.sales_from_top_10_market
                )}% of sales are coming from the top 10 markets. Dive deeper into the performance drivers - velocity, distribution, price, promotion efficiency -  of a market with the ${textBold(
                    'Promo vs Non Promo Decomp Tree Report.'
                )}`
            )
        );

        if (alertResults.top_market_sales?.length) {
            currentAlertBody.push(`The largest markets are:`);
            currentAlertBody.push(parse(generateOrderedList(alertResults.top_market_sales, 'top_market_sales')));
        }

        if (alertResults.highest_growing_market?.length) {
            currentAlertBody.push(`The quickest growing markets are:`);
            currentAlertBody.push(
                parse(
                    generateOrderedList(alertResults.highest_growing_market, 'growing_market', 'pos'))
            );
        }

        // {{custom.highest_growing_market}}
        if (alertResults.lowest_growing_market?.length) {
            currentAlertBody.push(`The biggest decline by market are in: `);
            currentAlertBody.push(parse(generateOrderedList(alertResults.lowest_growing_market, 'growing_market', 'neg')));
        }

        currentAlertBody.push(<div className="detailed_card_subheading">Brand Performance:</div>);

        currentAlertBody.push(
            parse(
                `${formatPercent(
                    alertResults.sales_from_top_10_brands
                )}% of sales are coming from the top 10 brands which ${txtColor(`${alertResults.sales_change_from_top_10_brands_direction} ${formatPercent(
                    alertResults.sales_change_from_top_10_brands)}%`, `${alertResults.sales_change_from_top_10_brands_direction}`)}. For a full landscape of the brands in the category and their performance check out the ${textBold(
                        'Brand Ranking Report.'
                    )}`
            )
        );

        if (alertResults.largest_brand_sales?.length) {
            currentAlertBody.push(`The largest brands in the category are:`);
            currentAlertBody.push(
                parse(generateOrderedList(alertResults.largest_brand_sales, 'largest_brand_sales'))
            );
        }

        if (alertResults.highest_growth_brands?.length) {
            currentAlertBody.push(`The highest growth brands vs. the last year:`);
            currentAlertBody.push(parse(generateOrderedListWithBrandAndGrowth(alertResults.highest_growth_brands)));
        }

        if (alertResults.growing_brands?.length) {
            currentAlertBody.push(`The quickest growing brands vs. the last 4 weeks:`);
            currentAlertBody.push(parse(generateOrderedListWithBrandAndGrowth(alertResults.growing_brands)));
        }

        currentAlertBody.push(
            `This alert was prepared on ${filter_data.filter_category} category based on FMCG Retailers for the ${filter_duration} vs. Last Year.`
        );
        currentAlertBody.push(parse(footerText));
        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'GROWTH_ALERT') {
        config.columnMapping = {
            market: 'Market',
            sales: 'Sales',
            sales_growth: 'Sales Growth',
            distro_growth: 'Distro Growth'
        };

        config1.columnMapping = {
            market: 'Brand',
            sales: 'Sales',
            sales_growth: 'Sales Growth',
            avg_velocity: 'Avg Velocity'
        };

        config.percentKeys = ['brand_level_share', 'brand_level_brand_growth', 'sales_growth', 'distro_growth'];
        config1.percentKeys = ['sales_growth', 'distro_growth'];
        // let filter_duration = `4 weeks ending ${duration}`;
        let curr_date = '4 weeks ending ' + formatDateMMDDYYYY(duration);
        let filter_duration_date = '4 weeks ending ' + formatDateMMDDYYYY(alertResults.filter_duration.split('to')[0].trim()); // flter duration

        currentAlertBody.push(
            parse(
                `For the latest ${curr_date}, ${filter_data.filter_brand} sales in ${filter_data.filter_category} were $${numberWith2Decimal(alertResults.category_sales)},
                ${valueCheck(alertResults.category_sales_change_direction)
                    ? `${txtColor(`${alertResults.category_sales_change_direction} ${formatPercentWithoutSign(alertResults.category_sales_change)}%`, `${alertResults.category_sales_change_direction}`)}`
                    : `${alertResults.category_sales_change_direction}`}                
                vs. the previous 4 weeks.
                ${filter_data.filter_brand} growth is ${alertResults.brand_growth_direction} ${filter_data.filter_category} which is 
                    ${valueCheck(alertResults.preceding_growth_direction)
                    ? `${txtColor(`${alertResults.preceding_growth_direction} ${formatPercentWithoutSign(alertResults.l4w_category_dollars_sales_growth_vs_preceding_4w)}%`, `${alertResults.preceding_growth_direction}`)}`
                    : `${alertResults.preceding_growth_direction}`}
                vs. the previous 4 weeks. For a full trend of ${filter_data.filter_brand} ’s performance vs. ${filter_data.filter_category} , check out the ${returnColorSpan(`${textBold(`Category and Brand Trend Report`)}`, `ByzzerPink`)}.`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Your Growth across Markets:</div>);
        currentAlertBody.push(
            parse(
                `${filter_data.filter_brand} products in ${filter_data.filter_category} were sold in ${alertResults.growth_across_grew_sold_in_x} of ${alertResults.growth_across_grew_sold_in_y} ${pluralCheck(Math.abs(alertResults.growth_across_grew_sold_in_y), 'market')} 
                and grew in ${alertResults.growth_across_grew_in} ${pluralCheck(Math.abs(alertResults.growth_across_grew_in), 'market')} in the latest 4 weeks. 
                ${alertResults.markets_greatest_sales.length > 0
                    ? `${returnNewtext(`<p>The greatest sales growth was in ${alertResults.sales_growth_market}
                        where sales were $${numberWith2Decimal(alertResults.sales_growth_values)} and 
                        ${valueCheck(alertResults.sales_change_direction)
                            ? `${txtColor(`${alertResults.sales_change_direction} ${formatPercentWithoutSign(alertResults.sales_growth_vs_perceding_4w)}%`, `${alertResults.sales_change_direction}`)}`
                            : `${alertResults.sales_change_direction}`}
                        vs. the previous 4 weeks.`, alertResults.markets_greatest_sales)}<br/>Markets with the greatest sales growth for ${filter_data.filter_brand} in ${filter_data.filter_category} vs. the previous 4 weeks and the change in Distribution (distro) for those markets:</p>${generateTable(alertResults.markets_greatest_sales, config)}`
                    : ``}
                    `
            )
        );

        // TABLE 1 -------------
        if (alertResults.markets_sales_change_declined.length > 0) {
            currentAlertBody.push(
                parse(
                    (`<br/><p>Markets where sales growth declined for ${filter_data.filter_brand} in ${filter_data.filter_category} vs. the previous 4 weeks and the change in Distribution (distro) for those markets:</p>${generateTable(alertResults.markets_sales_change_declined, config)}</br>`
                    )
                ));
            // currentAlertBody.push(parse(generateTable(alertResults.markets_sales_change_declined, config)));
        }

        currentAlertBody.push(<div className="detailed_card_subheading">Benchmarking to Competitors:</div>);
        currentAlertBody.push(
            parse(
                `Velocity is an important measure because it helps you to understand how you are doing vs. other brands, even if you don’t have the same distribution.
                Velocity is your sales per point (or percentage of) total distribution - items sold multiplied by the market representation of stores selling. 
                ${filter_data.filter_brand} ’s velocity is ${returnColorSpan(`$${alertResults.velocity_tdp}`, `positive`)}. One point of distribution is worth $${numberWith2Decimal(alertResults.velocity_tdp_2)}.
                Investigate the velocity of different brands with the ${returnColorSpan(`${textBold(`Brand Ranking Report`)}`, `ByzzerPink`)}.`
            )
        );

        if (alertResults.velocity_to_compete_top_brands.length > 0) {
            currentAlertBody.push(
                parse(
                    `Build your velocity to compete with the top brands:`
                )
            );
            currentAlertBody.push(parse(`${generateTable(alertResults.velocity_to_compete_top_brands, config1)} </br>`));
        }

        currentAlertBody.push(<div className="detailed_card_subheading">Markets to Watch:</div>);
        currentAlertBody.push(
            parse(
                `${filter_data.filter_category} category is growing in ${alertResults.count_of_market_growing} of ${alertResults.count_of_market_sold}
                ${pluralCheck(Math.abs(alertResults.count_of_market_sold), 'market')}. Keep an eye on which markets are growing as your next opportunity.
                For a deeper dive into the category performance for a market check out the ${returnColorSpan(`${textBold(`Product Performance Report`)}`, `ByzzerPink`)}.`
            )
        );

        if (alertResults.market_sales_growth_previous_4w.length > 0) {
            currentAlertBody.push(
                parse(
                    `<p>Markets with the greatest sales growth in ${filter_data.filter_category} vs. the previous 4 weeks:</p> ${generateTable(alertResults.market_sales_growth_previous_4w, config1)}</br>`)
            )
        }

        const footerLinkPink = `<a target="_blank" style="color:${ByzzerPink}" href="https://learn.byzzer.ai">Byzzer Resources</a>`;

        currentAlertBody.push(
            parse(
                `This alert was prepared for ${filter_data.filter_brand} on ${filter_data.filter_category} category 
                based on FMCG Retailers for the latest ${duration} 
                compared to the previous ${formatDateMMDDYYYY(subtractWeeks(duration, '4'))}. FMCG Retailers or Fast Moving Consumer Goods
                 is composed of Grocery, Drug, Mass, Dollar, Club, and Military Retailers. 
                 For more information about your alerts and the data powering them, head to the ${returnColorSpan(`${textBold(`${footerLinkPink}`)}`, `ByzzerPink`)}.`
            )
        );
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'BRAND_TREND_ALERT') {

        currentAlertBody.push(
            parse(
                `This week ${duration} in ${formatTitle(alertResults.filter_market)} your portfolio of select brands had sales of $${numberWith2Decimal(alertResults.total_dollars)}, <span style="color:${returnColorByDirection(
                    alertResults.total_dollars_change_direction
                )}">${alertResults.total_dollars_change_direction}</span> 
                ${valueCheck(alertResults.total_dollars_change_direction)
                    ? `<span style="color:${returnColorByDirection(
                        alertResults.total_dollars_change_direction
                    )}"> ${formatPercent(alertResults.total_dollars_change)}%</span>` : ''} vs. last year and <span style="color:${returnColorByDirection(
                        alertResults.total_dollars_change_L1W_vs_L7W_direction
                    )}">${alertResults.total_dollars_change_L1W_vs_L7W_direction}</span>  ${valueCheck(alertResults.total_dollars_change_L1W_vs_L7W_direction)
                        ? `<span style="color:${returnColorByDirection(
                            alertResults.total_dollars_change_L1W_vs_L7W_direction
                        )}"> ${formatPercent(alertResults.total_dollars_change_L1W_vs_L7W)}%</span>`
                        : ''} vs. the previous 7 weeks. ${formatTitle(alertResults.sales_change_brand)} in ${formatTitle(alertResults.sales_change_category)} had the greatest change in Sales, 
                        <span style="color:${returnColorByDirection(
                            alertResults.sales_change_direction
                        )}">${alertResults.sales_change_direction}</span> ${valueCheck(alertResults.sales_change_direction)
                            ? `<span style="color:${returnColorByDirection(
                                alertResults.sales_change_direction
                            )}"> ${formatPercent(alertResults.sales_change)}%</span>`
                            : ''} vs. the previous 7 week trend. For all the measures featured in this Smart Alert and more, head to the full <span style="color:#000000;font-weight:bold;">Brand Trend Alert Report</span>. This report will be available every week in My Alerts along with your Trending Markets Alert email.
                `
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Price Trend</div>);
        currentAlertBody.push(
            parse(
                `The greatest change in price position in ${formatTitle(alertResults.filter_market)} was for ${formatTitle(alertResults.price_trend_brand)} in ${formatTitle(alertResults.price_trend_category)}. ${formatTitle(alertResults.price_trend_brand)}'s price index to the average for ${formatTitle(alertResults.price_trend_category)} for this week is ${alertResults.price_index_avg} vs. a previous 7 week trend of ${alertResults.price_index_avg_vs_7week}, <span style="color:${returnColorByDirection(
                    alertResults.price_index_avg_vs_7week_direction
                    , true)}">${alertResults.price_index_avg_vs_7week_direction}</span> ${valueCheck(alertResults.price_index_avg_vs_7week_direction)
                        ? `<span style="color:${returnColorByDirection(
                            alertResults.price_index_avg_vs_7week_direction,
                            true
                        )}"> ${alertResults.price_index_avg_vs_7week_points} points</span>`
                        : ""}. Since your price is already ${alertResults.price_text_1} the market, ${alertResults.price_text_2}. To dive deeper into which UPCs should be the focus for the price adjustment check out <span style="color:#000000;font-weight:bold;">Pricing Smart Action report</span>.
                `
            )
        );


        currentAlertBody.push(<div className="detailed_card_subheading">Velocity Trend</div>);
        currentAlertBody.push(
            parse(
                `${formatTitle(alertResults.velocity_change_brand)} in ${formatTitle(alertResults.velocity_change_category)} had the greatest change in Velocity from $${numberWith2Decimal(alertResults.velocity_change_from)} to $${numberWith2Decimal(alertResults.velocity_change_to)}, <span style="color:${returnColorByDirection(
                    alertResults.velocity_change_percent_direction
                )}">${alertResults.velocity_change_percent_direction}</span> ${valueCheck(alertResults.velocity_change_percent_direction)
                    ? `<span style="color:${returnColorByDirection(
                        alertResults.velocity_change_percent_direction
                    )}"> ${formatPercent(alertResults.velocity_change_percent)}%</span>`
                    : ''} from the previous 7 weeks. ${alertResults.velocity_text}
                    `
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Distribution Trend</div>);
        currentAlertBody.push(
            parse(
                `${formatTitle(alertResults.distribution_change_brand)} in ${formatTitle(alertResults.distribution_change_category)} had the greatest change in Distribution, <span style="color:${returnColorByDirection(
                    alertResults.distribution_change_percent_direction
                )}">${alertResults.distribution_change_percent_direction}</span> ${valueCheck(alertResults.distribution_change_percent_direction)
                    ? `<span style="color:${returnColorByDirection(
                        alertResults.distribution_change_percent_direction
                    )}"> ${formatPercent(alertResults.distribution_change_percent)}%</span>`
                    : ''} from the previous 7 weeks. ${alertResults.distribution_text}
                `
            )
        );

        const footerLinkPink = `<a target="_blank" style="color:${ByzzerPink}" href="https://learn.byzzer.ai">Byzzer Resources</a>`;
        currentAlertBody.push(parse(
            `This alert was prepared for ${company?.displayName ?? company?.displayName}. ` + // todo: see which company name is needed
            'FMCG Retailers or Fast Moving Consumer Goods is composed of Grocery, Drug, Mass, Dollar, Club, and Military Retailers. For more information about your alerts and the data powering them, head to the ' +
            `${returnColorSpan(`${textBold(`${footerLinkPink}`)}`, `ByzzerPink`)}.`
        ));

    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'PRICE_POSITION_TREND_ALERT') {

        currentAlertBody.push(
            parse(
                `This week ${duration}, ${formatTitle(alertResults.top_market)} had the greatest sales for ${formatTitle(alertResults.filter_brand)} in ${formatTitle(alertResults.filter_category)}, $${numberWith1Decimal(alertResults.greatest_sales)}. In ${formatTitle(alertResults.top_market)} , your average price is $${numberWith2Decimal(alertResults.avg_price)}, <span style="color:${returnColorByDirection(
                    alertResults.avg_price_percent_change_direction, true
                )}">${alertResults.avg_price_percent_change_direction}</span>${valueCheck(alertResults.avg_price_percent_change_direction)
                    ? `<span style="color:${returnColorByDirection(
                        alertResults.avg_price_percent_change_direction, true
                    )}"> ${formatPercent(alertResults.avg_price_percent_change)}%</span>`
                    : ''} vs. the previous 7 weeks. ${formatTitle(alertResults.filter_brand)}’s price percentile is ${formatPercent(alertResults.price_percentile)}% in ${formatTitle(alertResults.top_market)}. That means ${formatPercent(alertResults.price_percentile)}% of brands sales in the category have an average price cheaper or the same as ${formatTitle(alertResults.filter_brand)}. The higher the brand’s percentile, the closer it is to the top priced brand in the category. The price percentile for ${formatTitle(alertResults.filter_brand)} in ${formatTitle(alertResults.top_market)} is <span style="color:${returnColorByDirection(
                        alertResults.price_percentile_point_direction, true
                    )}">${alertResults.price_percentile_point_direction}</span>${valueCheck(alertResults.price_percentile_point_direction)
                        ? `<span style="color:${returnColorByDirection(
                            alertResults.price_percentile_point_direction, true
                        )}"> ${formatPrice(alertResults.price_percentile_point)}% points</span>`
                        : ''} from ${formatPercent(alertResults.price_percentile_change_from)}% for the previous 7 weeks to ${formatPercent(alertResults.price_percentile_change_to)}% this week.

                `
            )
        );

        currentAlertBody.push(
            parse(
                ` For the measures featured in this Smart Alert and all the brands in the category, head to the full <span style="color:${ByzzerPink};font-weight:bold;">Price Position Trend Alert Report</span>. This report will be available every week in My Alerts along with your Assortment Opportunity Alert email.
               `
            )
        );

        currentAlertBody.push(
            parse(
                `Markets with highest Price Percentiles for ${formatTitle(alertResults.filter_brand)} in ${formatTitle(alertResults.filter_category)}:                
                `
            )
        );

        if (alertResults.highest_price_percentiles.length > 0) {
            currentAlertBody.push(
                parse(generateOrderedListforPricePercentile(alertResults.highest_price_percentiles)
                )
            );
        }

        currentAlertBody.push(
            parse(
                `Markets with lowest Price Percentiles for ${formatTitle(alertResults.filter_brand)} in ${formatTitle(alertResults.filter_category)}:                
                `
            )
        );

        if (alertResults.lowest_price_percentiles.length > 0) {
            currentAlertBody.push(
                parse(generateOrderedListforPricePercentile(alertResults.lowest_price_percentiles)
                )
            );
        }

        currentAlertBody.push(
            parse(
                `Greatest changes in Price Percentiles for ${formatTitle(alertResults.filter_brand)} in ${formatTitle(alertResults.filter_category)}:                
                `
            )
        );

        if (alertResults.greatest_price_percentiles.length > 0) {
            currentAlertBody.push(
                parse(generateOrderedListforPricePercentile(alertResults.greatest_price_percentiles)
                )
            );
        }

        const footerLinkPink = `<a target="_blank" style="color:${ByzzerPink}" href="https://learn.byzzer.ai">Byzzer Resources</a>`;

        currentAlertBody.push(parse(
            `This alert was prepared for ${formatTitle(alertResults.filter_brand)} on ${formatTitle(alertResults.filter_category)}.` +
            ' FMCG Retailers or Fast Moving Consumer Goods is composed of Grocery, Drug, Mass, Dollar, Club, and Military Retailers. For more information about your alerts and the data powering them, head to the ' +
            `${returnColorSpan(`${textBold(`${footerLinkPink}`)}`, `ByzzerPink`)}.`
        ));
    }

    // ------------------------ market , assortment alerts below ----------------------------

    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'MARKET_TREND_ALERT') {

        filter_data = {
            filter_category: formatTitle(alertResults.filter_category),
            filter_market: formatTitle(alertResults.filter_market),
            filter_brand: formatTitle(alertResults.filter_brand)
        };

        let duration = formatDateMinusOneMMDDYYYY(alertResults.filter_duration.split('to')[1].trim());

        // currentAlertBody.push(`Duration: ${alertResults.filter_duration}`);
        // currentAlertBody.push(`Market: ${alertResults.filter_market}`);
        // currentAlertBody.push(`Hi ${alert.user.firstName},`)
        currentAlertBody.push(
            parse(
                `This week ${duration}, ${filter_data.filter_brand} sales in ${filter_data.filter_category} were $${numberWith2Decimal(alertResults.category_sales)} 
                across the ${alertResults.market_count} markets, <span style="color:${returnColorByDirection(alertResults.category_sales_change_direction)}">${alertResults.category_sales_change_direction}</span> <span style="color:${returnColorByDirection(alertResults.category_sales_change_direction)}"> ${formatPercentWithoutSign(alertResults.category_sales_percent)}%</span> vs. last year and 
                <span style="color:${returnColorByDirection(alertResults.category_sales_last_7w_direction)}">${alertResults.category_sales_last_7w_direction}</span> <span style="color:${returnColorByDirection(alertResults.category_sales_last_7w_direction)}"> ${formatPercentWithoutSign(alertResults.category_sales_last_7w_percent)}%</span> vs. the previous 7 weeks. 
                ${alertResults.sales_change_market} had the greatest change in Sales 
                <span style="color:${returnColorByDirection(alertResults.sales_change_direction)}">${alertResults.sales_change_direction}</span>         
                <span style="color:${returnColorByDirection(alertResults.sales_change_direction)}"> 
                ${formatPercentWithoutSign(alertResults.sales_change_percent)}%</span> 
                vs. the previous 7 week trend.  For all the measures featured in this Smart Alert and more, head to the full ${returnColorSpan('<b>Market Trend Alert Report</b>', 'ByzzerPink')}. This report will be available every week in My Alerts along with your Trending Markets Alert emails.`
            )
        );

        if (alertResults.hasOwnProperty('price_market_trend')) {
            currentAlertBody.push(<div className="detailed_card_subheading">Price Position Trend</div>)

            currentAlertBody.push(parse(`The greatest change in price position for ${filter_data.filter_brand} in ${filter_data.filter_category} was in ${alertResults.price_market}. ${filter_data.filter_brand} 
            price index to the average for ${filter_data.filter_category} for this week is ${alertResults.price_market_average_l1w} vs. a previous 7 week trend of ${alertResults.price_market_preceding}, 
            <span style="color:${returnColorByDirection(alertResults.price_market_trend_direction, true)}"> ${alertResults.price_market_trend_direction}</span> <span style="color:${returnColorByDirection(alertResults.price_market_trend_direction, true)}">${alertResults.price_market_trend}</span> points. Since your price is already ${alertResults.price_market_trend_range} 
            the market, ${alertResults.price_market_trend_range_change}. To dive deeper into which UPCs should be the focus for the price adjustment check out the ${returnColorSpan('<b>Pricing Smart Action Report</b>', 'ByzzerPink')}.`));
        }

        if (alertResults.hasOwnProperty('velocity_trend_market')) {

            let velocityContent = '';
            if (alertResults.velocity_trend_direction === "new" || alertResults.velocity_trend_direction === "up") {
                velocityContent = `With the increase in velocity, ${filter_data.filter_brand} is more marketable. Build a story on ${filter_data.filter_brand}’s velocity improvement for your next retailer meeting and check ${filter_data.filter_brand}’s standing with the ${returnColorSpan('<b>Brand Ranking Report</b>', 'ByzzerPink')}.`
            } else if (alertResults.velocity_trend_direction === "down" || alertResults.velocity_trend_direction === "delisted" || alertResults.velocity_trend_direction === "flat ") {
                velocityContent = `Drive customer engagement to increase velocity and prevent ${filter_data.filter_brand} being delisted through additional marketing tactics and price.`;
            }

            currentAlertBody.push(<div className="detailed_card_subheading">Velocity Trend</div>)
            currentAlertBody.push(
                parse(`${alertResults.velocity_trend_market} had the greatest change in weekly Velocity from $${numberWith2Decimal(alertResults.velocity_trend_sales_7w)} to $${numberWith2Decimal(alertResults.velocity_trend_sales_1w)}, ${txtColor(alertResults.velocity_trend_direction, alertResults.velocity_trend_direction)} ${txtColor(`${formatPercent(alertResults.velocity_trend_percent)}%`, alertResults.velocity_trend_direction)} from the previous 7 weeks. 
                ${velocityContent}`)
            )
        }

        if (alertResults.hasOwnProperty('distribution_trend_market')) {

            let distributionContent = `This means ${filter_data.filter_brand} is selling ${alertResults.selling_instances} in ${filter_data.filter_category}. `;
            if (alertResults.distribution_trend_direction === "up" || alertResults.distribution_trend_direction === "new") {
                distributionContent += `Build a story showing ${filter_data.filter_brand}'s increase in distribution to expand distribution even further.`;
            } else if (alertResults.distribution_trend_direction === "down" || alertResults.distribution_trend_direction === "delisted") {
                distributionContent += `Evaluate which accounts are causing the decrease in distribution with the Distribution Landscape Report and work to regain your distribution.`;
            } else if (alertResults.distribution_trend_direction === "flat") {
                distributionContent += `Evaluate which accounts are causing the stalled growth in distribution with the Distribution Landscape Report and work to grow your distribution.`;
            }

            currentAlertBody.push(<div className="detailed_card_subheading">Distribution Trend</div>)
            currentAlertBody.push(parse(`${alertResults.distribution_trend_market} had the greatest change in Distribution, <span style="color:${returnColorByDirection(alertResults.distribution_trend_direction)}">${alertResults.distribution_trend_direction}</span> <span style="color:${returnColorByDirection(alertResults.distribution_trend_direction)}"> ${formatPercentWithoutSign(alertResults.distribution_trend_percent)}%</span> from the previous 7 weeks. ${distributionContent}`))
        }

        currentAlertBody.push(parse(`This alert was prepared for ${filter_data.filter_brand} on ${filter_data.filter_category}. FMCG Retailers or Fast Moving Consumer Goods is composed of Grocery, Drug, Mass, Dollar, Club, and Military Retailers. For more information about your alerts and the data powering them, head to the ${returnColorSpan('<b>Byzzer Resources</b>', 'ByzzerPink')}.`))
        // setCurrentAlertBody(currentAlertBody);
    }
    if (alertMap[alert.productSku] && alertMap[alert.productSku] === 'ASSORTMENT_ACTION') {

        filter_data = {
            filter_category: formatTitle(alertResults.filter_category),
            filter_market: formatTitle(alertResults.filter_market),
            filter_brand: formatTitle(alertResults.filter_brand)
        };

        let defBulletParams = {
            delisted: 'delisted',
            defValue: 'new',
            ly: '',
            ty: '',
        };

        let highest_sales_direction = defaultValue(alertResults.market_highest_sales_direction, defBulletParams);
        let lowest_sales_direction = defaultValue(alertResults.market_lowest_sales_direction, defBulletParams);
        let growth_highest_direction = defaultValue(alertResults.sales_growth_highest_direction, defBulletParams);
        let growth_lowest_direction = defaultValue(alertResults.sales_growth_lowest_direction, defBulletParams);
        // currentAlertBody.push(`Duration: ${alertResults.filter_duration}`);
        // currentAlertBody.push(`Market: ${alertResults.filter_market}`);
        // currentAlertBody.push(`Hi ${alert.user.firstName},`)
        currentAlertBody.push(
            parse(
                `For the last 13 weeks ending ${duration}, you have ${coloredSpan(redColor, `$${numberWith2Decimal(alertResults.at_risk_dollar_impact)} at risk`)} due to a low ranked performance and opportunities worth ${coloredSpan(greenColor, `$${numberWith2Decimal(alertResults.expand_dollar_impact)} to expand`)} across your selected markets. 
                For all the measures featured in this Smart Alert and more, head to the full ${returnColorSpan('<b>Assortment Opportunity </b>', 'ByzzerPink')} <a href="" >${returnColorSpan('<b>Alert Report</a></b>', 'ByzzerPink')}. This report will be available every week in My Alerts along with your Assortment Opportunity Alert email.`
            )
        );

        currentAlertBody.push(<div className="detailed_card_subheading">Market Performance Review</div>)
        currentAlertBody.push(parse(`Stay on top of your performance across markets by tracking 4 key measures. For item level recommendations by market check out the ${returnColorSpan('<b>Assortment Smart Action Report</b>', 'ByzzerPink')}.`))



        currentAlertBody.push(parse(`1.<b>$ Sales:</b> the market with the greatest sales is 
        ${formatTitle(alertResults.highest_sale_market)} $${numberWith2Decimal(alertResults.market_highest_sales)}, 
        ${coloredSpan(returnColorByDirection(highest_sales_direction), highest_sales_direction)} ${valueCheck(highest_sales_direction)
                ? coloredSpan(returnColorByDirection(alertResults.market_highest_sales_direction), `${formatPercentWithoutSign(alertResults.market_highest_sales_percent)}%`) : ''} 
        
        
         
            and the market with the lowest sales is 
        ${formatTitle(alertResults.lowest_sale_market)} 
        $${numberWith2Decimal(alertResults.market_lowest_sales)}, ${coloredSpan(returnColorByDirection(lowest_sales_direction), lowest_sales_direction)} 
        ${valueCheck(lowest_sales_direction) ? coloredSpan(returnColorByDirection(alertResults.market_lowest_sales_direction),
                    ` ${formatPercentWithoutSign(alertResults.market_lowest_sales_percent)}%`) : ''}`))



        // if (market > 1) =>

        let sales_high_market =
            `${formatTitle(alertResults.sales_growth_highest_market)} $${numberWith2Decimal(alertResults.sales_growth_highest_sale)}, 
        ${coloredSpan(returnColorByDirection(growth_highest_direction), growth_highest_direction)} ${valueCheck(growth_highest_direction)
                ? coloredSpan(returnColorByDirection(alertResults.sales_growth_highest_direction), `${formatPercentWithoutSign(alertResults.sales_growth_highest_percent)}%`) : ''} 
        `;

        let sales_low_market = alertResults.sale_market_count > 1
            ? ` and the market with the slowest growth is ${formatTitle(alertResults.sales_growth_lowest_market)} 
        $${numberWith2Decimal(alertResults.sales_growth_lowest_sale)}, ${coloredSpan(returnColorByDirection(growth_lowest_direction), growth_lowest_direction)} 
        ${valueCheck(growth_lowest_direction) ? coloredSpan(returnColorByDirection(alertResults.sales_growth_lowest_direction),
                ` ${formatPercentWithoutSign(alertResults.sales_growth_lowest_percent)}%`) : ''}`
            : '';

        currentAlertBody.push(parse(`2.<b>Sales Growth:</b> the market with the highest growth is ${sales_high_market} ${sales_low_market}`))



        let highest_selling_items = alertResults.total_increase_l13w_year_ago > 0
            ? `${formatTitle(alertResults.change_in_increase_selling_market)} has ${coloredSpan(
                greenColor,
                Math.abs(alertResults.total_item_increase_selling_change) + ` more`
            )} ${pluralCheck(Math.abs(alertResults.total_item_increase_selling_change), 'item')} selling for a total of ${alertResults.change_in_increase_selling} ${pluralCheck(Math.abs(alertResults.change_in_increase_selling), 'item')}`
            : ''

        let lowest_selling_items = alertResults.total_decrease_l13w_year_ago < 0
            ? ` and ${formatTitle(alertResults.change_in_decrease_selling_market)} has ${coloredSpan(
                redColor,
                Math.abs(alertResults.total_item_decrease_selling_change) + ` fewer`
            )} ${pluralCheck(Math.abs(alertResults.total_item_decrease_selling_change), 'item')} selling for a total of ${alertResults.change_in_decrease_selling} ${pluralCheck(Math.abs(alertResults.change_in_decrease_selling), 'item')}`
            : ''
        let change_in_items_selling = `${highest_selling_items}${lowest_selling_items}`
        currentAlertBody.push(parse(`3.<b>Change in Items Selling:</b> ${change_in_items_selling}`))





        let velocity_highest_market =
            `${formatTitle(alertResults.velocity_highest_market)} has the 
        ${coloredSpan(greenColor, 'highest velocity')} $${numberWith2Decimal(alertResults.velocity_highest_sale)}`;

        let velocity_lowest_market = alertResults.velocity_sale_count > 1
            ? ` and
        ${formatTitle(alertResults.velocity_lowest_market)} has the ${coloredSpan(redColor, 'lowest velocity')}
         $${numberWith2Decimal(alertResults.velocity_lowest_sale)}`
            : '';

        currentAlertBody.push(parse(`4.<b>Velocity:</b> ${velocity_highest_market} ${velocity_lowest_market}`));




        if (alertResults.top_expansion_items.length) {

            let expandTitle =
                alertResults.top_expansion_items.length > 0
                    ? `Talk to ${alertResults.expand_market} about an opportunity`
                    : `There are no opportunities`;
            let expandFollowing =
                alertResults.top_expansion_items.length > 0
                    ? `The ${alertResults.top_expansion_items.length} 
                    ${pluralCheck(Math.abs(alertResults.top_expansion_items.length),
                        'market'
                    )} with the most items which are expansion opportunities are`
                    : '';


            currentAlertBody.push(<div className="detailed_card_subheading">Expand</div>)
            currentAlertBody.push(parse(
                `<p> ${expandTitle} to expand distribution of ${filter_data.filter_brand} in ${toTitleCaseWithComma(`${filter_data.filter_category}`, true)}. ` +
                `${expandFollowing} </p>` + '<ol style="margin-left:2em">' +
                alertResults.top_expansion_items.reduce((acc, memo) => {
                    if (!acc) acc = '';
                    acc += `<li>${formatTitle(memo.market)} has ${coloredSpan(
                        greenColor,
                        memo.expand_utc_count + ` ${pluralCheck(Math.abs(Math.round(memo.expand_utc_count)), 'item')} to expand`
                    )}, a value of ${coloredSpan(
                        greenColor,
                        '$' + numberWith2Decimal(memo.expand_dollar_impact_action)
                    )}</li>`;
                    return acc;
                }, '') +
                `</ol>`))
        }
        if (alertResults.top_risk_items.length) {

            let riskTitle =
                alertResults.top_risk_items.length > 0 ? 'Improve the performance of' : 'There are no items at risk for';
            let riskFollowing =
                alertResults.top_risk_items.length > 0
                    ? ` which is at risk of being delisted at ${alertResults.risk_market} due to a low assortment performance rank.` +
                    ` The ${alertResults.top_risk_items.length} 
                    ${pluralCheck(Math.abs(alertResults.top_risk_items.length),
                        'market'
                    )} with the most items at risk are`
                    : '.';

            currentAlertBody.push(<div className="detailed_card_subheading">At Risk</div>)
            currentAlertBody.push(parse(
                `<p>${riskTitle} ${filter_data.filter_brand} in ${toTitleCaseWithComma(`${filter_data.filter_category}`, true)} 
                ${riskFollowing} </p>` + '<ol style="margin-left:2em">' +
                alertResults.top_risk_items.reduce((acc, memo) => {
                    if (!acc) acc = '';
                    acc += `<li>${formatTitle(memo.market)} has ${coloredSpan(
                        redColor,
                        memo.risk_utc_count + ` ${pluralCheck(Math.abs(Math.round(memo.risk_utc_count)), 'item')} at risk`
                    )}, a value of ${coloredSpan(redColor, '$' + numberWith2Decimal(memo.risk_dollar_impact_action))}</li>`;
                    return acc;
                }, '') +
                `</ol>`))
        }

        currentAlertBody.push(parse(`This alert was prepared for ${filter_data.filter_brand} on
         ${toTitleCaseWithComma(`${filter_data.filter_category}`, true)}, based on FMCG Retailers. 
         FMCG Retailers or Fast Moving Consumer Goods is composed of Grocery, Drug, Mass, Dollar, Club, and 
         Military Retailers. For more information about your alerts and the data powering them, head to 
         the ${returnColorSpan('<b>Byzzer Resources</b>', 'ByzzerPink')}.`))
        // setCurrentAlertBody(currentAlertBody);
    }

    const navigate = useNavigate();    
    const redirectToReport = async () => {
        navigate({
            pathname: '/my-reports/report-view',
            state: {
                userData: {reportSku: alert.productSku, reportParams: alert.actionConfig, actionId: alert.id},
                sourceUrl: '/dashboard/my_alerts'
            }
        });
    };

    const showCreatedBy = Boolean(createdBy?.fullName && createdBy?.email);

    return (
        <>
            <div className={'my-alerts ' + (viewReportAlertsSKU.includes(alert.productSku) ? 'viewReportButton' : 'detailed-my-alerts')}>
                <div className="my-alerts__heading"> {alert.title} </div>

                {viewReportAlertsSKU.includes(alert.productSku) &&
                    <div className="inline-product-selector__submit-btn">
                        <ByzzerButton
                            label="View Report"
                            onClick={() => redirectToReport()}
                        />
                    </div>
                }

                <div className="report-run-selector___container back_button_my_detailed_alert">
                    <div className="report-run-selector___back">
                        <ByzzerButton onClick={() => props.goBack()} type={'negative'}>Back</ByzzerButton>
                    </div>
                </div>

                <div className="my-alerts__sub-heading"> For the latest week, ending {duration}</div>
                {showCreatedBy && <div>Created by {createdBy?.fullName} ({createdBy?.email})</div>}
            </div>

            <div
                className="detailed_card detailed_card_scroll"
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 5,
                }}
            >
                {currentAlertBody &&
                    currentAlertBody.map((section, index) => {
                        return (
                            <div key={index} className="card_paragraph">
                                {/* <ol className="list_container">
                                    <li> */}
                                {section}

                                {/* </li>
                                </ol> */}
                            </div>
                        );
                    })}


                <div className="report-run-selector___container back_button_my_detailed_alert bottom">
                    <div className="report-run-selector___back">
                        <ByzzerButton onClick={() => props.goBack()} type={'negative'}>Back</ByzzerButton>
                    </div>
                </div>
            </div>
        </>
    );

}

export default MyAlertView1_4;
