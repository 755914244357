import React, {useEffect, memo} from 'react';
import {parse} from 'qs';
import axios from 'axios';
import {ScriptTag} from './ScriptTag';

const SIGN_UP_ATTRIB_KEY = 'signUpAttributionData';
const URCHIN_PARAMS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
];
const FORM_FIELDS = {
    firstName: 'firstname',
    lastName: 'lastname',
    companyName: 'company',
    utm_source: 'utm_source',
    utm_content: 'utm_content',
    utm_medium: 'utm_medium',
    email: 'email',
    phone: 'phone',
    companyType: 'company_type',
    userId: 'netsuite_contact_id',
    companyId: 'netsuite_customer_id',
    lead_source_event: 'lead_source_event'

};

const signUpTrackingEnabled = import.meta.env.REACT_APP_ENABLE_SIGN_UP_TRACKING === 'true';
const signUpFormId = import.meta.env.REACT_APP_HUBSPOT_SIGN_UP_FORM_ID;
const hubspotId = import.meta.env.REACT_APP_HUBSPOT_ID;
const hubspotBaseUrl = import.meta.env.REACT_APP_HUBSPOT_BASE_URL || `https://api.hsforms.com/submissions/v3/integration/`;
const http = axios.create({
    baseURL: hubspotBaseUrl
})

export const SiteAnalytics = memo(() => {

    useEffect(() => {

        // capture any attribution query parameters the first time the site is loaded
        const queryParams = parse(window.location.search.substr(1));
        const hasUrchinParams = Object.keys(queryParams).some(param => URCHIN_PARAMS.includes(param));
        if (hasUrchinParams) {
            localStorage.setItem(SIGN_UP_ATTRIB_KEY, JSON.stringify(queryParams));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(hubspotId) {
        const src = `//js.hs-scripts.com/${hubspotId}.js`;
        return <ScriptTag async={true} defer={true} type={"text/javascript"} src={src}/>
    } else {
        return <></>;
    }
});

export function getSignUpAttribution() {
    if(signUpTrackingEnabled) {
        const attribData = JSON.parse(localStorage.getItem(SIGN_UP_ATTRIB_KEY) || '{}');
        // strip the query string from the page uri
        //localStorage.removeItem(SIGN_UP_ATTRIB_KEY);
        return attribData;

    }
}

export async function recordSignUpAttribution(data = {}, pageName = 'User Sign Up') {

    if(signUpTrackingEnabled && hubspotId) {

        const attribData = JSON.parse(localStorage.getItem(SIGN_UP_ATTRIB_KEY) || '{}');
        // strip the query string from the page uri
        const pageUri = window.location.href.replace(/\?.*?#/, '#').replace(/\?.*$/, '');
        // I feel like Hubspot should have support for accessing this cookie but they don't so we have to parse it
        // yes we could use a library for this but why?
        const [, hutk] = document.cookie.split('; ').find(cookie => cookie.trim().startsWith('hubspotutk='))?.split('=') || []
            .split('=')[1];

        try {
            await http.post(`submit/${hubspotId}/${signUpFormId}`, {
                submittedAt: Date.now(),
                fields: toFields({
                    ...attribData,
                    ...data
                }),
                context: {
                    hutk,
                    pageUri,
                    pageName
                }
            });
        } catch (err) {

        } finally {
            localStorage.removeItem(SIGN_UP_ATTRIB_KEY);
        }
    }
}

function toFields(data) {
    const formFields = [];
    const extras = [];
    Object.entries(data).forEach(([name, value]) => {

        if (value === null) return;
        const formField = FORM_FIELDS[name];
        if (formField) {
            formFields.push({name: formField, value})
        } else {
            extras.push(`${name}: ${JSON.stringify(value)}`);
        }
    });

    formFields.push({
        name: 'notes',
        value: extras.join('\n')
    });

    return formFields;
}

export function setIdentity(email, data = {}) {

    const _hsq = window._hsq || [];
    _hsq.push(['identify', {
        email,
        ...data,
    }])
}

export function trackEvent(id, value) {
    const _hsq = window._hsq || [];
    _hsq.push(['trackEvent', {
        id,
        value
    }]);
}

export default SiteAnalytics;
export const useAnalytics = () => ({
    getSignUpAttribution,
    recordSignUpAttribution,
    setIdentity,
    trackEvent
});
