import { ByzzerButton } from '@byzzer/ui-components';
import { BENEFITS, SCHEDULE_DEMO_LINK } from '@/constants/signup.constants';

export type BenefitsProps = {
    baseClassName?: string;
};

export const Benefits = ({ baseClassName, ...props }: BenefitsProps) => {
    function onScheduleDemoClick() {
        window.open(SCHEDULE_DEMO_LINK, '_blank', 'rel=noopener noreferrer');
        return;
    }

    return (
        <div className={`${baseClassName}-marketing`}>
            <div className={`${baseClassName}-marketing__section`}>
                <div className={`${baseClassName}-marketing__caption`}>
                    Join a Community of 5000+ Happy Users Harnessing the Power of Data
                </div>
                <div className={`${baseClassName}-marketing__benefits`}>
                    {BENEFITS?.map((benefit, index) => (
                        <div className={`${baseClassName}-marketing__benefits-details`} key={index + benefit.title}>
                            <div className={`${baseClassName}-marketing__benefits-details-icon`}>
                                <img src={benefit.icon} alt={'benefit-icon'} />
                            </div>
                            <div className={`${baseClassName}-marketing__benefits-details-text`}>
                                <span className={`${baseClassName}-marketing__benefits-details-text-title`}>
                                    {benefit.title}
                                </span>
                                <span className={`${baseClassName}-marketing__benefits-details-text-description`}>
                                    {benefit.description}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`${baseClassName}-marketing__actions`}>
                    <ByzzerButton label={'Schedule a Demo'} onClick={() => onScheduleDemoClick()} />
                </div>
            </div>
        </div>
    );
};

export default Benefits;

Benefits.displayName = 'Benefits';
