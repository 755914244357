import './ExpandableFilter.scss';
import React, {ChangeEvent, MouseEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {ByzzerCheckbox} from "@/components/form";
import {DateRange, Range, RangeKeyDict} from "react-date-range";
import {uniqBy} from 'lodash';

export type ExpandableFilterOption = {
    display: string;
    value: string;
};

export type FilterChangeEvent<T> = {
    value: T;
    name?: string;
}

export type ExpandableFilterProps = {
    name?: string;
    title: string;
    options: (FilterOption | string)[];
    className?: string;
    expanded?: boolean;
    onChange?: (e: FilterChangeEvent<string[]>) => void;
    value: string[];
}

export const ExpandableFilter = React.memo(({title, className, onChange, value, options, name}: ExpandableFilterProps) => {

    const baseClassName = 'expandable-filter'
    const [expanded, setExpanded] = useState(false);
    const [normalizedOptions, setNormalizedOptions] = useState<(string | ExpandableFilterOption)[]>([]);

    useEffect(() => {
        const uniqValues = uniqBy<any>(options.filter(v => Boolean(v)), (option) => option.value ?? option);
        const sortedValues = uniqValues.sort((a, b) => {
            const aValue = (a as ExpandableFilterOption).display ?? a;
            const bValue = (b as ExpandableFilterOption).display ?? b;
            return (aValue).localeCompare((bValue), 'en', {sensitivity: 'base'});
        });
        setNormalizedOptions([{display: 'All', value: '$$all$$'}, ...sortedValues]);
    }, [options])

    function toggleExpansion() {
        setExpanded(expanded => !expanded);
    }

    function handleChange(e: ChangeEvent<HTMLInputElement>, newValue: any) {

        let newValues: string[];

        const allOptions: string[] = normalizedOptions.map(option => (option as ExpandableFilterOption).value ?? option);
        if (e.target.checked && newValue === '$$all$$') {
            newValues = allOptions;
        } else if (e.target.checked) {
            newValues = [newValue, ...value];
        } else {
            newValues = newValue === '$$all$$' ? [] : value.filter(v => ![newValue, '$$all$$'].includes(v));
        }
        if (allOptions.filter(item => item !== '$$all$$').every((v) => newValues?.includes(v))) {
            newValues = allOptions; // To make 'all' selected automatically when other options are fully selected
        }
        onChange?.({
            name,
            value: newValues,
        });
    }

    function handleClearClick(e: MouseEvent) {

        e.preventDefault();
        e.stopPropagation();

        onChange?.({
            name,
            value: []
        })
    }

    return (
        <div className={classnames(baseClassName, className)}>
            <div className={classnames(`${baseClassName}__header`, {
                [`${baseClassName}__header--expanded`]: expanded
            })} onClick={toggleExpansion}>
                <h1 className={`${baseClassName}__title`}>{title}</h1>
                {Boolean(value.length) && (
                    <div className={`${baseClassName}__summary`}>
                        <span>{value?.filter(val => val !== '$$all$$').length} Selected</span>
                        <span className={`${baseClassName}__clear`} onClick={handleClearClick}>Clear</span>
                    </div>
                )}
            </div>
            <div className={classnames(`${baseClassName}__options`, {
                [`${baseClassName}__options--expanded`]: expanded
            })}>
                {normalizedOptions.map(option => (
                    // @ts-ignore
                    <ByzzerCheckbox
                        key={(option as ExpandableFilterOption).value ?? option}
                        onChange={handleChange}
                        value={(option as ExpandableFilterOption).value ?? option}
                        checked={value?.includes((option as ExpandableFilterOption).value ?? option)}
                    >
                        {(option as ExpandableFilterOption).display ?? option}
                    </ByzzerCheckbox>
                ))}
            </div>
        </div>
    );
});

export type ExpandableDateFilterProps = {
    name?: string;
    title: string;
    className?: string;
    expanded?: boolean;
    onChange?: (e: FilterChangeEvent<Range | null>) => void;
    value: Range | null;
}

export const ExpandableDateFilter = React.memo(({title, className, onChange, value, name}: ExpandableDateFilterProps) => {

    const baseClassName = 'expandable-filter'
    const [expanded, setExpanded] = useState<boolean>(false);
    const [ranges, setRanges] = useState<Range[]>([{startDate: undefined, endDate: undefined, key: 'value'}])

    useEffect(() => {
        setRanges([{
            ...value,
            key: 'value'
        }])
    }, [value])

    function toggleExpansion() {
        setExpanded(expanded => !expanded);
    }

    function handleChange({value}: RangeKeyDict) {

        onChange?.({
            name,
            value
        })
    }

    function handleClearClick(e: MouseEvent) {

        e.preventDefault();
        e.stopPropagation();

        onChange?.({
            name,
            value: null
        });
    }

    return (
        <div className={classnames(baseClassName, className)}>
            <div className={classnames(`${baseClassName}__header`, {
                [`${baseClassName}__header--expanded`]: expanded
            })} onClick={toggleExpansion}>
                <h1 className={`${baseClassName}__title`}>{title}</h1>
                {Boolean(value?.startDate) && (
                    <div className={`${baseClassName}__summary`}>
                        <button className={`${baseClassName}__clear`} onClick={handleClearClick}>Clear</button>
                    </div>
                )}
            </div>
            {/* @ts-ignore */}
            <DateRange
                className={classnames(`${baseClassName}__calendar`, {
                    [`${baseClassName}__calendar--expanded`]: expanded,
                })}
                onChange={handleChange}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={ranges}
                rangeColors={['black']}
                direction="vertical"
                showDateDisplay={false}
                showPreview={false}
                dragSelectionEnabled={true}

            />
        </div>
    )
});
