import React, {useEffect, useState} from 'react';
import {MrktInfoIconCopy} from '@/utils/MarketEntitlementMessages';
import {useUser} from './UserContext';
import {AppInitializing} from "@/components/AppInitializing";

const TIP_LIMITED_PROMOTION = (
    <div className={'market-picker-node__tip-content'}>
        <p>This market contains limited promotion data.</p>
        <p>For promotion reporting, consider using Total FMCG Retailers.</p>
    </div>
);
export type MarketContextValue = {
    initialized?: boolean;
    allMarkets: MarketNode[];
    selectableMarketsWithRemainingMarkets: MarketNode[];
    selectableMarketsWithoutRemainingMarkets: MarketNode[];
};

export const MarketContext = React.createContext<MarketContextValue>({
    initialized: false,
    allMarkets: [],
    selectableMarketsWithRemainingMarkets: [],
    selectableMarketsWithoutRemainingMarkets: [],
} as any);

export function MarketProvider({children}) {
    const {allMarkets: markets} = useUser();

    const [value, setValue] = useState<MarketContextValue>({
        allMarkets: [],
        selectableMarketsWithRemainingMarkets: [],
        selectableMarketsWithoutRemainingMarkets: [],
    });

    useEffect(() => {
        if (markets?.length) {
            // load markets from app context
            let selectableMarketsWithRemainingMarkets: MarketNode[] = markets.filter(
                (market) => market.selectable === true
            );
            let selectableMarketsWithoutRemainingMarkets: MarketNode[] = markets.filter(
                (market) => market.selectable === true && market.isRemaining === false
            );
            // enhance with icons and stuff
            setValue(value => ({
                ...value,
                initialized: true,
                allMarkets: markets.map(enhanceAndCacheMarketNode),
                selectableMarketsWithRemainingMarkets: selectableMarketsWithRemainingMarkets.map(
                    enhanceAndCacheMarketNode
                ),
                selectableMarketsWithoutRemainingMarkets: selectableMarketsWithoutRemainingMarkets.map(
                    enhanceAndCacheMarketNode
                ),
            }));
        }
    }, [markets]);

    function enhanceAndCacheMarketNode(market: MarketNode): MarketNode {
        const enhancedMarket = {
            ...market,
            ...getExtraInfoForMarketNode(market),
            ...getAdditionalInfoIconsByMarketName(market.name),
        };
        return enhancedMarket;
    }

    function getExtraInfoForMarketNode(market: MarketNode): Partial<MarketNode> | void {
        switch (market.name) {
            case 'Total FMCG Retailers':
                return {
                    isPseudoRemaining: true,
                };
            case 'Total FMCG Retailers + Pet Stores':
            case 'Total Pet Stores':
            case 'Total Neighborhood Pet Retail':
                return {
                    showLimitedDataTip: true,
                    limitedDataType: 'pet',
                    limitedDataMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            Limited product scope: This market only contains data for pet-specific categories.
                            {/** BYZ_8546;support multiple warnings */}
                            {TIP_LIMITED_PROMOTION}
                        </div>
                    ),
                };
            case 'Total FMCG Retailers + Convenience':
            case 'Total Convenience Stores':
                return {
                    showLimitedDataTip: true,
                    limitedDataType: 'weight',
                    limitedDataMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            Limited product scope: This market does not contain data for random weight categories.
                        </div>
                    ),
                };
            case 'Total FMCG Retailers + Liquor + Convenience':
            case 'Total Liquor Plus':
                return {
                    showLimitedDataTip: true,
                    limitedDataType: 'liquor',
                    limitedDataMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            Limited product scope: This market only contains data for alcohol categories.
                        </div>
                    ),
                };
            case 'Amazon':
                return {
                    // todo: get rid of this class.  we need a common class used to display all of our tippy content
                    info: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>
                                Amazon markets report POS data from Amazon physical stores including: Amazon Fresh,
                                Amazon Go, Amazon Go Grocery formats, and any sales through Amazon Fresh.com and Amazon
                                Prime Now not fulfilled by Amazon.com or Whole Foods.
                            </p>
                            <p>They do not include Amazon.com sales or any sales fulfilled through Whole Foods.com.</p>
                        </div>
                    ),
                };
            case '7-Eleven Defined Geography':
                return {
                    info: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>
                                7-Eleven Defined Geography contains both <span>7-Eleven</span> and competitive
                                Convenience Retailers
                            </p>
                        </div>
                    ),
                };
            case 'SuperValu':
                return {
                    info: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>
                                The UNFI Total market includes Cub and Shopper Food Warehouse, as well as independent
                                stores that UNFI supplies to and does not actually own.
                            </p>
                            <p>For details on the UNFI Supplied Independents, reach out to customer support.</p>
                        </div>
                    ),
                };
        }
    }

    /** BYZ_7377: display info-icons to all FMCG markets and unique channels */
    function getAdditionalInfoIconsByMarketName(name: string): Partial<MarketNode> | void {
        switch (name) {
            case 'Total FMCG Retailers':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS}</p>
                        </div>
                    ),
                };
            case 'Total FMCG Retailers + Convenience':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_CONVENIENCE}</p>
                        </div>
                    ),
                };
            case 'Total FMCG Retailers + Liquor + Convenience':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_LIQUOR_CONVENIENCE}</p>
                        </div>
                    ),
                };
            case 'Total FMCG Retailers + Pet Stores':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_PET_STORES}</p>
                        </div>
                    ),
                };
            case 'Total Pet Stores':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.TOTAL_PET_STORES}</p>
                        </div>
                    ),
                };
            case 'Total Neighborhood Pet Retail':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.TOTAL_NEIGHBORHOOD_PET_RETAIL}</p>
                        </div>
                    ),
                };
            case 'Giant Food Total':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.GIANT_FOOD_TOTAL}</p>
                        </div>
                    ),
                };
            case 'The Giant Company':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.THE_GIANT_COMPANY}</p>
                        </div>
                    ),
                };
            case 'Alliance Retail Group Total':
                return {
                    hasInfoIconForFmcgMarkets: true,
                    infoMessage: (
                        <div className={'market-picker-node__tip-content'}>
                            <p>{MrktInfoIconCopy.ALLIANCE_RETAIL_GROUP_TOTAL}</p>
                        </div>
                    ),
                };
        }
    }


    return <MarketContext.Provider value={value}>
        {children}
    </MarketContext.Provider>;
}

export const useMarketContext = () => React.useContext(MarketContext);
