import './DodMarketFilterValuePicker.scss';
import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import classnames from 'classnames';
import { DodMarketFilterType } from '@/components/DodConfigEditor/types';
import { DodFilters } from '@/types/DodRun';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { DodFilterValuePicker, DodFilterValuePickerRef, DodValueOption } from '@/components/DodConfigEditor/common';
import { isMarketDisabled, isMarketHidden, useMarketService } from '@/services/market.service';
import { filterValuesToStrings } from '@/components/DodConfigEditor/common/utils';
import { MarketPickerContext, MarketPickerNode } from '@/components/MarketPicker';
import { RunConfigMarket } from '@/types/ReportRun';
import { isAsyncFunction } from '@/utils';
import { DodMarketTree } from '../DodMarketTreePicker/DodMarketTree';
import { useUser } from '@/contexts/UserContext';
import { AdvancedSearchFilters, MarketTab } from '../DodMarketFilterBuilder';
import { unionBy } from 'lodash';
import {alertSelectionLimitExceed} from '@/components/DodConfigEditor/common/utils'
import { useCategoryService } from '@/services/category.service';

const baseClassName = 'dod-market-filter-value-picker';

export type DodMarketFilterValuePickerProps = {
    name?: string;
    className?: string;
    filterType: DodMarketFilterType;
    filters: DodFilters;
    value: RunConfigMarket[];
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    onApply?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    actions?: ActionConfig[];
    pickerRef?: React.Ref<DodFilterValuePickerRef>;
    includeActions?: boolean;
    filterText?: string;
    activeMarketFolderTab?: MarketTab;
    advancedSearchFilters?: AdvancedSearchFilters;
    limit?: number;    
    inSummedMode?:boolean;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type FilterValueConfig = {
    getOptions(values: DodFilters, data: any): Promise<DodValueOption[]> | DodValueOption[] | void;
    valueToStrings?(value: any): string[];
    stringsToValue?(strings: string[]): any;
    filterValues?(searchTerm: string): DodValueOption[];
};

function defaultValueToStrings(value: RunConfigMarket[]): string[] {
    let marketNames = value?.map((market: RunConfigMarket) => {
        if (market) {
            return market.name;
        }
        return '';
    });
    return marketNames;
}

export function DodMarketFilterValuePicker({
    name,
    className,
    filterType,
    filters,
    value,
    onChange,
    onApply,
    pickerRef,
    filterText = '',
    advancedSearchFilters,
    limit = Infinity,
    activeMarketFolderTab,  
    inSummedMode,  
    ...props
}: DodMarketFilterValuePickerProps) {
    const {
        getMarketsOptionsByMarketFilterType,
        getRunConfigMarketForMarketName,
        filterMarketOptions,
    } = useMarketService();
    const [markets, setMarkets] = useState<RunConfigMarket[]>([]);
    const [options, setOptions] = useState<DodValueOption[]>([]);
    const {
        categories: userCategories,
        company,
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
    } = useUser();
    const { 
        requiredMasterCompany, 
        requiredMarketGroup, 
        requireRemainingMarket, 
        includePseudoRemainingMarkets, 
        showRemainingMarkets, 
        hideDisabledMarkets, 
        hideNonPromoMarkets 
    } = useContext(MarketPickerContext);    

    const { getAllCategoriesByDepartmentsAndSuperCategories } = useCategoryService();

    const checkMarketDisabled = useCallback((market: MarketNode): boolean => {
        return isMarketDisabled(market, {
            requiredMarketGroup,
            requiredMasterCompany,
            requireRemainingMarket,
            enableLimitedMarketSubscription,
            accessibleMasterCompanies,            
            purchasedMarketKeys:company?.purchasedMarketKeys,  
        });
    }, [requiredMarketGroup, requiredMasterCompany, requireRemainingMarket])

    const checkMarketHidden = useCallback((market: MarketNode): boolean => {
        return isMarketHidden(market, {
            includePseudoRemainingMarkets,
            requireRemainingMarket,
            showRemainingMarkets,
            hideDisabledMarkets,
            hideNonPromoMarkets,
            requiredMarketGroup,
            requiredMasterCompany,
            enableLimitedMarketSubscription,
            accessibleMasterCompanies,
            purchasedMarketKeys:company?.purchasedMarketKeys, 
        });
    }, [includePseudoRemainingMarkets, requiredMarketGroup, requiredMasterCompany, requireRemainingMarket, showRemainingMarkets, hideDisabledMarkets, hideNonPromoMarkets])

    const filterValueConfigs = useMemo<Record<DodMarketFilterType, FilterValueConfig>>(
        () => ({
            all: {
                getOptions(filters) {
                    // when filter type is 'all' or 'accounts', <DodMarketTreePicker /> is rendered
                    // Hence we don't require getOptions() in these cases
                },
                filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const marketsNodes = filterMarketOptions({
                            includeFmcgRetailers: true,
                            includeSpecialityRetailers: true,
                            includeGeographyMarkets: true,
                            includeTotalUSMarkets: true,
                            includePanelTotal: false,
                            includePannelByChannel: false,
                            searchTerm: searchTerm,
                            categories: filterValuesToStrings(filters.categories, userCategories),
                            purchasedMarketKeys: company?.purchasedMarketKeys,
                            isDodMarketPicker: true,
                            hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                            summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                            nonSummedCategories: filters?.categories.values as string[],
                            isMarketSummedMode:inSummedMode,
                        });                       
                        const result = marketsNodes.filter((market) => !checkMarketHidden(market))
                            .map((market) => ({
                                text: market.name,
                                display: <MarketPickerNode marketNode={market} />,
                                value: market.name,
                                disabled: checkMarketDisabled(market),
                            }));
                        return result;
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            channels: {
                async getOptions(filters) {
                    const markets = await getMarketsOptionsByMarketFilterType({
                        categories: filterValuesToStrings(filters.categories, userCategories),
                        marketType: 'Channel',
                        purchasedMarketKeys: company?.purchasedMarketKeys,
                        isDodMarketPicker: true,
                        hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                        summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        nonSummedCategories: filters?.categories.values as string[],
                        isMarketSummedMode:inSummedMode,
                    });
                    return markets.filter((market) => !checkMarketHidden(market.data!))
                        .map((market) => ({
                            text: market.display,
                            display: <MarketPickerNode marketNode={market.data!} />,
                            value: market.display,
                            disabled: checkMarketDisabled(market.data!),
                        }));
                },
            },
            accounts: {
                getOptions(filters) {
                    // when filter type is 'all' or 'accounts', <DodMarketTreePicker /> is rendered
                    // Hence we don't require getOptions() in these cases
                },
                filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const marketsNodes = filterMarketOptions({
                            includeFmcgRetailers: true,
                            includeSpecialityRetailers: true,
                            includeGeographyMarkets: false,
                            includeTotalUSMarkets: false,
                            includePanelTotal: false,
                            includePannelByChannel: false,
                            searchTerm: searchTerm,
                            categories: filterValuesToStrings(filters.categories, userCategories),
                            purchasedMarketKeys: company?.purchasedMarketKeys,
                            isDodMarketPicker: true,
                            hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                            summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                            nonSummedCategories: filters?.categories.values as string[],
                            isMarketSummedMode:inSummedMode,
                        });
                        return marketsNodes.filter((market) => !checkMarketHidden(market))
                            .map((market) => ({
                                text: market.name,
                                display: <MarketPickerNode marketNode={market} />,
                                value: market.name,
                                disabled: checkMarketDisabled(market),
                            }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            stateLines: {
                async getOptions(filters) {
                    const markets = await getMarketsOptionsByMarketFilterType({
                        categories: filterValuesToStrings(filters.categories, userCategories),
                        marketType: 'Stateline',
                        purchasedMarketKeys: company?.purchasedMarketKeys,
                        isDodMarketPicker: true,
                        hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                        summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        nonSummedCategories: filters?.categories.values as string[],
                        isMarketSummedMode:inSummedMode,
                    });
                    return markets.filter((market) => !checkMarketHidden(market.data!))
                        .map((market) => ({
                            text: market.display,
                            display: <MarketPickerNode marketNode={market.data!} />,
                            value: market.display,
                            disabled: checkMarketDisabled(market.data!),
                        }));
                },
            },
            majorMarkets: {
                async getOptions(filters) {
                    const markets = await getMarketsOptionsByMarketFilterType({
                        categories: filterValuesToStrings(filters.categories, userCategories),
                        marketType: 'Major Market',
                        purchasedMarketKeys: company?.purchasedMarketKeys,
                        isDodMarketPicker: true,
                        hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                        summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        nonSummedCategories: filters?.categories.values as string[],
                        isMarketSummedMode:inSummedMode,
                    });
                    return markets.filter((market) => !checkMarketHidden(market.data!))
                        .map((market) => ({
                            text: market.display,
                            display: <MarketPickerNode marketNode={market.data!} />,
                            value: market.display,
                            disabled: checkMarketDisabled(market.data!),
                        }));
                },
            },
            regions: {
                async getOptions(filters) {
                    const markets = await getMarketsOptionsByMarketFilterType({
                        categories: filterValuesToStrings(filters.categories, userCategories),
                        marketType: 'Region',
                        purchasedMarketKeys: company?.purchasedMarketKeys,
                        isDodMarketPicker: true,
                        hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                        summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        nonSummedCategories: filters?.categories.values as string[],
                        isMarketSummedMode:inSummedMode,
                    });
                    return markets.filter((market) => !checkMarketHidden(market.data!))
                        .map((market) => ({
                            text: market.display,                            
                            display: <MarketPickerNode marketNode={market.data!} />,
                            value: market.display,
                            disabled: checkMarketDisabled(market.data!),
                        }));
                },
            },
            divisions: {
                async getOptions(filters) {
                    const markets = await getMarketsOptionsByMarketFilterType({
                        categories: filterValuesToStrings(filters.categories, userCategories),
                        marketType: 'Division',
                        purchasedMarketKeys: company?.purchasedMarketKeys,
                        isDodMarketPicker: true,
                        hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                        summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        nonSummedCategories: filters?.categories.values as string[],
                        isMarketSummedMode:inSummedMode,
                    });
                    return markets.filter((market) => !checkMarketHidden(market.data!))
                        .map((market) => ({
                            text: market.display,                            
                            display: <MarketPickerNode marketNode={market.data!} />,
                            value: market.display,
                            disabled: checkMarketDisabled(market.data!),
                        }));
                },
            },
        }),
        [filters, filterType, checkMarketDisabled, checkMarketHidden]
    );
    const [normalizedValue, setNormalizedValue] = useState<string[] | 'all'>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (activeMarketFolderTab === 'markets') {
            (async () => {
                const { getOptions } = filterValueConfigs[filterType];
                setLoading(isAsyncFunction(getOptions));
                const options = await getOptions(filters, filterType);
                setOptions(options ?? []);
                setLoading(false);
            })();
        }
    }, [filterType, activeMarketFolderTab, filterValueConfigs]);

    useEffect(() => {
        if (markets.length) {
            // const allValues: ValueType[] = options.map(({ value }) => value);
            // const allMatched =
            //     intersection(
            //         allValues,
            //         markets.map((market) => market.name)
            //     ).length === allValues.length;
            // if (allMatched) {
            //     setNormalizedValue('all');
            // } else {
            const { valueToStrings = defaultValueToStrings } = filterValueConfigs[filterType];
            setNormalizedValue(valueToStrings(markets));
            // }
        } else {
            const { valueToStrings = defaultValueToStrings } = filterValueConfigs[filterType];
            setNormalizedValue(valueToStrings(markets));
        }
    }, [markets, filterType, filters, filterValueConfigs]);

    useEffect(() => {
        setMarkets(value);
    }, [value]);

    useEffect(() => {
        if (activeMarketFolderTab === 'search') {
            setOptions([]);
            setLoading(true);
            const marketsNodes: MarketNode[] = filterMarketOptions({
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,
                includeTotalUSMarkets: true,
                includePanelTotal: false,
                includePannelByChannel: false,
                categories: filterValuesToStrings(filters.categories, userCategories),
                marketType: advancedSearchFilters?.marketType?.value,
                marketLevel: advancedSearchFilters?.marketLevel?.value,
                marketTier: advancedSearchFilters?.marketTier?.value,
                parentCompanies: advancedSearchFilters?.parentCompany?.map((parentCompany) => parentCompany.value),
                channel: advancedSearchFilters?.channel?.value,
                childMarketType: advancedSearchFilters?.childMarketType?.value,
                purchasedMarketKeys: company?.purchasedMarketKeys,
                isDodMarketPicker: true,
                hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                nonSummedCategories: filters?.categories.values as string[],
                isMarketSummedMode:inSummedMode,
            });
            const result = marketsNodes.filter((market) => market.selectable && !checkMarketHidden(market))
                .map((market) => ({
                    text: market.name,
                    display: <MarketPickerNode marketNode={market} />,
                    value: market.name,
                    disabled: checkMarketDisabled(market),
                    marketHasPartialApproval: market.hasPartialApproval, 
                }));
            setOptions(result as DodValueOption[]);
            setLoading(false);
        }
    }, [advancedSearchFilters, activeMarketFolderTab, checkMarketDisabled, checkMarketHidden]);

    const categories = useMemo<string[]>(() => {
        if (
            filters.categories.values === 'all' ||
            filters.categories.summedSelections.some((ss) => ss.values === 'all')
        ) {
            return getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories);
        } else {
            return filterValuesToStrings(filters.categories, userCategories);
        }
    }, [filters]);

    async function handleChange(e) {
        if (Array.isArray(e.value)) {
            let runConfigMarkets: RunConfigMarket[] = e.value.map((marketName) =>
                getRunConfigMarketForMarketName(
                    marketName,
                    categories,
                    true,
                    filters?.categories?.summedSelections.length ? true : false,
                    filters?.categories?.summedSelections.map((item) => item.values).flat(),
                    filters?.categories.values as string[],
                    inSummedMode,
                )
            );
            const newMarketSet: RunConfigMarket[] = removeDuplicateMarkets([...runConfigMarkets]);
            setMarkets(newMarketSet);
            onChange?.({
                ...e,
                value: newMarketSet,
            });
        } else {
            let runConfigMarkets: RunConfigMarket[] = options
                .filter((option) => !option.disabled)
                .map((marketOption) =>
                    getRunConfigMarketForMarketName(
                        marketOption.value,
                        categories,
                        true,
                        filters?.categories?.summedSelections.length ? true : false,
                        filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        filters?.categories.values as string[],
                        inSummedMode,
                    )
                ) as RunConfigMarket[];
            const newMarketSet: RunConfigMarket[] = removeDuplicateMarkets([...runConfigMarkets]);
            // this is needed because the `options` may include the previously selected options
            // for example first selecting from 'Accounts' hierarchy 
            // then switching to 'Channels' and selecting all will clear previous selections
            setMarkets((prev)=>{
                const latestSelectedMarkets = unionBy(newMarketSet, prev, 'name')
                onChange?.({
                    ...e,
                    value: latestSelectedMarkets,
                });
                return latestSelectedMarkets
            });
        }
    }

    function handleMarketTreeChange(e) {
        setMarkets(e.value);
        onChange?.({
            ...e,
            value: e.value,
        });
    }

    function handleMarketTreeApply(e) {
        onApply?.({
            ...e,
            value: markets,
        });
    }

    function removeDuplicateMarkets(markets: RunConfigMarket[]): RunConfigMarket[] {
        const stringifiedMarkets = markets.map((market) => JSON.stringify(market));
        const marketSet = new Set(stringifiedMarkets);
        const marketArray = Array.from(marketSet).map((market) => JSON.parse(market));

        return marketArray;
    }

    function handleApply(e) {
        if (Array.isArray(e.value)) {
            let runConfigMarkets: RunConfigMarket[] = e.value.map((marketName) =>
                getRunConfigMarketForMarketName(
                    marketName,
                    categories,
                    true,
                    filters?.categories?.summedSelections.length ? true : false,
                    filters?.categories?.summedSelections.map((item) => item.values).flat(),
                    filters?.categories.values as string[],
                    inSummedMode,
                )
            );
            setMarkets(runConfigMarkets);
            onApply?.({
                ...e,
                value: runConfigMarkets,
            });
        } else {
            let runConfigMarkets: RunConfigMarket[] = options
                .filter(({ disabled }) => !disabled)
                .map((marketOption) =>
                    getRunConfigMarketForMarketName(
                        marketOption.value,
                        categories,
                        true,
                        filters?.categories?.summedSelections.length ? true : false,
                        filters?.categories?.summedSelections.map((item) => item.values).flat(),
                        filters?.categories.values as string[],
                        inSummedMode,
                    )
                ) as RunConfigMarket[];
            // this is needed because the `options` may include the previously selected options
            // for example first selecting from 'Accounts' hierarchy 
            // then switching to 'Channels' and selecting all will clear previous selections
            setMarkets((prev)=>{
                const latestSelectedMarkets = unionBy(runConfigMarkets, prev,'name')
                onApply?.({
                    ...e,
                    value: latestSelectedMarkets,
                });
                return latestSelectedMarkets
            });
        }
    }

    return (
        <>
            {activeMarketFolderTab === 'markets' ? (
                <>
                    {filterType === 'all' || filterType === 'accounts' ? (
                        <DodMarketTree
                            className={classnames(baseClassName, className)}
                            value={markets}
                            limit ={limit}
                            onLimitExceed = {()=>alertSelectionLimitExceed("market",limit)}
                            normalizedValue={normalizedValue}
                            onChange={handleMarketTreeChange}
                            onApply={handleMarketTreeApply}
                            loading={loading}
                            categories={categories}
                            filterType={filterType}
                            title="Make Your Market Selections"
                            filterText={filterText}
                            options={options}
                            onOptionChange={handleChange}
                            onOptionApply={handleApply}
                            onFilterChange={filterValueConfigs[filterType].filterValues}
                            hideTotalUSMarkets={filterType === 'accounts'}
                            hideGeographicMarkets={filterType === 'accounts'}
                            filters={filters}  
                            inSumSelectionMode={inSummedMode}     
                            {...props}
                        />
                    ) : (
                        <DodFilterValuePicker
                            className={classnames(baseClassName, className)}
                            options={options}
                            loading={loading}
                            onChange={handleChange}
                            onApply={handleApply}
                            limit = {limit}
                            onLimitExceed = {()=>alertSelectionLimitExceed("market",limit)}
                            value={normalizedValue}
                            onFilterChange={filterValueConfigs[filterType].filterValues}
                            filterText={filterText}
                            enableExplicitAllSelection={true}
                            dodWizardStep="market"
                            displaySelectAllOption = {!inSummedMode}               
                            {...props}
                        />
                    )}
                </>
            ) : (
                <>
                    <DodFilterValuePicker
                        className={classnames(baseClassName, className)}
                        options={options}
                        loading={loading}
                        limit = {limit}
                        onLimitExceed = {()=>alertSelectionLimitExceed("market",limit)}
                        onChange={handleChange}
                        onApply={handleApply}
                        value={normalizedValue}
                        filterText={filterText}
                        enableExplicitAllSelection={true}
                        dodWizardStep="market"
                        displaySelectAllOption = {!inSummedMode}               
                        {...props}
                    />
                </>
            )}
        </>
    );
}

export default DodMarketFilterValuePicker;