import { forwardRef, useEffect, useState } from 'react';
import { ByzzerChangeEventHandler, ByzzerSearch, ByzzerSelectOption, WithValue } from '@byzzer/ui-components';
import classNames from 'classnames';
import {useTenantApi} from '@/hooks/useTenantApi';

export type ByzzerUPCSearchProps = {
    name?: string;
    className?: string;
    placeholder?: string;
    maxSelections?: number;
    label?: string;
    value: any;
    onlyRenderIf?: boolean;
    onChange?: ByzzerChangeEventHandler<any>;
};
const baseClassName = '';
export const ByzzerUPCSearch = forwardRef<WithValue<string | null>, ByzzerUPCSearchProps>(
    ({ name, className, label, value, placeholder, onChange, onlyRenderIf, maxSelections, ...props }, ref) => {
        if (onlyRenderIf === false) return null;
        const { getUPCForCategories, searchMultipleUPCs } = useTenantApi();

        const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>([]);

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        function handleChange(e: ByzzerChangeEvent<ByzzerSelectOption[]>): void {
            onChange?.({
                value: e?.value,
                name,
            });
        }

        async function searchUPCs(searchText: string) {
            if (searchText === '' || (searchText && searchText.length <= 0)) {
                return;
            }
            let searchTextArray: string[] = searchText.split(',').map((item: string) => item.trim());
            let searchData: any;
            if (searchTextArray.length > 1) {
                searchData = await searchMultipleUPCs(searchTextArray);
                let upcData: ByzzerSelectOption[] = searchData?.upc_details?.map((upcDetails) => {
                    return {
                        display: upcDetails.upc,
                        value: upcDetails.upc,
                        data: upcDetails,
                    };
                });
                const upc: any = [...internalValue, ...upcData];
                const filteredData = upc.filter((obj, index) => {
                    return index === upc.findIndex((o) => obj.value === o.value);
                });
                onChange?.({
                    value: filteredData,
                    name,
                });
                return;
            } else {
                searchData = await getUPCForCategories(searchText, undefined, undefined);

                if (searchData && Object.keys(searchData)?.length === 0) {
                    return;
                    // Add logic to display error message
                } else {
                    return searchData?.upc_details?.map((upcDetails) => {
                        return {
                            display: upcDetails.upc,
                            value: upcDetails.upc,
                            data: upcDetails,
                        };
                    });
                }
            }
        }

        return (
            <ByzzerSearch
                ref={ref}
                name={name}
                className={classNames(baseClassName, className)}
                search={searchUPCs}
                onChange={handleChange}
                value={internalValue}
                label={label}
                placeholder={placeholder}
                maxSelections={Number(maxSelections) > 0 ? maxSelections : undefined}
            />
        );
    }
);

export default ByzzerUPCSearch;
