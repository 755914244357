// IMPORTANT: importing bootstrap is the very first thing we should do to ensure the system is initialized
import './bootstrap';
// import the byzzer global styles. DO NOT IMPORT ANY ANT STYLES AFTER THIS.  we need to be able to override them here
import './index.scss';
import React from 'react';
import {HashRouter} from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import {intercomConfig} from '@/config';
import {AppProvider} from '@/contexts/UserContext';
import {IntercomProvider} from 'react-use-intercom';
import {AnalyticsProvider} from '@/analytics';
import {MarketProvider} from '@/contexts/MarketContext';
import IntercomChat from '@/components/IntercomChat/IntercomChat';
import {ByzzerModalContainer} from '@/components/modals/ByzzerModalContainer/ByzzerModalContainer';
import '@/services/routeDebugger';
import {createRoot} from "react-dom/client";
import axios from "axios";
import {SystemMaintenance} from './components/SystemMaintenance';

;(async () => {
    try {
        const localVersion = Number(localStorage.getItem('version'));
        const {data} = await axios.get('meta.json', {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        });
        const latestVersion = data.version;

        if (!localVersion || latestVersion > localVersion) {
            caches.delete('byzzer');
            localStorage.setItem('version', latestVersion.toString());
        }
    } catch (err) {
        // do nothing
        // console.error(err);
    }
})();

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <>
        <HashRouter>
            <IntercomProvider appId={intercomConfig.appId!} shouldInitialize={intercomConfig.shouldInitialize}>
                <AppProvider>
                    <MarketProvider>
                        <AnalyticsProvider>
                            <ByzzerModalContainer>
                                <SystemMaintenance>
                                    <App/>
                                </SystemMaintenance>
                            </ByzzerModalContainer>
                        </AnalyticsProvider>
                    </MarketProvider>
                </AppProvider>
            </IntercomProvider>
        </HashRouter>
        {/*  @ts-ignore */}
        <IntercomChat/>
    </>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
serviceWorker.unregister();
export {caseInsensitiveSort} from '@/utils/Sort';
export {MinMaxMessage} from '@/components/MinMaxMessage';
export {toAxisDef} from "@/components/DodConfigEditor/toAxisDef";
