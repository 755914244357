import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useUser} from "@/contexts/UserContext";

export type AnalyticsEvent = {
    type: string;
    name?: string;
    group?: string;
    data?: Record<string, string | number>;
}
export type AnalyticsContextValue = {
    trackEvent(event: AnalyticsEvent): void;
    event?: AnalyticsEvent;
}

const AnalyticsContext = createContext<AnalyticsContextValue>({
    trackEvent() {

    }
});

export const useTrackEvent = () => useContext(AnalyticsContext).trackEvent;
export const useAnalyticsEvent = () => useContext(AnalyticsContext).event;

export function AnalyticsProvider({children}: PropsWithChildren) {

    const location = useLocation();
    const [value, setValue] = useState<AnalyticsContextValue>({
        trackEvent(event: AnalyticsEvent): void {
            setValue(value => ({
                ...value,
                event,
            }));
        }
    })

    useEffect(() => {
        const {hash, pathname} = location;
        setValue(value => ({
            ...value,
            event: {
                type: 'navigation',
                data: {
                    // transform hash route to normal path if necessary
                    path: hash ? `${pathname}/${hash}` : pathname,
                },
            }
        }));
    }, [location]);

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}

export const useEventDataWithUserInfo = () => {
    const { user } = useUser();
    const { email = '', role = '', firstName = '', lastName = '' } = user || {};
    const name = `${firstName} ${lastName}`;

    // * prefixing user_* keys with 'a_' ensures they appear first when sorted
    return (eventParams?: { [key: string]: any }) => ({
        ...(eventParams || {}),
        a_user_email: email,
        a_user_name: name,
        a_user_role: role,
    });
};