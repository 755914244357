import './ReportHistory.scss';
import {useEffect, useMemo, useRef, useState, useCallback} from 'react';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useUser} from '@/contexts/UserContext';
import {useBetterNavigate} from '@/utils';
import { AgGridReact } from 'ag-grid-react';
import { ByzzerTable } from '@/components/ByzzerTable';
import { CellEditRequestEvent, CellEvent, ColDef } from 'ag-grid-community';
import { openErrorModal, confirm, alert as byzzerAlert, ByzzerModal} from '@/components/form';
import {getProductBySku} from "@/services/product.service";
import classnames from 'classnames';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import editNotesPencilIcon from '@images/icons/niq-pencil-blue.svg';
import {ByzzerButton, ZonedDate } from '@byzzer/ui-components';
import { CharacteristicCondition } from '@/types/ReportRun';
import { intersection } from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';
import { ReportRunSummary } from '@/types/ApiTypes';
import {FLOATING_DATE_PICKER_FILTER_PARAMS} from '@/constants/table.constants'
import { reportDescriptionUrl } from '@/config/globalVars';
import ReportInformation from '../ReportInformation';
import { TipIcon } from '@/components/icons';

/**
 * Useful Links - 
 * https://www.ag-grid.com/react-data-grid/ 
 * https://www.ag-grid.com/react-data-grid/component-cell-renderer/ 
 * https://www.ag-grid.com/react-data-grid/cell-editing/
 * https://www.ag-grid.com/react-data-grid/cell-editing-start-stop/
 * https://www.ag-grid.com/react-data-grid/videos/
 * https://www.ag-grid.com/react-data-grid/change-detection/
 */ 

const NOTES_PLACEHOLDER = 'Add notes';
const NOTES_FIELD_ID = 'notes';

function ReportHistory() {
    const { getMySubscriptionUsage, getCompanyReportRuns, deleteProductUsingActionId, saveFavorites, saveNotes } = useTenantApi();
    const baseClassName = 'report-history'; 

    const {user, categories: subscriptionCategories, omniCategories: subscriptionOmniCategories, subscription, subscribedSkus, maxDataDates} = useUser();
    const [reportRuns, setReportRuns] = useState<ReportRunSummary[]>([]);    
    const navigate = useBetterNavigate();
    const [loading, setLoading] = useState(false);    
    const [canRunReports, setCanRunReports] = useState(false);    
    const gridRef = useRef<AgGridReact>(null);
    const menuRefs = useRef<any[]>([]);     
    const [showReportInfo, setShowReportInfo] = useState(false);
    const [selectedReport, setSelectedReport] = useState({} as ReportRunSummary);

    useEffect(() => {
        if (user?.role) {
            setCanRunReports(['user', 'admin'].includes(user?.role));
        }
    }, [user]);

    useEffect(() => {
        loadHistory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadHistory() {        
        try {
            setLoading(true);
            // todo: move this outside of this function
            const companyReportRuns: ReportRunSummary[] = await getCompanyReportRuns(subscribedSkus);
            const sortedRuns = companyReportRuns.sort((a, b) => b.runDtm.localeCompare(a.runDtm));            
            setReportRuns(sortedRuns.map( (item)=>{ return {...item , runDtm: formatInTimeZone(new Date(item.runDtm), 'America/New_York', 'yyyy-MM-dd') }} ))
            
        } catch (err: any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
        } finally {
            setLoading(false);
        }
    };   

    /**
    * handle favorite feature by clicking star icon in each record which is a toggle btn   
    */
    async function handleFavorites (favRecord:ReportRunSummary) { 
            
        const reportId: number  = favRecord.id ;
        const isfav   : boolean = !favRecord.isFavorite ;
        
        // gridRef.current?.props.rowData?.find((element) => element.id === reportId)        
        // let favindex = gridRef.current?.props.rowData?.findIndex((element) => element.id === reportId)        
        try {            
            let updatedReportRuns = gridRef.current?.props.rowData?.map( obj =>{
                if (obj.id === reportId) {
                    return {...obj, isFavorite: !obj.isFavorite}    
                } 
                return obj ;          
            })            
             setReportRuns(updatedReportRuns as ReportRunSummary[]);
            //  setLoading(true);
             await saveFavorites( reportId , isfav );
            
        } catch (error:any) {
            console.log(error.message);
        } 
    } 
   /**
    * newvalue is set as undefined if newvalue equals to oldvalue OR newvalue is empty/white spaces OR newvalue equals to Add Notes
    * save happens only if newvalue.trim() is not undefined
    */
    async function handleNotesEdit({data: { id: reportId }, oldValue, newValue}: CellEditRequestEvent<ReportRunSummary>){
        try {
            const newNotes: string = !newValue || ['', NOTES_PLACEHOLDER].includes(newValue.trim()) ? undefined : newValue.trim();
            
            if (!(Boolean(!oldValue) && Boolean(!newNotes))) { // if editing an already empty cell and enter nothing or just spaces, then do nothing
                let updatedReportRuns = gridRef.current?.props.rowData?.map( obj =>{
                    if (obj.id === reportId) {
                        return {...obj, notes: newNotes}    
                    } 
                    return obj           
                })
                
                setReportRuns(updatedReportRuns as ReportRunSummary[]);
                //  setLoading(true);
                await saveNotes( reportId , newNotes );                 
            }
        } catch (error:any) {
            console.log(error.message);
        }        
    }

    function handleReportInfo(run: ReportRunSummary) {
        setShowReportInfo(!showReportInfo);
        setSelectedReport(run);
    }
    
    const defaultColDef = useMemo( ()=> ({
        filter: true,   // make every column use 'text' filter by default
        sortable: true,        
        floatingFilter: true, // enable floating filters by default
        resizable:true,   //set each col resizable
        width: 170, // set every column width
        flex: 0,
        autoHeight: false,
        wrapText: false, 
        suppressMenu:true,     
        lockVisible: true 
       // suppressMovable:true, to be discussed today?
        
    }), []);

    async function runReport(reportRun: ReportRunSummary , runAgainWithLatestDate?:boolean) {
        const reportSku = reportRun?.sku;
        const reportId = reportRun?.id;
        const navigateTabIndex = reportRun?.report?.metadata?.configOptions?.length;
        
        if (user?.role && !['user', 'admin'].includes(user?.role)) { 
            return;
        }

        try {
            let usage = await getMySubscriptionUsage();
            const report = getProductBySku(reportSku);

            if (!subscribedSkus.includes(reportSku)) {
                await openErrorModal({
                    title: 'Upgrade Required',
                    content: (
                        <>
                            <p>"{report.title}" is not part of your current subscription.</p>
                            <p>Please upgrade your subscription or add a bundle to get access the report.</p>
                        </>
                    ),
                });
                return;
            }

            const {premiumReports, basicReports} = usage;
            const isPremiumReport = report?.metadata?.reportClass === 'premium';
            const hasPremiumRuns = premiumReports && premiumReports.used < premiumReports.limit;
            const hasBasicRuns = basicReports && basicReports.used < basicReports.limit;

            if ((isPremiumReport && !hasPremiumRuns) || (!isPremiumReport && !hasBasicRuns)) {
                await openErrorModal({
                    title: `No ${isPremiumReport ? 'Smart' : 'Core'} Runs Remaining`,
                    content: (
                        <>
                            <p>You have used all of your subscription's {isPremiumReport ? 'smart' : 'core'} runs.</p>
                            <p>
                                Please upgrade your subscription, add a bundle or purchase additional runs to run the
                                report.
                            </p>
                        </>
                    ),
                });
                return;
            }

            let reportRunByLatestDate :Date| undefined;
            
            //Note: as the maxdate is set with EST on the api, we first fetching the date and then convert to respective localTimeZone()
            reportRunByLatestDate = new ZonedDate(maxDataDates[reportRun.report?.metadata.dataType],'EST').translateToTimeZone(ZonedDate.getLocalTimeZoneName() ) ;

            navigate(`/dashboard/configure_report`, {
                search: {
                    sku: reportSku,
                    runType: 'subscription',
                    reportId: reportId,
                },
                ...(runAgainWithLatestDate ? {
                    state: {
                        reportRunByLatestDate,
                        navigateTabIndex: runAgainWithLatestDate ? navigateTabIndex : 0,
                        //navigateTabname: 'time_period' //TODO: this requires a fix at the component level as WithUid thorws some warnings, hence navigating by tabIndex
                    }
                } : {}),
            });
        } catch (err) {
        }
    }

    async function deleteReport(reportId) {
        if (
            !(await confirm({
                // @ts-ignore
                title: 'Delete Report',
                content: <div className="byzzer-allocation-warning">Are you sure you want to delete this report?</div>,
                yesLabel: 'Yes',
                noLabel: 'No',
            }))
        ) {
            return;
        }
        setLoading(true);
        let delResponse = await deleteProductUsingActionId(reportId);
        setLoading(false);
        let alertContent = delResponse === 'Success' ? 'Report deleted successfully!' : 'Something went wrong';
        byzzerAlert({
            // @ts-ignore
            content: <div className="story_modal_alertText">{alertContent}</div>,
        });
        loadHistory();
    };

    function stringArrayToString(params: string[]): string {
        return params.join('; ');
    };

    function charDimToFeatureIdString(params: any[]) {  //  // todo: add real type for param     
        return [...new Set(params?.map((item) => item.featureId ? item.featureId : item))].join('; ')
    }

    // const marketsFormatterbyCellRenderedMethod = (params) =>{ // I may need this in future
    //     return params.join(',')
    // }

    function characteristicToString(params: CharacteristicCondition[]) {
        return params?.map((c) => `${c.characteristicDisplayValue ?? c.characteristic} ${c.condition} ${c.value.join(', ')}`).join('; ');
    }

    function handleCellClick({column, api: gridApi, node}: CellEvent<ReportRunSummary>): void {
        // Could use this to edit cells, like notes
        // if (column.getColId() === NOTES_FIELD_ID) {
        //     gridApi.startEditingCell({
        //         rowIndex: node.rowIndex!,
        //         colKey: column.getId(),
        //     });
        // }
    }

    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'report.title',
            headerName: 'Report',
            pinned: 'left',
            valueGetter: ({ data }) => {
                return `${data?.report?.title}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.report?.title}`;
            },
        },
        ...(window.localStorage['show-report-id'] === 'true' ? [{ field: 'id' }] : []),
        {
            headerName: 'Run By',
            valueGetter: ({ data }) => {
                return `${data.runBy.firstName} ${data.runBy.lastName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.runBy.firstName} ${data.runBy.lastName}`;
            },
        },
        {
          ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            field: 'runDtm',
            headerName: 'Run Date',
            width: 230,
        }, // cellDataType: 'date , this causing a problem
        {
            headerName: 'Brand(s) / Manufacturer(s)',
            valueGetter: ({ data }: { data: ReportRunSummary }) =>
                stringArrayToString([...data.brands, ...(data?.omniFocusProducts ?? [])]),
            tooltipValueGetter: ({ data }) => stringArrayToString([...data.brands, ...(data?.omniFocusProducts ?? [])]),
        },
        {
            headerName: 'Category(s)',
            tooltipField: 'categories',
            valueGetter: ({ data }: { data: ReportRunSummary }) => stringArrayToString(data.categories),
            cellRenderer: ({ data }: { data: ReportRunSummary }) => {
                return (
                    <span
                        className={classnames({
                            [`${baseClassName}__warning`]: Boolean(
                                !intersection(
                                    data?.categories,
                                    Boolean(data.report?.metadata.dataType === 'omni')
                                        ? subscriptionOmniCategories
                                        : subscriptionCategories
                                )?.length
                            ),
                        })}
                    >
                        {stringArrayToString(data.categories)}
                    </span>
                );
            },
        },
        {
            headerName: 'Time Period',
            valueGetter: ({ data }) => data.timePeriod,
            tooltipValueGetter: ({ data }) => {
                return `${data.timePeriod}`;
            },
        },
        {
            headerName: 'Market(s)',
            tooltipField: 'markets',
            valueGetter: ({ data }) => stringArrayToString(data.markets),
        },
        {
            headerName: 'Characteristic Dimension(s)',
            valueGetter: ({ data }) => charDimToFeatureIdString(data.charDimension),
            tooltipValueGetter: ({ data }) => charDimToFeatureIdString(data.charDimension),
        },
        {
            headerName: 'Characteristic(s)',
            valueGetter: ({ data }) => characteristicToString(data.charFilters),
            tooltipValueGetter: ({ data }) => characteristicToString(data.charFilters),
        },
        {
            headerName: '',
            field: 'isFavorite',
            cellRenderer: ({ data }: { data: ReportRunSummary }) => {
                const { id } = data;
                return (
                    <div className={classnames(`${baseClassName}__actions`)}>
                        <FavoriteIcon
                            className={`${baseClassName}__favorites--icon`}
                            trackClick={`Filter by favorites`}
                            selected={data?.isFavorite}
                            onClick={() => handleFavorites(data)}
                        />
                        {/* @ts-ignore */}
                        <TipIcon
                            className={`${baseClassName}__info--icon`}
                            trackClick={`Report Info (${data?.report?.title}) clicked`}
                            onClick={() => handleReportInfo(data)}
                        />
                        <div
                            key={id}
                            className={classnames(`${baseClassName}__menu-trigger`)}
                            ref={(thisElement) => (menuRefs.current[String(id)] = { current: thisElement })}
                        />
                        <ByzzerMenu
                            className={`${baseClassName}__menu`}
                            reference={menuRefs.current[String(id)]}
                            offset={[-75, -25]}
                            triggerTarget={menuRefs.current[String(id)]?.current}
                            items={[
                                {
                                    content: 'View report',
                                    trackClick: `Report History View (${data.report?.title}) clicked`,
                                    toolTipHelpText: 'View report',
                                    to: `/report/${data?.id}`,
                                },

                                {
                                    showIf: data.runnable && subscription.active,
                                    content: 'Run a new report',
                                    trackClick: `Report History Run-new-report (${data?.report?.title}) clicked`,
                                    toolTipHelpText: 'Run a new report',
                                    onClick: function () {
                                        runReport(data);
                                    },
                                },

                                {
                                    showIf: data.runnable && subscription.active,
                                    content: 'Run with latest date',
                                    trackClick: `Report History Run-again-report-with-latestdate (${data?.report?.title}) clicked`,
                                    toolTipHelpText: 'Run report with latest date',
                                    onClick: function () {
                                        runReport(data, true);
                                    },
                                },

                                {
                                    showIf: subscription.active,
                                    content: 'Delete report',
                                    trackClick: `Report History delete (${data?.report?.title}) clicked`,
                                    toolTipHelpText: 'Delete',
                                    onClick: function () {
                                        deleteReport(data.id);
                                    },
                                },
                            ]}
                        />
                    </div>
                );
            },
            pinned: 'right',
            resizable: false,
            lockPosition: 'right',
            sortable: false,
            width: 135,
            maxWidth: 135,
            minWidth: 135,
        },

        // APPROACH -2
        {
            field: NOTES_FIELD_ID, // DO NOT CHANGE; needed for editing function to work
            headerName: 'Notes',
            editable: true, // enables editing
            cellEditorPopup: true,
            cellEditor: 'agLargeTextCellEditor',
            // headerComponentParams: { menuIcon: <span className={`fa fa-external-link-alt`}></span> },
            cellEditorParams: {
                maxLength: 100,
                rows: 10,
                cols: 50,
            },
            tooltipValueGetter: ({ data }) => `Click to edit`,
            cellRenderer: (props) => (
                <span
                    className={classnames(`${baseClassName}__notes`, {
                        // could add onClick handler to enable single click edit mode, etc
                        [`${baseClassName}__notes--has-notes`]: props.data.notes,
                        [`${baseClassName}__notes--empty`]: !props.data.notes,
                    })}
                >
                    {props.data.notes ?? NOTES_PLACEHOLDER}
                </span>
            ),
            headerTooltip: 'Make your notes here',
        },
    ]);

    return (
        <div
            className={classnames(baseClassName, {
                [`${baseClassName}--readonly`]: !canRunReports,
                [`${baseClassName}--runnable`]: canRunReports,
            })}
        >
            <ByzzerMask show={loading} loading={loading} />
            {Boolean(!reportRuns?.length) && !loading && (
                <div className={`${baseClassName}__empty-state`}>You Haven't Run Any Reports Yet.</div>
            )}

            {selectedReport?.report?.title && (
                <ByzzerModal
                    show={showReportInfo}
                    onClose={() => setShowReportInfo(!showReportInfo)}
                    heading={selectedReport?.report?.title}
                    size={'large'}
                    headerType={'normal'}
                    extraIcon={
                        canRunReports &&
                        subscribedSkus.includes(selectedReport.sku) && (
                            <ByzzerButton label={'Run Report'} onClick={() => runReport(selectedReport)} />
                        )
                    }
                >
                    <ReportInformation                        
                        sku={selectedReport.sku}
                        disabled={!canRunReports || !subscribedSkus.includes(selectedReport.sku)}
                        url={
                            selectedReport?.report?.metadata.detailsUrl ||
                            `${reportDescriptionUrl}/${selectedReport?.sku}.html`
                        }
                        runReport={() => runReport(selectedReport)}
                    />
                </ByzzerModal>
            )}

            {Boolean(reportRuns?.length) && (
                <ByzzerTable
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={reportRuns} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Column Defs for Columns
                    readOnlyEdit={true}
                    onCellEditRequest={handleNotesEdit}
                    // onCellClick={handleCellClick}
                    //  suppressClickEdit={false}
                    singleClickEdit={true}
                />
            )}
        </div>
    );
}


export default ReportHistory;