import {DodPreset, DodPresetMap, ProductValueFilterParams} from "@/types/ApiTypes";
import {ApiClient, defaultTenantApiClient} from "@/api/ApiClient";
import {DodRunConfig, DodSchedule} from "@/types/DodRun";

export async function getDodPresets(client: ApiClient = defaultTenantApiClient): Promise<DodPresetMap> {
    return client.httpGet(`/my/extract_presets`);
}

export async function updateDodPreset(id: number, values: any, client: ApiClient = defaultTenantApiClient): Promise<DodPreset> {
    return client.httpPatch(`/my/extract_presets/${id}`, values);
}

export async function createDodPreset(preset: Omit<DodPreset, 'id'>, client: ApiClient = defaultTenantApiClient): Promise<DodPreset> {
    return client.httpPost('/my/extract_presets', preset);
}

export async function deleteDodPreset(id: number, client: ApiClient = defaultTenantApiClient): Promise<void> {
    return client.httpDelete(`/my/extract_presets/${id}`);
}

export async function getDodPreset(id: number, client: ApiClient = defaultTenantApiClient): Promise<DodPreset> {
    return client.httpGet(`/my/extract_presets/${id}`);
}

export async function getDodExcelTemplates(client: ApiClient = defaultTenantApiClient) {
    return client.httpGet(`/extract_templates`);
}

let dataPointsAbortController: AbortController;
export async function estimateExtractDataPoints(config: DodRunConfig, client: ApiClient = defaultTenantApiClient): Promise<number> {

    // cancel any previous active request before sending the next one.
    dataPointsAbortController?.abort();
    dataPointsAbortController = new AbortController();

    const {estimate} = await client.httpPost('/extracts.estimate_data_points', normalizeDodConfig(config), {
        signal: dataPointsAbortController.signal
    });
    return estimate;
}

export async function createExtract(config: DodRunConfig, isFutureTemplate: boolean = false, client: ApiClient = defaultTenantApiClient): Promise<DodRunConfig> {

    return client.httpPost('/extracts', {
        ...normalizeDodConfig(config),
        isFutureTemplate
    });
}

export async function runExtractLayoutChange( extractId: number, config: DodRunConfig, isFutureTemplate: boolean = false, client: ApiClient = defaultTenantApiClient ) {
    return client.httpPost(`/data_on_demand/create_extract_hack_layout`, {
        extractId,
        runConfig: {
            ...normalizeDodConfig(config),
            isFutureTemplate
        },
    });
}

export async function createExtractSchedule(config: DodRunConfig, schedule?: DodSchedule, isFutureTemplate: boolean = false, client: ApiClient = defaultTenantApiClient): Promise<DodRunConfig> {

    return client.httpPost('/extract_schedules', {
        ...normalizeDodConfig(config),
        isFutureTemplate,
        schedule,
    });
}

export async function updateExtractSchedule(id: number, config: DodRunConfig, schedule?: DodSchedule, isFutureTemplate: boolean = false, client: ApiClient = defaultTenantApiClient): Promise<DodRunConfig> {

  return client.httpPut('/extract_schedules', {
      ...normalizeDodConfig(config),
      isFutureTemplate,
      schedule,
      id
  });
}

export async function getDodSeriesNames(
    client: ApiClient = defaultTenantApiClient
): Promise<{ [key: string]: string[] }> {
    return client.httpGet('/extracts/series_names');
}

export async function updateExtractName(id: number, name: string, type: 'name' | 'series' = "name", isSchedule = false, client: ApiClient = defaultTenantApiClient): Promise<any> {
    if(isSchedule){
        return client.httpPatch(`/extracts_schedule_series_name/${id}`, {id, name});
    }
    return client.httpPatch(`/extracts_name/${id}/${type}`, {
        name
    });
}

export function normalizeDodConfig(config: DodRunConfig): any {
    return {
        ...config,
        filters: {
            ...config.filters,
            markets: {
                // convert market displays names to keys
                values: config.filters.markets.values.map(v => v.key),
                summedSelections: config.filters.markets.summedSelections.map(v => ({
                    name: v.name,
                    values: v.values.map(v => v.key)
                }))
            },
            characteristics: Object.values(config.filters.characteristics),
            customCharacteristics: Object.values(config.filters.customCharacteristics)
        }
    }
}
