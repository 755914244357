import { useEffect, useRef } from 'react';
import StoryValidationProgress from '@/components/story/storyLoader/StoryValidationProgress';
import { useUser } from '@/contexts/UserContext';
import { StoriesContext } from '@/types/StoriesTypes';
import ReviewAndRunStories from '@/components/story/StoryDataSpecSelectors/ReviewAndRunStories';
import StoryObjectivePreview from '@/components/story/StoryDataSpecSelectors/StoryObjectivePreview';
import { TabWizard, TabWizardRef } from '@byzzer/ui-components';
import {
    StoryMarketPerformanceSelector,
    StoryMarketSelector,
    StoryProductSelector,
    StoryShopperSegmentSelector,
    StoryTimePeriodSelector,
} from '../../../components/story/StoryDataSpecSelectors';
import { useStoriesContext } from './StoriesContext';
export const StoryRunConifgWizard = ({ state }) => {
    const user = useUser();
    const {
        updateStoryContextStateValues,
        validationInProgress,
        dataSpecificationValues,
        selectorsConfigurations,
        runStory,
        resetStoriesContext,
        storyCustomName,
        isStoryNameValid
    }: StoriesContext = useStoriesContext();
    const STORY_SELECTORS = {
        objective_preview: <StoryObjectivePreview />,
        product: <StoryProductSelector />,
        time_period: <StoryTimePeriodSelector />,
        market_performance: <StoryMarketPerformanceSelector />,
        market: <StoryMarketSelector />,
        shopper_segment: <StoryShopperSegmentSelector />,
        review_and_run: <ReviewAndRunStories />,
    };
    useEffect(() => {
        resetStoriesContext();
        initialize();
    }, [state]);
    const initialize = async () => {
        await updateStoryContextStateValues(state?.storyConfig, user, state.from, state.defaultValues);
    };
    const disabledTipMessage = (index, selector) => {
        if (index === Object.keys(selectorsConfigurations).length - 1) {
            switch (selector.status) {
                case 'processing':
                    return 'Validating your selections.';
                case 'error':
                    return 'See errors above.';
                default:
                    if (!storyCustomName) {
                        return `Please enter a name for this story.`;
                    } else {
                        return 'You must choose all required values to continue';
                    }
            }
        }
        return 'You must choose all required values to continue';
    };
    function getNextButtonIcon(index, selector) {
        if (index === Object.keys(selectorsConfigurations).length - 1) {
            switch (selector.status) {
                case 'processing':
                    return 'busy';
                case 'error':
                    return 'error';
                case 'pending':
                    return 'error';
                default:
                    return 'ok';
            }
        }
        return '';
    }
    const wizardRef = useRef<TabWizardRef | null>(null)

    return (
        <>
            <StoryValidationProgress inProgress={validationInProgress === 'validating'} />
            <div className="story-content">
                <TabWizard autoExpanded={true} className={'story-tabwizard'} ref={wizardRef}>
                    {selectorsConfigurations.map((selector, index) => (
                        <TabWizard.Step
                            summary={''}
                            key={`step-${index}`}
                            title={selector.title}
                            status={''}
                            message={selector.validationMessage}
                        >
                            <TabWizard.Content>{STORY_SELECTORS[selector.type]}</TabWizard.Content>
                            <TabWizard.Actions
                                nextText={
                                    index === Object.keys(selectorsConfigurations).length - 1
                                        ? 'Review & Run'
                                        : 'Next Step'
                                }
                                disableNext={
                                    selector.status === 'pending' ||
                                    selector.status === 'processing' ||
                                    selector.status === 'error' ||
                                    !isStoryNameValid ||
                                    (index === Object.keys(selectorsConfigurations).length - 1 &&
                                        !storyCustomName)
                                }
                                nextIconType={getNextButtonIcon(index, selector)}
                                nextDisabledTip={disabledTipMessage(index, selector)}
                                key={index}
                                onClick={() => runStory(index)}
                            />
                        </TabWizard.Step>
                    ))}
                </TabWizard>
            </div>
        </>
    );
};
