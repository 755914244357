import React, {useCallback, useEffect, useState} from 'react';
import AlertsList from '@/components/AlertsList/AlertsList';

export const AvailableAlerts = (props) => {
    return (
        <AlertsList {...props}/>
    );
}
 
export default AvailableAlerts;

AvailableAlerts.displayName = 'AvailableAlerts';