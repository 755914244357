import './CategoryEditor.scss';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {Modal} from 'antd';
import {ByzzerButton} from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useLocation, useNavigate} from 'react-router-dom';
import Close from '@/images/icons/close-circle-icon.svg';
import ProductScopeNew from '@/components/ProductScopeNew';
import {useUser} from '@/contexts/UserContext';
import {openErrorModal} from '@/components/form/ByzzerModal';
import DashboardContent from "@/components/dashboard/DashboardContent";
import CategorySelector from "@/views/onboarding/CategorySelector";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";

const baseClassName = 'category-editor';
const CategoryEditor = (props) => {
    const {getMySubscriptionUsage, updateCategories} = useTenantApi();
    const location = useLocation();
    const canEditCategories = location.state?.canEditCategories;
    const uneditableCategories = location.state?.uneditableCategories ?? [];
    const {refreshUser, categories: currentCategories, subscription } = useUser();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [nonEditableCategories, setNonEditableCategories] = useState([]);
    const [showProductScopeModal, updateshowProductScopeModal] = useState(false);
    const [categoryOption, updateCategoryOption] = useState([]);
    const [maxCategories, setMaxCategories] = useState(0);
    const [busy, setBusy] = useState(true);

    // useEffect(() => {
    //     // don't display this page if the user has already configured their data scope even if the url is explicitly entered
    //     if (user?.dataScopeConfigured) {
    //         navigate('/dashboard', {replace: true});
    //     }
    // }, [user]);

    useEffect(() => {
        setCategories(currentCategories);
        setNonEditableCategories(currentCategories);
        fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchCategories() {
        setBusy(true);
        try {
            const {categories: categoryUsage = {}} = await getMySubscriptionUsage();
            setMaxCategories(categoryUsage.limit ?? 0);
        } catch (err) {
            console.error(err);
        } finally {
            setBusy(false);
        }
    }

    function cancel() {
        navigate(-1);
    }

    async function save() {
        try {
            setBusy(true);
            await updateCategories(categories);
            navigate(-1);
        } catch (err) {
            // todo: add handling for the various error cases
            switch (err.code) {
                case 'company_not_found':
                    // this should never happen except maybe in the lower environments
                    await openErrorModal({
                        title: `Company not found`,
                        content: (
                            <>
                                <p>
                                    We couldn't find your company, so we were unable to save you choices at this time.
                                </p>
                                <p>Please try again later or contact customer support.</p>
                            </>
                        ),
                        errorId: err.id
                    });
                    break;
                default:
                    openErrorModal({
                        title: 'Failed To Save Your Choices',
                        content: (
                            <>
                                <p>Some unexpected happened while saving your choices.</p>
                                <p>Our support time is looking into the issue.</p>
                                <p>Please contact customer support if you have any questions.</p>
                            </>
                        ),
                        errorId: err.errorId
                    });
            }
        } finally {
            setBusy(false);
        }
    }

    function handleOptionClick(value, categories) {
        let uniqueCategories = [...new Set(categories)];
        uniqueCategories.forEach((item, index) => {
            if (item === value) {
                uniqueCategories.splice(index, 1);
            }
        });
        let editableCategories = uniqueCategories.filter((item) => !uneditableCategories.includes(item));
        let tempCategoryOption = uniqueCategories.map((option) => {
            const {value = option, display = option} = option;
            if (canEditCategories) {
                return (
                    <div className={'configure-scope-wrapper__categories-option'}>
                        <div key={value}>{display}</div>
                        <img
                            className={'configure-scope-wrapper__categories-option-close'}
                            src={Close}
                            onClick={() => handleOptionClick(option, uniqueCategories)}
                            alt="close"
                        />
                    </div>
                );
            } else {
                return (
                    <>
                        {!uneditableCategories.includes(option) && (
                            <div className={'configure-scope-wrapper__categories-option'}>
                                <div key={value}>{display}</div>
                                <img
                                    className={'configure-scope-wrapper__categories-option-close'}
                                    src={Close}
                                    onClick={() => handleOptionClick(option, uniqueCategories)}
                                    alt="close"
                                />
                            </div>
                        )}
                    </>
                );
            }
        });
        if (editableCategories.length === 0 && !canEditCategories) {
            tempCategoryOption = [];
        }
        setCategories(uniqueCategories);
        updateCategoryOption(tempCategoryOption);
    }

    function updateProductScope(data) {
        data.forEach((item) => {
            categories.push(item);
        });
        let uniqueCategories = [...new Set(categories)];
        let tempCategoryOption =
            uniqueCategories.length === 0
                ? []
                : uniqueCategories.map((option) => {
                    const {value = option, display = option} = option;
                    if (canEditCategories) {
                        return (
                            <div className={'configure-scope-wrapper__categories-option'}>
                                <div key={value}>{display}</div>
                                <img
                                    className={'configure-scope-wrapper__categories-option-close'}
                                    src={Close}
                                    onClick={() => handleOptionClick(option, uniqueCategories)}
                                    alt="close"
                                />
                            </div>
                        );
                    } else {
                        return (
                            <>
                                {!uneditableCategories.includes(option) && (
                                    <div className={'configure-scope-wrapper__categories-option'}>
                                        <div key={value}>{display}</div>
                                        <img
                                            className={'configure-scope-wrapper__categories-option-close'}
                                            src={Close}
                                            onClick={() => handleOptionClick(option, uniqueCategories)}
                                            alt="close"
                                        />
                                    </div>
                                )}
                            </>
                        );
                    }
                });
        setCategories(uniqueCategories);
        updateCategoryOption(tempCategoryOption);
        updateshowProductScopeModal(false);
    }

    function renderModal() {
        if (showProductScopeModal) {
            return (
                <Modal
                    visible={showProductScopeModal}
                    closable={false}
                    width={'60vw'}
                    footer={null}
                    bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    <ProductScopeNew
                        hideProductScopeModal={() => updateshowProductScopeModal(false)}
                        updateProductScope={(data) => updateProductScope(data)}
                        maxCategoryCount={maxCategories - categories.length}
                        errorMessage={
                            'Purchase a Subscription to choose more categories or unselect the previously selected category.'
                        }
                        categoryData={categories}
                        nonEditableCategories={canEditCategories ? [] : nonEditableCategories}
                    />
                </Modal>
            );
        }
    }

    function onSelectionChange(categories) {
        setCategories(categories);
    }

    return (
        <DashboardContent title={'Edit Your Categories'} className={classnames(`${baseClassName}`, {
            [`${baseClassName}--loading`]: busy
        })}>
            <ByzzerMask loading={busy}/>
            <CategorySelector
                maxCategoryCount={maxCategories}
                // openConfirmation={openConfirmation}
                categories={categories}
                onSelectionChange={onSelectionChange}
                uneditableCategories={uneditableCategories}
                // productScopeTitle={'Set categories'}
                productScopeSubtitle={`Your subscription includes ${maxCategories} categories. You can add-on more in the shop.`}
                submitButtonLabel={'Add'}
                displayTreeButtonLabel={'Confirm categories'}
            />
            <div className={`${baseClassName}__actions`}>
                <ByzzerButton onClick={cancel} type={'negative'}>Cancel</ByzzerButton>
                <ByzzerButton onClick={save}>Save Changes</ByzzerButton>
            </div>
        </DashboardContent>
    );
};
/*
x = <>
    <header className={'setup-header'}>Set up your package</header>
    <main className={'setup-content'}>
        <div className={wrapperClasses}>
            <ByzzerMask loading={loading}/>
            <span className="configure-scope-wrapper__heading">Select your subscription categories</span>
            {productActionQuantity - categoryList.length > 0 && (
                <span className="configure-scope-wrapper__sub-heading">
                            You can select {productActionQuantity - categoryList.length} more categories for your
                            subscription. You can add-on more in the Shop.
                        </span>
            )}
            <>
                <label className="configure-scope-wrapper__label">Select Categories:</label>
                <div
                    className={
                        categoryOption.length > 0
                            ? 'configure-scope-wrapper__categories-selected'
                            : 'configure-scope-wrapper__categories'
                    }
                >
                    <div className={'configure-scope-wrapper__categories-options'}>
                        {categoryOption.length > 0 && (
                            <div className={'configure-scope-wrapper__categories-options-scrollarea'}>
                                {categoryOption}
                            </div>
                        )}
                        {categoryOption.length === 0 && (
                            <div
                                className={'configure-scope-wrapper__categories-options-scrollarea-label'}
                                onClick={() => updateshowProductScopeModal(true)}
                            >
                                Choose a category
                            </div>
                        )}
                    </div>
                </div>
                <img
                    src={EditIcon}
                    alt="edit"
                    style={{ marginTop: '20px', cursor: 'pointer' }}
                    onClick={() => updateshowProductScopeModal(true)}
                />
            </>
        </div>
    </main>
    <footer className={'setup-footer'}>
        <ByzzerButton
            disabled={!categoryList.length}
            onClick={save}
            className="configure-scope__button-btn"
            label={`Confirm Data Context`}
        />
    </footer>
    {renderModal()}
</>
*/
export default CategoryEditor;
