import { useUser } from '@/contexts/UserContext';
import HomePage from '../HomePage/HomePage';
import MyHomePage from '../MyHomePage/MyHomePage';
import { HomepageContextProvider } from '@/contexts/HomepageContext';
import { MyHomepageContextProvider } from '@/contexts/MyHomePageContext';

export default function HomePageConfigEditor({ ...props }) {
    const { subscription } = useUser();

    return (
        <>
            {subscription?.metadata?.isFree ? (
                <MyHomepageContextProvider>
                    <MyHomePage className={undefined} />
                </MyHomepageContextProvider>
            ) : (
                <HomepageContextProvider>
                    <HomePage className={undefined} />
                </HomepageContextProvider>
            )}
        </>
    );
}
