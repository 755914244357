import React, {ReactNode,useEffect, useState} from 'react';
import './CharEditor.scss';
import classNames from "classnames";
import {useTenantApi} from '@/hooks/useTenantApi';
import {useUser} from '@/contexts/UserContext';
import { FixIssuesStep } from '@/views/CustomCharacteristics/editor/FixIssuesStep';
import { create as createModal } from "react-modal-promise";
import { ByzzerModalMask, openErrorModal } from "@/components/form/ByzzerModal";
import { PreUploadStep } from "@/views/CustomCharacteristics/editor/PreUploadStep";
import { UploadStep } from "@/views/CustomCharacteristics/editor/UploadStep";
import { ConfigureStep } from "@/views/CustomCharacteristics/editor/ConfigureStep";
import { ChooseEditActionStep } from "@/views/CustomCharacteristics/editor/ChooseEditActionStep";
import { NameChar } from "@/views/CustomCharacteristics/editor/NameChar"
import ByzzerModal2 from '@/components/modals/ByzzerModal2';
import { CustomCharValueGroupProperties } from '@/types/ApiTypes';

export function CharEditor({ charId, onResolve, allowUpload = true, updateCustomCharData }) {
    const { checkUpcsInCategories,    createCustomChar,    getCustomChar,    updateCustomChar, renameCustomChar } = useTenantApi();
    const [currentStep, setCurrentStep] = useState(allowUpload ? 'pre-upload' : 'configure');
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [categories, setCategories] = useState<any[]>([]);
    const [uploadHeading,setUploadHeading] = useState('Make sure a UPC-upload is the right choice');
    const [productHierarchy, setProductHierarchy] = useState<{
        departments: string[];
        supercategories: string[];
        categories: string[];
        totalStore: string[];
    }>({
        departments: [],
        supercategories: [],
        categories: [],
        totalStore: []
    });
    const [upcGroups, setUpcGroups] = useState<CustomCharValueGroupProperties[]>([]);
    const [busy, setBusy] = useState(false);
    const [heading, setHeading] = useState<ReactNode>(<>&nbsp;</>);
    const [charErrorList, setCharErrorList] = useState<any[]>([]);
    const [upcs, setUpcs] = useState<any[]>([]);
    const [issues, setIssues] = useState<any>([]);
    const [showingErrorModal, setShowingErrorModal] = useState(false);
    const [renameMode, setRenameMode] = useState(false);
    const [segment, setSegment] = useState<any>([])
    const [char, setChar] = useState<any>();
    const [isInvalidUPC, setIsInvalidUPC] = useState(false);
    const [proceed, setProceed] = useState(false);
    const classes = classNames(
        'byzzer-modal-wrapper',
        'char-editor-wrapper', {
        'char-editor-wrapper--with-error-modal': showingErrorModal
    }
    );

    const userData = useUser();
    // todo: move this to a global state there is no point in loading this everytime the component loads
    useEffect(() => {
        if (charId) {
            setCurrentStep('choose-edit');
            loadChar(charId);
        }
        else {
            setCurrentStep(allowUpload ? 'pre-upload' : 'configure');
        }

    }, []);

    // useEffect(() => {
    //     if (charId) {
    //         setMode('edit');
    //         setCurrentStep('choose-edit');
    //         loadChar(charId);
    //     }
    //     else {
    //         setMode('create')
    //     }
    // }, [charId]);


    useEffect(() => {
        if (char) {
            if (char.type === 'Characteristics') {
                let segmentsToEdit: any[] = []
                char.segments.forEach(segment => {
                    let char: any[] = []
                    segment.criteria.forEach((criteria, index) => {
                        if (index === 0) {
                            criteria.forEach((item, index) => {
                                if (index === 0) {
                                    char.push({ characteristic: item.chrCode, condition: item.comparator === "<>" ? "is not" : "is", value: item.value })
                                }
                                else {
                                    char.push({ characteristic: item.chrCode, condition: item.comparator === "<>" ? "is not" : "is", value: item.value, and: "and" })
                                }
                            })
                        }
                        else {
                            criteria.forEach((item, index) => {
                                if (index === 0) {
                                    char.push({ characteristic: item.chrCode, condition: item.comparator === "<>" ? "is not" : "is", value: item.value, and: "or" })
                                }
                                else {
                                    char.push({ characteristic: item.chrCode, condition: item.comparator === "<>" ? "is not" : "is", value: item.value, and: "and" })
                                }
                            })
                        }
                    })
                    segmentsToEdit.push({ name: segment.name, char: char })
                })
                setSegment(JSON.parse(JSON.stringify(segmentsToEdit)))
            }
            setCategories(char.categories);
            setTitle(char.label);
        }
    }, [char])

    async function enableUploadStep(allowPreUpload) {
        if (
            await allowPreUpload== true
        ){
           setUploadHeading('Upload Custom Characteristic');
        }
    }

    useEffect(() => {
        let heading: ReactNode = <>&nbsp;</>
        switch (currentStep) {
            case 'pre-upload':
                heading = uploadHeading;
                break;
            case 'upload':
                heading = 'Upload Custom Characteristic';
                break;
            case 'configure':
                heading = 'Create Custom Characteristic';
                break;
            case 'name':
                heading = renameMode ? 'Rename Custom Characteristic' : 'Characteristic Definition is uploaded';
                break;
            case 'errors':
                heading = 'We Found Some Issues';
                break;
            case 'choose-edit':
                heading = char?.label ?? <>&nbsp;</>
                break;
            default: break;
        }
        setHeading(heading);
    }, [currentStep, char, uploadHeading]);

    async function loadChar(id) {
        setLoading(true)
        try {
            const charData = await getCustomChar(id);
            if (charData.id === id) {
                setChar(charData)
            }
        } catch (err: any) {
            onResolve(false);
            openErrorModal({
                title: `Uh Oh! Something Unexpected Happened`,
                content: <>
                    <p>Fear not our engineering team is on the job.</p>
                </>,
                errorId: err.id
            })
        } finally {
            setLoading(false)
        }

    }

    const isComma = (containsComma) => {
        setIsInvalidUPC(containsComma)
    }

    async function detectIssues(categories, upcs) {
        // todo: properly handle errors
        try {

            const issues: any[] = [];
            const {upcGroups, corrections, badUpcs} = validateAndGroup(upcs);
            // todo: deal with the possibility of this call failing
            if ((upcGroups.map(({ upcs }) => upcs).flat()).length > 0) {
                const notFound = await checkUpcsInCategories({
                    categories,
                    upcs: upcGroups.map(({ upcs }) => upcs).flat()
                });

                if (isInvalidUPC) {
                    setProceed(false);
                    issues.push({
                        type: 'badUpc',
                        message: 'UPC column must be formatted as integers. Please update your file and re-upload.',
                    });
                }
                if (badUpcs.length) {
                    setProceed(false);
                    issues.push({
                        type: 'badUpc',
                        message: 'UPC column must be formatted as integers. Please update your file and re-upload.',
                        data: badUpcs
                    });
                }
                if (corrections.length) {
                    setProceed(false);
                    issues.push({
                        type: 'check-digit',
                        message: `We found check-digits in ${corrections.length} of the UPCs in your upload, so we removed them.`,
                        data: corrections
                    });
                }
                if (notFound.length) {
                    if (upcs.length / 2 <= notFound.length) {
                        setProceed(false);
                        issues.push({
                            type: 'upc-category',
                            message: `${notFound.length} UPCs in your upload are not in the selected category. Fix your file or your category selections and try again.`,
                            data: notFound
                        });
                    }
                    if (upcs.length / 2 > notFound.length) {
                        issues.push({
                            type: 'upc-category',
                            message: `${notFound.length} UPCs in your upload are not in the selected category. If you proceed, those UPCs will be removed from the Characteristics.`,
                            data: notFound
                        });

                        //check if notFound && upcGroup includes same UPCs : if yes remove notFound UPCs & setUpcGroups()
                        let upcGroupsTemp = upcGroups;
                        notFound.map((notFoundUpc) => {
                            upcGroupsTemp.map((upcGroup) => {
                                let upcTemp: any[] = []
                                upcGroup.upcs.map((upc) => {
                                    if (upc !== notFoundUpc.upc)
                                        upcTemp.push(upc)
                                })
                                upcGroup.upcs = upcTemp
                            })
                        })
                        upcGroupsTemp = upcGroupsTemp.filter(upcGroup => upcGroup.upcs?.length > 0)
                        setProceed(true);
                        setUpcGroups(upcGroupsTemp)

                    }
                }
            }
            else {
                issues.push({
                    type: 'badUpc',
                    message: 'UPC column must be formatted as integers. Please update your file and re-upload.',
                });
                setCurrentStep('errors');
            }
            return issues;
        } catch (err) {

        }
    }

    function validateAndGroup(records) {

        // group upcs based on group name and remove duplicates
        const upcsByGroupName = records.reduce((groups, { upc, group }) => {
            let grpName = group?.trim();
            groups[grpName] = groups[grpName] ?? new Set();
            groups[grpName].add(upc)
            return groups
        }, {});

        // convert the map into an array of objects
        const upcGroups: any[] = [];
        const allCorrections: any[] = [];
        const allBadUpcs: any[] = [];

        Object.entries(upcsByGroupName).forEach(([name, upcs]) => {

            const { cleanUpcs, corrections, badUpcs } = cleanseUpcs(upcs)
            allCorrections.push(...corrections);
            allBadUpcs.push(...badUpcs);

            upcGroups.push({
                value: name,
                upcs: cleanUpcs
            })
        });

        // make sure the upcs are mutually exclusive to a group
        const allUpcs = upcGroups.map(({ upcs }) => upcs).flat();
        const crossGroupUpcs = allUpcs.filter(upc => allUpcs.filter(v => upc === v).length > 1)
        if (crossGroupUpcs.length) {
            // todo: handle this error condition
            var temp = charErrorList;
            temp.push({
                type: 'crossGroupUpcs',
                message: 'Same UPCs are present in multiple Characteristics. Please update your file and re-upload.',
                data: crossGroupUpcs,
            });
            setCharErrorList(temp);
        }

        setUpcGroups(upcGroups);

        return {
            upcGroups,
            corrections: allCorrections,
            badUpcs: allBadUpcs
        };
    }

    function cleanseUpcs(upcs) {

        const corrections: any[] = [];
        const badUpcs: any[] = [];
        const cleanUpcs: any[] = Array.from(upcs)
            .filter((v: any) => {
                if (/\D/.test(v)) {
                    badUpcs.push(v);
                    return false;
                }
                return true;
            });

        return {
            cleanUpcs,
            corrections,
            badUpcs
        };
    }

    const onFixIssuesComplete = (action) => {

        switch (action) {
            case 'discard':
                setCurrentStep('name');
                break;
            case 're-upload':
                setCurrentStep('upload');
                setIssues([]);
                break;
            default: break;
        }
    }

    function onUpcsChange(upcs) {
        setUpcs([...upcs]);
    }

    function onCategoriesChange(categories) {
        setCategories([...categories]);
    }

    function onCloseClick() {
        onResolve(false);
    }

    function onActionClick(action) {
        switch (action) {
            case 'showGuidelines':
                setCurrentStep('pre-upload');
                break;
            default:
                break;
        }
    }

    function onPreUploadStepComplete() {
        setCurrentStep('upload');
    }

    async function onUploadStepComplete() {

        try {
            setBusy(true)
            const issues = await detectIssues(categories, upcs);

            if (issues?.length) {
                setHeading(<>&nbsp;</>);
                setCurrentStep('errors');
                setIssues(issues);
            } else {
                setCurrentStep('name');
            }
        } catch (err) {

        } finally {
            //setBusy(false);
        }

        try {
            setBusy(true);
        } finally {
            setBusy(false);
        }
    }

    function onChooseEditActionComplete(action) {

        switch (action) {
            case 'rename':
                setRenameMode(true);
                setCurrentStep('name');
                break;
            case 'upload-upcs':
                setCurrentStep('upload');
                break;
            case 'change-rules':
                setCurrentStep('configure');
                break;
            case 'remove-upcs':
                setUpcGroups([]);
                setCurrentStep('configure');
                break;
            default: break;
        }
    }

    async function useCharacteristicError(errorTitle) {
        setShowingErrorModal(true);
        await openErrorModal({
            title: `Segment Name Already Used`,
            content: <>
                <p>
                    You already have a Segment with the title, {errorTitle}.
                </p>
            </>
        });
        setShowingErrorModal(false);
    }

    async function onConfigureStepComplete(segment, categories, title) {
        try {
            setBusy(true);
            let criteria: any[] = [];
            segment.forEach(item => {
                let eachSegment: any[] = [];
                let segmentDefination: any[] = [];
                item?.char.forEach(char => {
                    if (char?.and) {
                        if (char?.and === 'and') {
                            segmentDefination.push({
                                chrCode: char?.characteristic,
                                value: char?.value,
                                comparator: char?.condition === "is" ? "=" : "<>"
                            })
                        }
                        else {
                            eachSegment.push(segmentDefination);
                            segmentDefination = [];
                            segmentDefination.push({
                                chrCode: char?.characteristic,
                                value: char?.value,
                                comparator: char?.condition === "is" ? "=" : "<>"
                            })
                        }
                    }
                    else {
                        segmentDefination.push({
                            chrCode: char?.characteristic,
                            value: char?.value,
                            comparator: char?.condition === "is" ? "=" : "<>"
                        })
                    }
                })
                if (segmentDefination?.length) {
                    eachSegment.push(segmentDefination);
                }
                criteria.push({ name: item.name, criteria: eachSegment })
            })
            let requestObject = {
                label: title,
                type: "Characteristics" as const,
                categories,
                segments: criteria,
                hierarchy: {...productHierarchy}
            }
            if (charId) {
                await updateCustomChar(charId, requestObject);
            }
            else {
                let customChar = await createCustomChar(requestObject);

                let customCharObject = {
                    id: customChar.id,
                    label: title,
                    type: "Characteristics" as const,
                    isEditable: true,
                    createdDtm: new Date().toISOString(),
                    updatedDtm: new Date().toISOString(),
                    categories: categories,
                    createdBy: {id: userData?.user?.id, firstName: userData?.user?.firstName, lastName: userData?.user?.lastName, email: userData?.user?.email}
                }

                updateCustomCharData?.(customCharObject);   

            }
            onResolve(true);
        } catch (err: any) {
            setShowingErrorModal(true);
            switch (err.code) {
                case 'duplicate_custom_char_label':
                    await openErrorModal({
                        title: `Title Already Used`,
                        content: <>
                            <p>
                                You already have a Characteristic with the title, {title}.
                            </p>
                        </>,
                        actions: [{
                            label: 'Rename & Save',
                            key: 'renameAndSave',
                            action({resolve}) {
                                resolve('rename and save')
                            } 
                        }]
                    });
                    break;
                default:
                    await openErrorModal({
                        title: `Uh Oh! Something Unexpected Happened`,
                        content: <>
                            <p>Fear not our engineering team is on the job.</p>
                        </>,
                        errorId: err.id
                    });
            }
            setShowingErrorModal(false);
            setBusy(false);
        }
    }

    async function onNameStepComplete() {
        let editedUpcGroup: CustomCharValueGroupProperties[] = [];
        if (renameMode && char.type === 'UPC') {
            char.groups.forEach(group => {
                editedUpcGroup.push({ value: group.name, upcs: group.upcs })
            })
        }
        try {
            setBusy(true);
            if (renameMode) {
                await renameCustomChar(charId, title);
                onResolve(true);
                return;
            }
            if (charId) {
                if (char.type === 'UPC') {
                    await updateCustomChar(charId, {
                        label: title,
                        type: "UPC",
                        categories,
                        groups: !renameMode ? upcGroups : editedUpcGroup,
                        hierarchy: {...productHierarchy}
                    });
                }
                else {
                    await updateCustomChar(charId, {
                        label: title,
                        type: "Characteristics",
                        categories: categories,
                        segments: char.segments,
                        hierarchy: {...productHierarchy},
                    });
                }
            } else {
                await createCustomChar({
                    label: title,
                    type: "UPC",
                    categories,
                    groups: upcGroups,
                    hierarchy: {...productHierarchy}
                });
            }
            onResolve(true);
        } catch (err: any) {
            setShowingErrorModal(true);
            switch (err.code) {
                case 'duplicate_custom_char_label':
                    await openErrorModal({
                        title: `Title Already Used`,
                        content: <>
                            <p>
                                You already have a Characteristic with the title, {title}.
                            </p>
                        </>
                    });
                    break;
                default:
                    await openErrorModal({
                        title: `Uh Oh! Something Unexpected Happened`,
                        content: <>
                            <p>Fear not our engineering team is on the job.</p>
                        </>,
                        errorId: err.id
                    });
            }
            setShowingErrorModal(false);

            setBusy(false);
        }
    }

    function renderStep() {

        switch (currentStep) {
            case 'choose-edit':
                return <ChooseEditActionStep char={char} busy={busy} onComplete={onChooseEditActionComplete} />
            case 'upload':
                //
                return <UploadStep categories={categories}
                                   upcs={upcs}
                    onUpcsChange={onUpcsChange}
                    onCategoriesChange={onCategoriesChange}
                    onComplete={onUploadStepComplete}
                    busy={busy}
                    isComma={isComma}
                    setLoading={setLoading}
                    onActionClick={onActionClick}
                    setProductHierarchy={setProductHierarchy} />
            case 'configure':
                return <ConfigureStep categories={categories}
                    segment={segment}
                    title={title}
                    busy={busy}
                    onTitleChange={setTitle}
                    onCategoriesChange={onCategoriesChange}
                    onComplete={onConfigureStepComplete}
                    setLoading={setLoading}
                    error={useCharacteristicError}
                    setProductHierarchy={setProductHierarchy}
                />
            case 'name':
                return <NameChar
                    renameMode={renameMode}
                    title={title}
                    busy={busy}
                    onTitleChange={setTitle}
                    onComplete={onNameStepComplete} />
            case 'errors':
                return <FixIssuesStep issues={issues}
                    onComplete={onFixIssuesComplete} proceed={proceed} />
            case 'pre-upload':
            default:
                return <PreUploadStep uploadHeading={uploadHeading} enableUploadStep={enableUploadStep} onComplete={onPreUploadStepComplete} busy={busy} />
        }
    }

    return (
        <div className={classes}>
            {currentStep !== 'configure' ?
                <div className="char-editor">
                    <header className={'char-editor-header'}>
                        <h1 className={'char-editor-header__title'}>{heading}</h1>
                        <span className={'char-editor-header__close'} onClick={onCloseClick} />
                    </header>
                    <main className={'char-editor-content'}>
                        {/* @ts-ignore */}
                        <ByzzerModalMask show={loading}>{loading?.message}</ByzzerModalMask>
                        {renderStep()}
                    </main>
                </div>
                :
                // @ts-ignore
                <ByzzerModal2 className={'char-builder'}>
                    <ByzzerModal2.Header className={'char-builder__header'} onClose={onCloseClick}>
                        {heading}
                        <p className={'char-builder__subtitle'}>
                            Use custom characteristics to create new segments of the data that apply uniquely to your business.
                        </p>
                    </ByzzerModal2.Header>
                    <ByzzerModal2.Content className={'char-builder__content'}>
                        {/* @ts-ignore */}
                        <ByzzerModalMask show={loading}>{loading?.message}</ByzzerModalMask>
                        {renderStep()}
                    </ByzzerModal2.Content>
                </ByzzerModal2>
            }
        </div>
    );
}
                                        // @ts-ignore
export const openCharEditor = createModal(CharEditor);
