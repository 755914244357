/* eslint-disable react-hooks/rules-of-hooks */
import './AlertRunConfigWizard.scss';
import React, {ReactNode, useCallback, useContext, useEffect} from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import {TabWizard, AccordionWizard} from '@byzzer/ui-components';
import {AlertRunConfigSummaryStep} from '../AlertRunConfigSummaryStep';
// import {AlertConfigOptionsType, AlertRunConfigOptions, RunConfigOptions} from '@/types/RunConfigOptions';
import {AlertConfigOptionsType, AlertRunConfigOptions } from '@/types/AlertRunConfigOptions';
import {
    ProductRunConfigFiltersStep,
    MarketRunConfigFiltersStep,
    MarketsToWatchRunConfigFiltersStep,
    RecipientsRunConfigFiltersStep
} from '@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigFilters';
import {AlertRunConfig} from '@/types/AlertRun';
import {
    AlertRunConfigWizardContext,
    AlertRunConfigWizardContextValue,
} from '@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useBetterNavigate} from '@/utils';
import {useApp, useUser} from '@/contexts/UserContext';
import {useReportRunService} from "@/services/reportRun.service";
import {isMarketDisabled, useMarketService} from "@/services/market.service";
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { RunConfigMarket } from '@/types/ReportRun';
import { MarketPickerContext } from '@/components/MarketPicker';

export type AlertRunConfigWizardProps = {
    className?: string;
    runType?: RunType;
    sku?: string;
    runConfigOptions?: AlertRunConfigOptions[];
    defaultValues?: Partial<AlertRunConfig>;
    onComplete?: (runConfig: AlertRunConfig) => void;
    busy?: boolean;
    busyCompleteButtonText?: string;
    busyCompleteButtonTip?: ReactNode;
    editingExistingAlert?: boolean;
};

const baseClassName = 'report-run-config-wizard';

const FILTER_BY_OPTION_TYPE: Record<AlertConfigOptionsType, any> = {
    product: ProductRunConfigFiltersStep,
    market: MarketRunConfigFiltersStep,
    markets_to_watch: MarketsToWatchRunConfigFiltersStep,
    recipients: RecipientsRunConfigFiltersStep
};

export function AlertRunConfigWizard({
    className,
    runType,
    sku,
    runConfigOptions,
    defaultValues,
    onComplete,
    busy,
    busyCompleteButtonText,
    busyCompleteButtonTip,
    editingExistingAlert,
    ...props
}: AlertRunConfigWizardProps) {
    if (!runConfigOptions) return null;
    const {
        user,
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
        company,
    } = useUser();
    const { clearMarketCache,getCachedMarketNodeByName } = useMarketService();
    const {createAdHicReportRun, createAlerts, createSubscriptionReportRun, updateAlerts} = useTenantApi();
    const [loading, setLoading] = useState<boolean>(false);
    const { requiredMasterCompany, requiredMarketGroup, requireRemainingMarket } = useContext(MarketPickerContext);
    const [contextValue, setContextValue, contextRef] = useState<AlertRunConfigWizardContextValue>({
        value: {
            productSelections: [],
            characteristics: [],
            markets: [],
            categoryAlias: '',
            marketsToWatch: undefined,
            notification: {
                recipientType: 'just-me',
                recipientEmails: [user?.email],
            },
            focusBrands: [],
            categories: []
        },
        runType,
        sku,
        onChange(name: keyof AlertRunConfig, value: any): void {

            // do not inline this logic.
            const shouldResetMarkets = name === 'productSelections' || name === 'categories';

            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    markets: shouldResetMarkets ? [] : current.value.markets,
                    [name]: value,
                },
            }));
        },
    });

    useEffect(() => {
        // clean up the market cache when the wizard unmounts
        return clearMarketCache;
    }, []);

    useEffect(() => {
        if (defaultValues?.markets) {
            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    ...defaultValues,
                    markets: defaultValues?.markets!?.map((item) => {
                        return {
                            ...(item as RunConfigMarket),
                            disabledMarketNode:isMarketDisabled(
                                getCachedMarketNodeByName(item.name, defaultValues.categories)!,
                                {
                                    requiredMarketGroup,
                                    requiredMasterCompany,
                                    requireRemainingMarket,
                                    enableLimitedMarketSubscription,
                                    accessibleMasterCompanies,
                                    purchasedMarketKeys:company?.purchasedMarketKeys,                             
                                }
                            ),
                        };
                    }),
                },
            }));
        }
    }, [defaultValues]);

    useEffect(() => {
        // console.log(`AlertRunConfigWizard - useEffect / 'contextValue.value' updated ===>> `, contextValue.value);
    }, [contextValue.value])

    const handleComplete = useCallback(async () => {
        const value = contextRef.current.value;
        if (!value) return;

        onComplete?.(value);
    }, [contextValue]);

    const Wizard = window.localStorage['wizard-mode'] === 'accordion' ? AccordionWizard : TabWizard;

    return (
        <AlertRunConfigWizardContext.Provider value={contextValue}>
            <ByzzerMask loading={loading}/>
            <Wizard className={classnames(baseClassName, className)}>
                {runConfigOptions.map((options, i) => {
                    const Step = FILTER_BY_OPTION_TYPE[options.type];
                    return <Step key={`${options.type}${i}`} {...options}/> // DO NOT WRAP WITH ANYTHING, even fragments; IT WILL BREAK CONTEXT
                })}
                <AlertRunConfigSummaryStep 
                    onNext={handleComplete}
                    runConfigOptions={runConfigOptions}
                    busy={busy}
                    busyNextText={busyCompleteButtonText}
                    busyNextTooltip={busyCompleteButtonTip}
                    sku={sku}
                    editingExistingAlert={editingExistingAlert}
                />
            </Wizard>
        </AlertRunConfigWizardContext.Provider>
    );
}

export default AlertRunConfigWizard;

AlertRunConfigWizard.displayName = 'AlertRunConfigWizard';
