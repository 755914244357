import {
    addMinutes,
    addWeeks,
    differenceInCalendarDays,
    differenceInDays,
    eachWeekendOfMonth,
    eachWeekOfInterval,
    endOfQuarter,
    format,
    getMonth,
    getYear,
    isSaturday,
    lastDayOfMonth,
    nextSaturday,
    subWeeks
} from 'date-fns';
import {convertToUtc, getWeekCount} from '@/utils/timeperiodUtil';
import {timePeriodOptionsForFolder} from "@/config/globalVars";

/**
 * to check permissions for given segment / access level
 * @param {*} segment array of string
 * response
 * 2 for retailer
 * 3 for allelse
 * 4 for unknown
 */
export const checkUserSegmentPermissions = (userSegment) => {
    if (['retailers', 'retailer'].includes(userSegment)) {
        return 2;
    } else if (['all alse', 'all else', 'all_else'].includes(userSegment)) {
        return 3;
    } else if (['unknown'].includes(userSegment)) {
        return 4;
    }
    return 0;
}

export const ExtractConstants = {
    SUM_SELECTION: 'Sum Selections',
    CANCEL: 'Cancel'
}

export const getDateFromISOString = (isoString) => {
    const d = new Date(isoString.split('T')[0]);
    let day = d.getUTCDate();
    let mon = d.getUTCMonth();
    let yr = d.getUTCFullYear();
    let resp = format(new Date(yr, mon, day), 'yyyy-MM-dd').toString();
    return resp;
}
/**
 *
 * @param {*} cadenceWeeksInMonth 4 | 5
 * @param {*} dateProp date object
 * @returns
 */
const fetchDateBasedOnCadence = (cadenceWeeksInMonth, dateProp) => {
    // cadenceWeeksInMonth will be 4 or 5, based on month it will be passed, like Jan:4, Feb:4 and March:5.
    const allWeeksInAMonth = eachWeekendOfMonth(dateProp);
    // getting all saturdays for current month
    const weeksInAMonth = allWeeksInAMonth.filter((val1, indx) => {
        return isSaturday(val1)
    });
    // last saturday for the month
    const lastSaturday = weeksInAMonth[weeksInAMonth.length - 1];
    // last day of month
    const lastDayOfAMonth = lastDayOfMonth(dateProp);
    // difference between last saturday of month and last day of month
    const differenceBetweenLastSaturdayAndLastDay = differenceInCalendarDays(lastDayOfAMonth, lastSaturday);

    // if number of weeks in a month greater than or equal to cadenceWeeksInMonth and
    // difference between last saturday of month and last day of month  is less than 5
    // it will return last saturday of month
    // else it will return next saturday to the last saturday of present month
    if(weeksInAMonth.length >= cadenceWeeksInMonth && (differenceBetweenLastSaturdayAndLastDay < 5)){
        return lastSaturday;
    }else {
        return nextSaturday(lastSaturday)
    }
}
/**
 *
 * @param {*} dateProp dateObj
 * @param {*} cadence '4-4-5'
 * mostly cadence will be '4-4-5',
 * @returns
 */
export const calculateDateCadence = (dateProp, cadence = '4-4-5') => {
    const  splitCadence = cadence.split('-');
    // fetch month value of a date
    const monthNumber = getMonth(dateProp);

    // Jan, April, Jul, Oct
    const firstMonthsOfEachCadence = [1,4,7,10];
    // Feb, May, Aug, Nov
    const secondMonthsOfEachCadence = [2,5,8,11];
    // Mar, Jun, Sep, Dec
    const thirdMonthsOfEachCadence = [3,6,9,12];

    // if given date is in Jan, April, Jul, Oct, then we will pass 4 to the cadence logic
    if(firstMonthsOfEachCadence.includes(monthNumber+1)){
        return fetchDateBasedOnCadence(parseInt(splitCadence[0]), dateProp)
    }
    // if given date is in Feb, May, Aug, Nov then we will pass 4 to the cadence logic
    if(secondMonthsOfEachCadence.includes(monthNumber+1)){
        return fetchDateBasedOnCadence(parseInt(splitCadence[1]), dateProp)
    }
    // if given date is in Mar, Jun, Sep, Dec then we will pass 5 to the cadence logic
    if(thirdMonthsOfEachCadence.includes(monthNumber+1)){
        return fetchDateBasedOnCadence(parseInt(splitCadence[2]), dateProp)
    }
}
export const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getTimePeriodsToDisplay = (arr, key, yrs, maxDate) => {
    let currentSelectionDataTemp = arr?.map((val, index) => {
        let customVal = val;
        let mainVal;
        let currentValue;
        let currWeekCount;
        if(val?.month === undefined){
            currentValue = convertToUtc(val);
            currWeekCount = getWeekCount(currentValue);
        }else {
            currWeekCount = (val.month + 1) % 3 === 0 ? 5 : 4
        }
        if (key === '1') {
            // var endDate = new Date(maxDate);
            let endDateUtc = maxDate;//new Date(addMinutes(endDate, endDate.getTimezoneOffset()));
            if (val.includes(' Year-Ago')) {
                var oneYearBackDate = subWeeks(new Date(endDateUtc), 52);
                endDateUtc = isSaturday(oneYearBackDate) ? oneYearBackDate : nextSaturday(oneYearBackDate)
            } else {
                for (let index = 1; index <= yrs; index++) {
                    if (val.includes(`${index}-Year-Ago`)) {
                        var twoYearBackDate = subWeeks(new Date(endDateUtc), index * 52);
                        endDateUtc = isSaturday(twoYearBackDate) ? twoYearBackDate : nextSaturday(twoYearBackDate);
                        break;
                    }
                }
            }
            mainVal = `${val} Ending ${format(new Date(endDateUtc), 'MM/dd/yyyy')}`;
        } else if (key === '2') {
            mainVal = `1 week ending ${val}`;
        } else if (key === '3') {
            mainVal = `4 weeks ending ${val}`;
        } else if (key === '4') {
            let lasWeek = val.date;
            customVal = format(new Date(lasWeek), 'MM-dd-yyyy');
            mainVal = `${monthList[val.month]} ${val.year} - ${currWeekCount} Weeks Ending ${format(new Date(lasWeek), 'MM/dd/yyyy')}`;
        } else if (key === '5') {
            currentValue = endOfQuarter(currentValue);

            currWeekCount = getWeekCount(currentValue);
            const getWeekendOfYear = eachWeekendOfMonth(currentValue);
            const weeksInAMonth = getWeekendOfYear.filter((val1, indx) => {
                return isSaturday(val1)
            });
            let lastSaturday = weeksInAMonth[weeksInAMonth.length - 1];
            if (weeksInAMonth.length < currWeekCount) {
                lastSaturday = addWeeks(lastSaturday, 1);
            }
            // let lastSaturday = endOfQuarter(currentValue);

            let Quarter = format(new Date(currentValue), 'QQQ');
            let QuarterWithYear = Quarter + ' ' + format(new Date(currentValue), 'yy');
            customVal = format(new Date(lastSaturday), 'MM-dd-yyyy');
            mainVal = `${QuarterWithYear} - 13 weeks ending ${format(new Date(lastSaturday), 'MM-dd-yyyy')}`;
        } else if (key === '6') {
            mainVal = format(new Date(currentValue), 'yyyy');
            const currentYear = convertToUtc(`12-01-${mainVal}`);
            const getWeekendOfYear = eachWeekendOfMonth(currentYear);
            let lastSaturday = getWeekendOfYear.filter((val1, indx) => {
                return isSaturday(val1)
            });
            lastSaturday = lastSaturday[lastSaturday.length - 1];
            const differenceOfYearDates = differenceInDays(lastDayOfMonth(lastSaturday), lastSaturday);
            if (differenceOfYearDates > 3) {
                lastSaturday = addWeeks(lastSaturday, 1);
            }
            const yearOfDate = getYear(currentValue);
            customVal = format(new Date(lastSaturday), 'MM-dd-yyyy');
            mainVal = `Full Calendar Year ${yearOfDate} - 52 Weeks Ending ${format(new Date(lastSaturday), 'MM/dd/yyyy')}`;
        }
        return { customVal, mainVal };
    }) ?? [];
    return currentSelectionDataTemp;
}

export const ScheduleDoDMessages = {
    monthlySelectionsOnly: `You included monthly periods in your DOD run. Months align to the 4-4-5 calendar, so you should choose that option to ensure 
        you get updated data with each run.`,
    weeks4SelectionsOnly: `You included rolling 4 week periods in your DOD run. 4 week periods align to the 4-4-4 calendar, so you should choose that 
        delivery cadence to ensure you never miss a week of data!`,
    monthlyAnd4weeksSelections: `You included rolling 4 week periods and monthly periods in your DOD run. Choose the Monthly (4-4-4 Cadence) if you want to 
        ensure the 4 week periods are able to be trended. Choose the Monthly (4-4-5 Cadence) if you want to ensure you receive a fully 
        updated month with each run.`,
    weeks4Selections: `You included rolling 4 week periods in your DOD run. 4 week periods align to the 4-4-4 calendar, so we recommend choosing that 
        delivery cadence to ensure you never miss a week of data!`,
    monthlySelections: `You included monthly periods in your DOD run. Months align to the 4-4-5 calendar, so we recommend choosing that option to 
        ensure all periods of data in your run update with each delivery.`,

}

const getEveryNth = (arr, rowLength, colIdx) => arr.filter(
    (_, i) => i % rowLength === colIdx
)
/**
 *
 * @param {*} maxDate
 * @param {*} weekChunkPeriod
 * @returns
 */
export const getTimeperiodsFor445Logic = (maxDate, extendedDataYears) => {
    const startDate = new Date('01/06/2018');
    var endDate = new Date(maxDate);
    var endDateUtc = new Date(addMinutes(endDate, endDate.getTimezoneOffset()));
    let allWeeksUntilMaxDate = eachWeekOfInterval({
        start: startDate,
        end: endDateUtc
      }, {
        weekStartsOn: 6
    });
    // const firstNElements = allWeeksUntilMaxDate.reverse().slice(0, extendedDataYears*52)
    return allWeeksUntilMaxDate;
}

export const getTimeperiodsForWeeks = (maxDate, weekChunkPeriod, extendedDataYears) => {
    let firstNElements = getTimeperiodsFor445Logic(maxDate, extendedDataYears).reverse().slice(0, extendedDataYears*52);
    return getEveryNth(firstNElements, weekChunkPeriod, 0);
}

const getEvery445 = (arr) => {
    let incrementalValue = 1;
    let compareRowLength = 4;
    arr.shift();
    let monthIndex = 0;
    return arr.map(
        (_, i) => {
            if((i+1) === compareRowLength){
                if(incrementalValue!== 0 && incrementalValue % 2 === 0){
                    incrementalValue = 0;
                    compareRowLength += 5;
                }else {
                    incrementalValue +=1;
                    compareRowLength += 4;
                }
                let monthTemp = getMonth(_);
                let yearTemp = getYear(_);
                if(monthTemp === 0 && format(_, 'd') < 7){
                    yearTemp = yearTemp - 1;
                }
                const returnObj = {
                    date: _, month: monthIndex, year: yearTemp, formattedDate: format(_, 'MM-dd-yyyy')
                }

                if(monthIndex === 11){
                    monthIndex = 0;
                }else {
                    monthIndex += 1;
                }
                return returnObj;
            }
            return false;
        }
    ).filter(val => val)
}

export const getTimperiodsForMonths = (maxDate, extendedDataYears) => {
    let firstNElements = getTimeperiodsFor445Logic(maxDate, extendedDataYears);
    const res = getEvery445(firstNElements);
    return res.reverse().slice(0, extendedDataYears*12);
}


export const getTimeperiodOptionsForFolder = (years = 2) => {
    let folderValues = [];
    for (let index = 0; index < years; index++) {
        timePeriodOptionsForFolder.map((val) => {
            if (index === 0) {
                folderValues.push(val);
            } else if (index === 1) {
                folderValues.push(`${val} Year-Ago`);
            } else {
                folderValues.push(`${val} ${index}-Year-Ago`);
            }
            return folderValues;
        });
    }

    return folderValues.length > 0 ? folderValues : timePeriodOptionsForFolder;
};

export const displayTimeperiodFromValue = (value, maxDate) => {
    const splitValue = value.split(":");
    const filterType = splitValue[0];
    const duration = splitValue[1];
    const offset = splitValue[2];
    switch (filterType) {
        case "1w":
            return format(subWeeks(maxDate, offset), "MM-dd-yyyy");
        case "4w":
        case "4s":
            return format(subWeeks(maxDate, offset * duration), "MM-dd-yyyy");
        case "13w":
            return format(subWeeks(maxDate, offset * duration), "MM-dd-yyyy");
        case "1q":
            // const endOfQrtrDate = endOfQuarter(utilDate.startDate);
            // const startDate = addQuarters(endOfQrtrDate, 2);
            return format(subWeeks(startDate, offset * duration), "MM-dd-yyyy");
        case "1y":
            return format(subWeeks(utilDate.startDate, offset * duration), "MM-dd-yyyy");
        default:
            break;
    }
    return splitValue;
}
