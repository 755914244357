import {useMemo} from 'react';
import {useUser} from "@/contexts/UserContext";
import {DodFilters, DodRunConfig} from '@/types/DodRun';
import {useMarketService} from './market.service';
import {DodPreset, DodPresetSummary, DodPresetType} from '@/types/ApiTypes';
import {useTenantApi} from '@/hooks';
import { isValidArray } from '@/utils';

type DodSaveSelectionValue = {
    type: 'savedSelection' // saved_selection;
    filters: Partial<DodFilters>;
}

const LEGACY_SAVED_SELECTION_TYPE_MAP: Record<string, DodPresetType> = {
    Product: 'product',
    Market: 'market',
    TimePeriod: 'time_period',
    Fact: 'fact'
}

// export type DodSavedSelectionType = 'product' | 'market' | 'time_period' | 'fact';
// export type DodSavedSelection = {
//     id: number;
//     title: string;
//     type: DodSavedSelectionType;
//     filters?: Partial<DodFilters>;
// }

export function useDodService() {

    const {getRunConfigMarketsByKeys} = useMarketService();
    const {dodPresets, defaultRunConfig = {}, reloadDodPresets} = useUser();
    const {createDodPreset, deleteDodPreset, getDodPreset} = useTenantApi();

    const dodPresetsMap = useMemo<Record<DodPresetType, (DodPresetSummary | DodPreset)[]>>(() => {

        let {product: products=[], fact: facts, time_period: timePeriods=[], market: markets=[], layout: layout = []} = dodPresets||{};

        const {
            markets: defaultMarkets = [],
            categories: defaultCategories = [],
            brands: defaultBrands = [],
            timePeriod: defaultTimePeriod
        } = defaultRunConfig;

        if (defaultBrands.length || defaultCategories.length) {
            products = [{
                id: -1,
                type: 'product',
                displayName: `My Default Products`,
                values: {
                    brands: {
                        values: defaultBrands,
                        summedSelections: []
                    },
                    categories: {
                        values: defaultCategories,
                        summedSelections: []
                    },
                    departments: {
                        values: [],
                        summedSelections: [],
                    },
                    superCategories: {
                        values: [],
                        summedSelections: [],
                    },
                    subcategories: {
                        values: [],
                        summedSelections: [],
                    },
                    upcs: {
                        values: [],
                        summedSelections: [],
                    },
                    productDescriptions: {
                        values: [],
                        summedSelections: [],
                    },
                    parentCompanies: {
                        values: [],
                        summedSelections: [],
                    },
                    manufacturers: {
                        values: [],
                        summedSelections: [],
                    },
                    characteristics: {},
                    customCharacteristics: {},
                }
            } as any, ...products,];
        }

        if (defaultMarkets.length) {

            markets = [{
                id: -1,
                type: 'market',
                displayName: `My Default Markets`,
                values: {
                    markets: {
                        values: getRunConfigMarketsByKeys(defaultMarkets.map(m => m.key!)),
                        summedSelections: []
                    }
                }
            } as any, ...markets,];
        }

        if (defaultTimePeriod) {
            let timePeriod;
            const [type, weeks] = (defaultTimePeriod.period! as string).split(/\s+/);
            if(type === 'Latest') {
                timePeriod = `pe:${weeks}:0`
            } else {
                timePeriod = `pe1y:1y:0`
            }

            timePeriods = [{
                id: -1,
                type: 'time_period',
                displayName: `My Default Time Periods`,
                values: {
                    timePeriods: {
                        values: [timePeriod],
                        summedSelections: []
                    }
                }
            } as any, ...timePeriods,]
        }

        return {
            product: products,
            time_period: timePeriods,
            market: markets,
            fact: facts,
            layout: layout
        };
    }, [dodPresets]);

    function getSavedSelectionsByType(type: DodPresetType): DodPresetSummary[] {

        return dodPresetsMap[type];
    }

    async function deletePreset(id: number): Promise<void> {

        // confirmation checks do not belong in this service that is why the alert was removed.
        await deleteDodPreset(id);
        // this isn't necessary we just update the map with the change
        await reloadDodPresets();
    }

    async function getPreset(id: number, runConfig?: DodRunConfig): Promise<DodPreset> {

        const {values, ...preset} = await getDodPreset(id);

        if (preset.type === 'market' && typeof values.markets!.values[0] === 'string') {
            let priorApprovalParams: any = undefined;
            if (
                (values?.categories?.values && values?.categories?.summedSelections) ||
                (runConfig?.filters.categories.values && runConfig?.filters.categories.summedSelections)
            ) {
                const categoryValues = values?.categories?.values || runConfig?.filters.categories.values;
                const categorySummedValues =
                    values?.categories?.summedSelections || runConfig?.filters.categories.summedSelections;
                priorApprovalParams = {
                    isDodMarket: true,
                    categories: categoryValues,
                    isCategoriesSummedMode: categorySummedValues?.length ? true : false,
                    summedCategories: categorySummedValues as any,
                    nonSummedCategories: categoryValues,
                };
            }

            values.markets = {
                values: getRunConfigMarketsByKeys(values.markets!.values as any, priorApprovalParams),
                summedSelections: values.markets!.summedSelections.map((ss) => ({
                    name: ss.name,
                    values: getRunConfigMarketsByKeys(ss.values as any)
                }))
            };
        }

        return {
            ...preset,
            values
        };
    }

    async function addPreset({values, ...preset}: Omit<DodPreset, 'id'>): Promise<DodPreset> {

        switch (preset.type) {
            case "product":
                values = {
                    departments: values.departments,
                    superCategories: values.superCategories,
                    categories: values.categories,
                    subcategories: values.subcategories,
                    upcs: values.upcs,
                    productDescriptions: values.productDescriptions,
                    parentCompanies: values.parentCompanies,
                    manufacturers: values.manufacturers,
                    brands: values.brands,
                    characteristics: values.characteristics,
                    customCharacteristics: values.customCharacteristics,
                }
                break;
            case "market":
                values = {
                    markets: values.markets
                }
                break;
            case "time_period":
                values = {
                    timePeriods: values.timePeriods
                }
                break;
            case "fact":
                break;
        }


        const result = await createDodPreset({
            ...preset,
            values
        });
        // this isn't necessary we just update the map with the change
        await reloadDodPresets();

        return result;
    }

    return {
        dodPresetsMap,
        getPreset,
        deletePreset,
        addPreset

    }
}

type WithTitle = {
    title: string;
}

function compareByLabel(a: WithTitle, b: WithTitle) {
    return a.title.localeCompare(b.title);
}
