import React, { useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './DefaultsConfigWizard.scss';
import { DefaultConfigValue, DefaultConfigWizardContext, DefaultConfigWizardContextValue } from "./DefaultsConfigWizardContext";
import { TabWizard, TabWizardRef } from "@byzzer/ui-components";
import useState from 'react-usestateref';
import { useUser } from "@/contexts/UserContext";
import { MarketStep, ProductStep, TimePeriodStep } from "../Steps";
import { DefaultRunConfig } from "@/types/ApiTypes";
import { useMarketService } from "@/services/market.service";
import { FocusKpiStep } from "../Steps/FocusKpiStep";
import { RunConfigOptions } from "@/types/RunConfigOptions";
import { DefaultConfigLabels } from "@/pages/NewOnboarding/OnboardingSteps";

export type DefaultsConfigWizardProps = {
    onComplete?: (defaultConfig: DefaultRunConfig) => void;
    onCancel?: () => void;
    data?: any;
    busy?: boolean;
    busyCompleteButtonText?: string;
    busyCompleteButtonTip?: ReactNode;
    defaultPreset?: Partial<DefaultRunConfig>;
    defaultConfigOptions:  RunConfigOptions[];
    labelsAndTips: DefaultConfigLabels;
}
const OMNI_DISCLAIMER = 'Note: Default selections do not apply to Omnisales data and reports.'

type ConfigOptionsType = 'product' | 'market' | 'time_period' | 'focuskpi'; // Todo- DRY this up


const FILTER_BY_OPTION_TYPE: Record<ConfigOptionsType, any> = {
    product: ProductStep,
    market: MarketStep,
    time_period: TimePeriodStep,
    focuskpi: FocusKpiStep,
};


export const DefaultsConfigWizard = forwardRef(({
    onComplete,
    onCancel,
    busy,
    busyCompleteButtonText,
    busyCompleteButtonTip,
    defaultPreset,
    defaultConfigOptions,
    labelsAndTips,
    ...props
}: DefaultsConfigWizardProps, ref) => {

    const baseClassName = 'byz-defaults-config-wizard';

    const {
        defaultRunConfig,
    } = useUser();
    const {getEnabledMarkets} = useMarketService();
  

    useEffect(() => { 
        wizardRef.current!.activeStepIndex = 0;
     },[]);

    const {
        brands = [],
        timePeriod = {type: 'relative'},
        markets,
        categories = [],
        characteristics = [],
        focusKPI,
    } = defaultRunConfig ?? {};

    const [contextValue, setContextValue, contextValueRef] = useState<DefaultConfigWizardContextValue>({
        value: {
            brands,
            categories,
            characteristics,
            markets: getEnabledMarkets(markets, categories),
            timePeriod,
            focusKPI,
            ...defaultPreset,            
        },
        onChange(name: keyof DefaultConfigValue, value: any): void {
            const shouldResetMarkets = name === 'categories';

            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    markets: shouldResetMarkets ? [] : current.value.markets,
                    [name]: value,
                },
            }));
        },
    });

    useImperativeHandle(ref, () => contextValueRef.current.value, [contextValue.value]);

    const handleComplete = useCallback(async () => {
        const value = contextValueRef.current.value;
        if (!value) return;

        onComplete?.(value);

    }, [contextValue]);

    const Wizard = TabWizard;
    const wizardRef = useRef<TabWizardRef | null>(null);

    function handleCancel() {
        onCancel?.();
    }

    return (
        <>
            <header className={classnames(`${baseClassName}__header`)}>
                <div className={classnames(`${baseClassName}__heading`)}>
                    <p className={classnames(`${baseClassName}__title`)}>
                        Default Selections
                    </p>
                    <span className={classnames(`${baseClassName}__disclaimer`)}>{OMNI_DISCLAIMER}</span>
                </div>
            </header>
            <DefaultConfigWizardContext.Provider value={contextValue}>
                <Wizard 
                    className={classnames(baseClassName) } 
                    ref={wizardRef}
                >
                    {defaultConfigOptions?.map((options, i) => {
                        const Step = FILTER_BY_OPTION_TYPE[options.type];
                        return <Step key={`${options.type}${i}`} onComplete={handleComplete} onCancel={handleCancel} {...options} labelsAndTips={labelsAndTips}/>;
                    })}
                </Wizard>
            </DefaultConfigWizardContext.Provider>
        </>
    );

});

export default DefaultsConfigWizard;

DefaultsConfigWizard.displayName = 'DefaultsConfigWizard';